import React from "react";
class CustomerComments extends React.Component {
  constructor(props) {
    super(props);
    this.submitComment = this.submitComment.bind(this);
    this.editComment = this.editComment.bind(this);
    this.cancelComment = this.cancelComment.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.state = {
      comment: props.comment,
      show: false,
    };
  }

  submitComment() {
    this.setState({ show: false });
    this.props.submitComment(this.state);
  }

  editComment() {
    this.setState({ show: true });
  }

  cancelComment() {
    this.setState({ show: false });
  }

  changeComments(e) {
    this.setState({ comment: e.target.value });
  }

  render() {
    return (
      <div className="customerComment">
        {this.state.show == true ? (
          <span>
            <h5 style={{ marginTop: "15px" }}>
              <a
                href="javascript:;"
                onClick={this.cancelComment}
                style={{ margin: "5px" }}
              >
                Special Instructions <i className="fa fa-chevron-up" />
              </a>
            </h5>
            <div className="floatinput bigbox">
              <input
                style={{ padding: "10px", marginTop: "10px", display: "block" }}
                type="text"
                name="comments"
                value={this.state.comment}
                onChange={this.changeComments}
              />
              <span
                className={
                  "floatlabel " +
                  (this.state.comment == "" || this.state.comment == null
                    ? null
                    : "floatme")
                }
              >
                Special Instructions
              </span>
            </div>
            <input
              className="btn btn-tubedepot"
              style={{ margin: "5px" }}
              type="submit"
              value="Submit"
              onClick={this.submitComment}
            />
          </span>
        ) : (
          <span>
            <h5 style={{ marginTop: "15px" }}>
              <a
                style={{ margin: "5px" }}
                href="javascript:;"
                onClick={this.editComment}
              >
                Special Instructions <i className="fa fa-chevron-down" />
              </a>
            </h5>
            <h5 style={{ marginLeft: "5px", marginTop: "5px" }}>
              {this.props.comment}
            </h5>
          </span>
        )}
      </div>
    );
  }
}

export default CustomerComments;
