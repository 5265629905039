import React from "react";
import { Link } from "react-router-dom";
class CartItemAdjustments extends React.Component {
  constructor(props) {
    super();
    this.testref = React.createRef();
    this.toggleTests = this.toggleTests.bind(this);
  }

  toggleTests(e) {
    $(e.target).find("i").toggleClass("flip");
    $(this.testref.current).slideToggle();
  }

  render() {
    return (
      <ul className="cart-line-item-adjustments">
        {this.props.adjustments.bulk_label == "" ? null : (
          <span>
            <li>
              <strong>{this.props.adjustments.bulk_label}</strong>
              <div className="pull-right">
                {this.props.adjustments.bulk_amount}
              </div>
            </li>
            <hr style={{ margin: "0px" }} />
          </span>
        )}
        {this.props.adjustments.other_adjustments.map((itemMap, key) => (
          <span key={key}>
            <li>
              <strong>{itemMap.label}</strong>
              <div className="pull-right">{itemMap.price}</div>
            </li>
            <hr style={{ margin: "0px" }} />
          </span>
        ))}
        {this.props.tests.length == 0 ? null : (
          <li onClick={this.toggleTests} style={{ cursor: "pointer" }}>
            <h5>
              Available Tests <i className="fa fa-caret-down transitionable" />
            </h5>
          </li>
        )}
        <ul
          ref={this.testref}
          className="test_types cart-line-item-adjustments"
          style={{ display: "none" }}
        >
          {this.props.tests.map((itemMap, key) => (
            <span key={key}>
              <li>
                <label>
                  <input
                    type="checkbox"
                    checked={itemMap.selected}
                    onChange={(e) =>
                      this.props.changeTesting(
                        itemMap.id,
                        this.props.li_id,
                        e.target.checked,
                      )
                    }
                  />{" "}
                  {itemMap.label} (add {itemMap.price} each)
                </label>
              </li>
            </span>
          ))}
        </ul>
      </ul>
    );
  }
}

export default CartItemAdjustments;
