import "react-app-polyfill/ie11";
import React from "react";
import { Router, Route, Link } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-google";
import Home from "./Home/Data";
import Products from "./Products/Product";
import Search from "./Search/Index";
import Taxons from "./Taxons/Data";
import TBARoutes from "./TubesByAmp/Data";
import Footer from "./Footer/Index";
import Header from "./Header/Index";
import Navbar from "./Header/Navbar";
import PagesRoutes from "./Pages/Routes.js";
import Account from "./Account/Account.js";
import AnimateHeight from "react-animate-height";
import LoadingOverlay from "react-loading-overlay";
import ResetPassword from "./Account/ResetPassword";
import Checkout from "./Header/Checkout/Index";
import MediaQuery from "react-responsive";
import Cart from "./Header/Cart/Cart";
import NewProducts from "./NewProducts/NewProducts";
import CustomerComment from "./Shared/CustomerComment";
import Register from "./Header/Register/Index";
import ExternalIpLogin from "./ExternalIpLogin";
import ScrollToTop from "./Functionality/ScrollToTop";
import StickerDeal from "./StickerDeal/Index";
import MobileNavbar from "./MobileNavbar/Index";
import MobileCart from "./MobileCart/Index";
import SubscribeModal from "./Shared/SubscribeModal";
import NosList from "./NosList/Index";
import FreeShipping from "./Shared/FreeShipping";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
history.replace({
  pathname: window.location.pathname,
  search: window.location.search,
});

const loadingMessages = [
  "Turning it up to 11...",
  "Sound checking...",
  "Setting up the drums...",
  "Tuning the guitar...",
  "Warming up the tubes...",
  "Searching for a pick...",
  "Hanging out in the green room...",
  "Finding the sound guy...",
  "Plugging things into other things...",
];

class Routes extends React.Component {
  constructor() {
    super();
    this.logOut = this.logOut.bind(this);
    this.changeTesting = this.changeTesting.bind(this);
    this.closeError = this.closeError.bind(this);
    this.emptyCart = this.emptyCart.bind(this);
    this.notify = this.notify.bind(this);
    this.couponCode = this.couponCode.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.captureBraintree = this.captureBraintree.bind(this);
    this.captureStoredBraintree = this.captureStoredBraintree.bind(this);
    this.capturePayPal = this.capturePayPal.bind(this);
    this.gaComplete = this.gaComplete.bind(this);
    this.loadStates = this.loadStates.bind(this);
    this.editAddress = this.editAddress.bind(this);
    this.setShipping = this.setShipping.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.register = this.register.bind(this);
    this.logIn = this.logIn.bind(this);
    this.changeDeviseInfo = this.changeDeviseInfo.bind(this);
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.repeatOrder = this.repeatOrder.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.loadGoogleReviews = this.loadGoogleReviews.bind(this);
    this.removeStoredCard = this.removeStoredCard.bind(this);
    this.getShippingRates = this.getShippingRates.bind(this);
    this.localStorageUpdated = this.localStorageUpdated.bind(this);
    this.updateStorage = this.updateStorage.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.captureNothing = this.captureNothing.bind(this);
    this.closeSubModal = this.closeSubModal.bind(this);
    this.closeFreeModal = this.closeFreeModal.bind(this);
    this.state = {
      loadingRates: false,
      success: false,
      error: "Something went wrong.",
      orderCompleted: false,
      failure: false,
      loading: false,
      loadingId: 0,
      showDropdown: false,
      height: "auto",
      notification: false,
      notificationType: "danger",
      notificationMessage:
        "Something went wrong. Please refresh and try again.",
      devise: {
        email: "",
        password: "",
        password_confirmation: "",
      },
      data: {
        paymentInfo: {
          storedCards: [],
        },
        jjPurchaseLimit: "",
        cart_suggestions: [],
        locations: {
          states: [],
          countries: [],
        },
        ftlogo: "",
        orders: 1,
        subscribed: true,
        links: {
          amps: [],
          pedals: [],
          pedalkits: [],
          hifikits: [],
          hifiamps: [],
          hifigear: [],
          otherstuff: [],
          diycentral: [],
          guitarparts: [],
          brands: [],
          quickpicks: [],
          categories: [],
        },
        account: {
          admin: false,
          email: "Account",
          logged_in: false,
          default_address: "",
          default_billing_address: "",
          order: {
            state: "",
            version: "",
            last_added_image: "",
            comment: "",
            number: "",
            item_count: 0,
            adjustments: {},
            items: {
              adjustments: {
                other_adjustments: {},
              },
              tests: {},
            },
            shipments: {
              status: "",
              line_items: {
                name: "",
                quantity: 0,
              },
              rates: {
                label: "",
                value: 0,
              },
              selected_rate: {
                label: "",
                value: 0,
              },
            },
          },
          checkout_info: {
            has_address: false,
            ship_address: [],
            bill_address: [],
            international: false,
          },
        },
      },
    };
  }

  closeSubModal() {
    $("#subscribeModal").modal("hide");
  }

  closeFreeModal() {
    $("#freeShippingModal").modal("hide");
  }

  removeStoredCard(token) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/remove_stored_card", {
      method: "post",
      body: JSON.stringify({ token: token }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: data,
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please refresh the page and try again.",
        );
      });
  }

  loadGoogleReviews() {
    var ratingBadgeContainer = document.querySelector("#gts-container");
    window.gapi.load("ratingbadge", function () {
      window.gapi.ratingbadge.render(ratingBadgeContainer, {
        merchant_id: 10490068,
        position: "BOTTOM_LEFT",
      });
    });
  }

  componentDidMount() {
    loadReCaptcha();
    const that = this;
    return fetch("/react/headerdata?log=yes")
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState({ data: data });
        that.updateStorage();
        this.loadGoogleReviews(); //Loading this here since it relies on things being rendered already
        // if (typeof window !== 'undefined') {
        //   window.addEventListener('storage', this.localStorageUpdated)
        // } Removed - localStorageUpdated was very occassionally cause situations where
        // if you had two tabs open, they would both enter an infinite loading loop.
        // This was too intermittent for me to figure out, so I'm removing this functionality.
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  reloadData() {
    this.startLoading();
    const that = this;
    return fetch("/react/headerdata")
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState({ data: data });
        this.stopLoading();
      })
      .catch(function (error) {
        that.notify("danger", "Something went wrong. Please refresh the page.");
      });
  }

  handleAddressChange(new_address, address_type) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/change_default_address", {
      method: "post",
      body: JSON.stringify({
        address: new_address,
        address_type: address_type,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            account: data,
          },
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  localStorageUpdated(e) {
    if (
      localStorage.getItem("td.version") !=
      this.state.data.account.order.version
    ) {
      if (
        localStorage.getItem("td.version") != "null" &&
        this.state.data.account.order.version
      ) {
        //Ensuring that neither is falsey - this leads to version loops
        console.log("Change made on another tab, reloading data.");
        this.reloadData();
      }
    }
  }

  changeTesting(test_id, li, checked) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/change_testing", {
      method: "post",
      body: JSON.stringify({
        line_item: li,
        checked: checked,
        test_id: test_id,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            account: {
              ...prevState.data.account,
              order: data,
            },
          },
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  submitComment(comment) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/submit_comment", {
      method: "post",
      body: JSON.stringify(comment),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            account: {
              ...prevState.data.account,
              order: data,
            },
          },
        }));
        this.stopLoading();
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please check your payment info and try again.",
        );
      });
  }

  capturePayPal(nonce) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/capture_paypal", {
      method: "post",
      body: JSON.stringify({ nonce: nonce }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        try {
          this.gaComplete();
        } catch (error) {}
        that.setState({ data: data, orderCompleted: true });
        this.stopLoading();
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please check your payment info and try again.",
        );
      });
  }

  captureBraintree(nonce, device, storeCard, recaptcha) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/capture_braintree", {
      method: "post",
      body: JSON.stringify({
        nonce: nonce,
        storeCard: storeCard,
        recaptcha: recaptcha,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        try {
          this.gaComplete();
        } catch (error) {}
        that.setState({ data: data, orderCompleted: true });
        this.stopLoading();
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please check your payment info and try again.",
        );
      });
  }

  captureStoredBraintree(token, cvv, device) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/capture_stored_braintree", {
      method: "post",
      body: JSON.stringify({ token: token }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        try {
          this.gaComplete();
        } catch (error) {}
        that.setState({ data: data, orderCompleted: true });
        this.stopLoading();
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please check your payment info and try again.",
        );
      });
  }

  captureNothing() {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/capture_nothing", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        try {
          this.gaComplete();
        } catch (error) {}
        that.setState({ data: data, orderCompleted: true });
        this.stopLoading();
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please refresh the page and try again.",
        );
      });
  }

  gaComplete() {
    const that = this.state.data.account;
    const orderItems = that.order.items.map((item) => ({
      id: item.sku,
      item_name: item.name,
      item_id: item.sku,
      item_category: "",
      price: item.price,
      quantity: item.quantity,
    }));

    gtag("event", "purchase", {
      affiliation: "TubeDepot",
      currency: "USD",
      event_category: "ecommerce",
      items: orderItems,
      shipping: that.order.ship_total,
      tax: that.order.tax_total,
      transaction_id: that.order.number,
      value: that.order.total,
    });
  }

  loadingMessageIndex() {
    return Math.floor(Math.random() * loadingMessages.length);
  }

  startLoading() {
    if (this.state.loading == false) {
      this.setState({
        loading: true,
        loadingId: this.loadingMessageIndex(),
        success: false,
        failure: false,
        notification: false,
      });
    }
  }

  stopLoading() {
    if (this.state.loading == true) {
      this.setState({ loading: false, success: true });
    }
    this.updateStorage();
  }

  updateStorage() {
    if (
      localStorage.getItem("td.version") !=
      this.state.data.account.order.version
    ) {
      localStorage.setItem("td.version", this.state.data.account.order.version);
    }
  }

  logOut() {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/logout", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: data,
          devise: {
            email: "",
            password: "",
            password_confirmation: "",
          },
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  setAddress(address, isShipping) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    var url = "";
    isShipping
      ? (url = "/react/set_address")
      : (url = "/react/set_payment_address");
    return fetch(url, {
      method: "post",
      body: JSON.stringify(address),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            account: data,
          },
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch((err) => {
        err.json().then((errorMessage) => {
          that.notify("danger", errorMessage.error);
        });
      });
  }

  repeatOrder(order) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/repeat_order", {
      method: "post",
      body: JSON.stringify({ number: order }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            account: data,
          },
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
        {
          /* TODO: Maybe open cart modal here? */
        }
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please check your address and try again.",
        );
      });
  }

  setShipping(id) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/set_shipping", {
      method: "post",
      body: JSON.stringify(id),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            account: data,
          },
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  notify(type, message) {
    var failure = type == "danger";
    if (this.state.loading == true) {
      this.setState({
        loading: false,
        failure: failure,
        success: !failure,
        notification: true,
        notificationType: type,
        notificationMessage: message,
      });
    } else {
      this.setState({
        failure: failure,
        success: !failure,
        notification: true,
        notificationType: type,
        notificationMessage: message,
      });
    }
    setTimeout(
      function () {
        this.setState({ notification: false });
      }.bind(this),
      5000,
    );
  }

  loadStates(id, unsetLoading) {
    const that = this;
    return fetch("/react/get_states?id=" + id)
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            locations: {
              ...prevState.data.locations,
              states: data,
            },
          },
        }));
        unsetLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  addToCart(lineItem) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    const jjPurchaseLimit = this.state.data.jjPurchaseLimit;
    return fetch("/react/add_to_cart", {
      method: "post",
      body: JSON.stringify(lineItem),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        return results.json().then((json) => {
          if (results.ok) {
            return json;
          } else {
            throw json;
          }
        });
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          showDropdown: true,
          data: {
            ...prevState.data,
            account: {
              ...prevState.data.account,
              order: data,
            },
          },
        }));
        this.stopLoading();
        setTimeout(
          function () {
            this.setState((prevState) => ({
              ...prevState,
              showDropdown: false,
              data: {
                ...prevState.data,
                account: {
                  ...prevState.data.account,
                  order: {
                    ...prevState.data.account.order,
                    last_added_image: "",
                  },
                },
              },
            }));
          }.bind(this),
          5000,
        );
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch(function (error) {
        if (
          error.message === "Quantity is limited to 10 Pieces Per Order" ||
          error.message ===
            `Quantity is limited to ${jjPurchaseLimit} Pieces Per Order`
        ) {
          that.notify("danger", error.message);
        } else {
          that.notify(
            "danger",
            "Something went wrong. Please refresh and try again.",
          );
        }
      });
  }

  getShippingRates() {
    if (
      !(this.state.data.account.order.state == "payment") &&
      this.state.data.account.checkout_info.has_address == true
    ) {
      const csrf = document
        .querySelector("meta[name='csrf-token']")
        .getAttribute("content");
      const that = this;
      this.setState({ loadingRates: true });
      return fetch("/react/get_shipping_rates", {
        method: "post",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf,
        },
        credentials: "same-origin",
      })
        .then((results) => {
          if (results.ok) {
            return results.json();
          }
          throw results;
        })
        .then((jsonData) => {
          if (jsonData.free_shipping_weight_notice !== null)
            that.notify("danger", jsonData.free_shipping_weight_notice);
          return jsonData;
        })
        .then((data) => {
          that.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              account: {
                ...prevState.data.account,
                order: data,
              },
            },
          }));
          this.setState({ loadingRates: false });
          {
            /* This is ugly, because updating nested state is complicated. */
          }
        })
        .catch(function (error) {
          that.notify(
            "danger",
            "Something went wrong. Please refresh and try again.",
          );
        });
    }
  }

  changeQuantity(line_item, e) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const jjPurchaseLimit = this.state.data.jjPurchaseLimit;
    const that = this;
    return fetch("/react/change_quantity", {
      method: "post",
      body: JSON.stringify({ line_item: line_item, qty: e }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        return results.json().then((json) => {
          if (results.ok) {
            return json;
          } else {
            throw json;
          }
        });
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            account: {
              ...prevState.data.account,
              order: data,
            },
          },
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch(function (error) {
        if (
          error.message === "Quantity is limited to 10 Pieces Per Order" ||
          error.message ===
            `Quantity is limited to ${jjPurchaseLimit} Pieces Per Order`
        ) {
          that.notify("danger", error.message);
        } else {
          that.notify(
            "danger",
            "Something went wrong. Please refresh and try again.",
          );
        }
        return false;
      });
  }

  emptyCart() {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/empty", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            account: {
              ...prevState.data.account,
              order: data,
            },
          },
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  editAddress() {
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        account: {
          ...prevState.data.account,
          checkout_info: {
            ...prevState.data.account.checkout_info,
            has_address: false,
          },
        },
      },
    }));
  }

  couponCode(code) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/apply_code", {
      method: "post",
      body: JSON.stringify({ code: code }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            account: {
              ...prevState.data.account,
              order: data,
            },
          },
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch((err) => {
        err.json().then((errorMessage) => {
          that.notify("danger", errorMessage.error);
        });
      });
  }

  updateHeight() {
    if (!(this.refs.container == null)) {
      if (this.refs.container.clientHeight != this.state.height) {
        this.setState({ height: this.refs.container.clientHeight });
      }
    }
  }

  deleteItem(line_item_id) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/remove_from_cart", {
      method: "post",
      body: JSON.stringify({ line_item_id: line_item_id }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            account: {
              ...prevState.data.account,
              order: data,
            },
          },
        }));
        this.stopLoading();
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  checkMark() {
    return (
      <div>
        <i
          className="fa fa-check-circle green"
          style={{ fontSize: "50px", marginBottom: "10px" }}
        />
      </div>
    );
  }

  errorMark() {
    return (
      <div>
        <i
          className="fa fa-times-circle"
          style={{ fontSize: "50px", marginBottom: "10px", color: "red" }}
        />
      </div>
    );
  }

  closeError() {
    this.setState({ failure: false, error: "", notification: false });
  }

  register() {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/register", {
      method: "post",
      body: JSON.stringify(this.state.devise),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: data,
          devise: {
            email: "",
            password: "",
            password_confirmation: "",
          },
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch((err) => {
        err.json().then((errorMessage) => {
          that.notify("danger", errorMessage.error);
        });
      });
  }

  logIn() {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/login", {
      method: "post",
      body: JSON.stringify(this.state.devise),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: data,
          devise: {
            email: "",
            password: "",
            password_confirmation: "",
          },
        }));
        this.stopLoading();
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Unable to log in. Please check your credentials and try again.",
        );
      });
  }

  changePassword(devise) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/changePassword", {
      method: "post",
      body: JSON.stringify(devise),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState((prevState) => ({
          ...prevState,
          data: data,
          devise: {
            email: "",
            password: "",
            password_confirmation: "",
          },
        }));
        this.stopLoading();
        this.notify("success", "Your password has been updated.");
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch((err) => {
        err.json().then((errorMessage) => {
          that.notify("danger", errorMessage.error);
        });
      });
  }

  forgotPassword(email) {
    this.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/forgot", {
      method: "post",
      body: JSON.stringify(email),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        this.stopLoading();
        this.notify(
          "info",
          "Reset password email has been sent - please check your email.",
        );
        {
          /* This is ugly, because updating nested state is complicated. */
        }
      })
      .catch(function (error) {
        that.notify(
          "danger",
          "Something went wrong. Please check the email provided and try again.",
        );
      });
  }

  changeDeviseInfo(e) {
    const {
      target: { name, value },
    } = e;
    this.setState((prevState) => ({
      ...prevState,
      devise: {
        ...prevState.devise,
        [name]: value,
      },
    }));
  }

  handleEmailChange = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      data: data,
    }));
  };

  render() {
    return (
      <Router history={history}>
        <LoadingOverlay
          active={this.state.loading}
          spinner={
            this.state.success == true
              ? this.checkMark()
              : this.state.failure == true
                ? this.errorMark()
                : true
          }
          text={loadingMessages[this.state.loadingId]}
        >
          <ScrollToTop>
            <span>
              {this.state.notification == true ? (
                <div
                  className={
                    "errordiv alert alert-" + this.state.notificationType
                  }
                >
                  <a
                    href="javascript:;"
                    onClick={this.closeError}
                    className="close"
                  >
                    &times;
                  </a>
                  {this.state.notificationMessage}
                </div>
              ) : null}
              {this.state.data.account.logged_in ? null : (
                <Register
                  notify={this.notify}
                  devise={this.state.devise}
                  changeDeviseInfo={this.changeDeviseInfo}
                  forgotPassword={this.forgotPassword}
                  register={this.register}
                  logIn={this.logIn}
                />
              )}
              <MediaQuery query="(min-width: 941px)">
                <div
                  className="modal cart-modal in fade"
                  id="cartModal"
                  style={{
                    width: "980px",
                    marginLeft: "-500px",
                    color: "#333333",
                  }}
                >
                  <div style={{ height: "100%" }}>
                    <div
                      className="container constrict display-table"
                      style={{ height: "100%" }}
                    >
                      {this.state.data.account.order.item_count == 0 ||
                      this.props.newCheckout ? null : (
                        <Checkout
                          getShippingRates={this.getShippingRates}
                          captureNothing={this.captureNothing}
                          loadingRates={this.state.loadingRates}
                          removeStoredCard={this.removeStoredCard}
                          comment={this.state.data.account.order.comment}
                          submitComment={this.submitComment}
                          email={this.state.data.account.email}
                          capturePayPal={this.capturePayPal}
                          captureBraintree={this.captureBraintree}
                          captureStoredBraintree={this.captureStoredBraintree}
                          paymentInfo={this.state.data.paymentInfo}
                          loggedIn={this.state.data.account.logged_in}
                          notify={this.notify}
                          shipments={this.state.data.account.order.shipments}
                          setShipping={this.setShipping}
                          editAddress={this.editAddress}
                          loadStates={this.loadStates}
                          checkoutInfo={this.state.data.account.checkout_info}
                          setAddress={this.setAddress}
                          locations={this.state.data.locations}
                          total={this.state.data.account.order.total}
                          reCaptchaSiteKey={this.props.reCaptchaSiteKey}
                          testEnv={this.props.testEnv}
                        />
                      )}
                      <Cart
                        newCheckout={this.props.newCheckout}
                        isMobile={false}
                        changeTesting={this.changeTesting}
                        orderCompleted={this.state.orderCompleted}
                        changeQuantity={this.changeQuantity}
                        cartSuggestions={this.state.data.cart_suggestions}
                        order={this.state.data.account.order}
                        emptyCart={this.emptyCart}
                        checkoutImage={this.props.checkoutImage}
                        couponCode={this.couponCode}
                        deleteItem={this.deleteItem}
                        international={
                          this.state.data.account.checkout_info.international
                        }
                      />
                    </div>
                  </div>
                </div>
              </MediaQuery>
              <div
                className="modal in fade"
                id="subscribeModal"
                style={{
                  width: "980px",
                  marginLeft: "-500px",
                  color: "#333333",
                }}
              >
                <div
                  id="empty-cart"
                  data-hook="empty_cart"
                  style={{ marginTop: "10px" }}
                >
                  <p id="clear_cart_link" data-hook>
                    <input
                      onClick={this.closeSubModal}
                      type="submit"
                      name="commit"
                      value="Close"
                      className="button btn btn-tubedepot"
                    />
                  </p>
                </div>
                <SubscribeModal
                  startLoading={this.startLoading}
                  stopLoading={this.stopLoading}
                  notify={this.notify}
                />
              </div>
              <div
                className="modal in fade"
                id="freeShippingModal"
                style={{
                  width: "980px",
                  marginLeft: "-500px",
                  color: "#333333",
                }}
              >
                <div
                  id="empty-cart"
                  data-hook="empty_cart"
                  style={{ marginTop: "10px" }}
                >
                  <p id="clear_cart_link" data-hook>
                    <input
                      onClick={this.closeFreeModal}
                      type="submit"
                      name="commit"
                      value="Close"
                      className="button btn btn-tubedepot"
                    />
                  </p>
                </div>
                <FreeShipping closeModal={this.closeFreeModal} />
              </div>
              <MediaQuery query="(max-width: 940px)">
                <div
                  className="modal navbar-modal in fade"
                  id="navModal"
                  style={{
                    width: "980px",
                    marginLeft: "-500px",
                    color: "#333333",
                  }}
                >
                  <div style={{ height: "100%" }}>
                    <div
                      className="container constrict display-table"
                      style={{ height: "100%" }}
                    >
                      <MobileNavbar
                        subscribed={this.state.data.subscribed}
                        devise={this.state.devise}
                        forgotPassword={this.forgotPassword}
                        changeDeviseInfo={this.changeDeviseInfo}
                        register={this.register}
                        logIn={this.logIn}
                        notify={this.notify}
                        startLoading={this.startLoading}
                        stopLoading={this.stopLoading}
                        links={this.state.data.links}
                        account={this.state.data.account}
                        logOut={this.logOut}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="modal mcart-modal in fade"
                  id="mcartModal"
                  style={{
                    width: "980px",
                    marginLeft: "-500px",
                    color: "#333333",
                  }}
                >
                  <div style={{ height: "100%" }}>
                    <div
                      className="container constrict display-table"
                      style={{ height: "100%" }}
                    >
                      <MobileCart
                        newCheckout={this.props.newCheckout}
                        itemCount={this.state.data.account.order.item_count}
                        changeTesting={this.changeTesting}
                        orderCompleted={this.state.orderCompleted}
                        changeQuantity={this.changeQuantity}
                        cartSuggestions={this.state.data.cart_suggestions}
                        order={this.state.data.account.order}
                        emptyCart={this.emptyCart}
                        checkoutImage={this.props.checkoutImage}
                        couponCode={this.couponCode}
                        deleteItem={this.deleteItem}
                        loadingRates={this.state.loadingRates}
                        removeStoredCard={this.removeStoredCard}
                        comment={this.state.data.account.order.comment}
                        submitComment={this.submitComment}
                        email={this.state.data.account.email}
                        capturePayPal={this.capturePayPal}
                        captureBraintree={this.captureBraintree}
                        captureStoredBraintree={this.captureStoredBraintree}
                        paymentInfo={this.state.data.paymentInfo}
                        loggedIn={this.state.data.account.logged_in}
                        notify={this.notify}
                        shipments={this.state.data.account.order.shipments}
                        setShipping={this.setShipping}
                        editAddress={this.editAddress}
                        loadStates={this.loadStates}
                        checkoutInfo={this.state.data.account.checkout_info}
                        setAddress={this.setAddress}
                        locations={this.state.data.locations}
                        total={this.state.data.account.order.total}
                        getShippingRates={this.getShippingRates}
                        reCaptchaSiteKey={this.props.reCaptchaSiteKey}
                        testEnv={this.props.testEnv}
                      />
                    </div>
                  </div>
                </div>
              </MediaQuery>

              <div className="container">
                <div className="hcc">
                  <div className="row no-margin header-container">
                    <Header logo={this.props.logo} />
                    <Navbar
                      newCheckout={this.props.newCheckout}
                      subscribed={this.state.data.subscribed}
                      showDropdown={this.state.showDropdown}
                      orderCompleted={this.state.orderCompleted}
                      capturePayPal={this.capturePayPal}
                      captureBraintree={this.captureBraintree}
                      paymentInfo={this.state.data.paymentInfo}
                      devise={this.state.devise}
                      forgotPassword={this.forgotPassword}
                      changeDeviseInfo={this.changeDeviseInfo}
                      register={this.register}
                      logIn={this.logIn}
                      notify={this.notify}
                      changeQuantity={this.changeQuantity}
                      setShipping={this.setShipping}
                      editAddress={this.editAddress}
                      loadStates={this.loadStates}
                      locations={this.state.data.locations}
                      setAddress={this.setAddress}
                      checkoutInfo={this.state.data.account.checkout_info}
                      cartSuggestions={this.state.data.cart_suggestions}
                      startLoading={this.startLoading}
                      stopLoading={this.stopLoading}
                      links={this.state.data.links}
                      account={this.state.data.account}
                      logOut={this.logOut}
                      emptyCart={this.emptyCart}
                      checkoutImage={this.props.checkoutImage}
                      couponCode={this.couponCode}
                      deleteItem={this.deleteItem}
                      getShippingRates={this.getShippingRates}
                    />
                  </div>
                  <div className="covid-header-notice">
                    <button
                      className="pull-right covid-header-dismiss-button"
                      style={{ background: "transparent", border: "none" }}
                      onClick={() =>
                        document
                          .querySelector(".covid-header-notice")
                          .classList.toggle("hidden")
                      }
                    >
                      <span
                        className="fa fa-times-circle-o"
                        style={{ fontSize: "1.75rem" }}
                      />
                    </button>
                    <Link
                      to="/pages/a-note-on-usps-delays"
                      className="_text-black _hover:text-blue-600"
                    >
                      The USPS is Experiencing Serious Delays - Domestically and
                      Worldwide. Click Here to Learn More.
                    </Link>
                  </div>
                </div>

                <AnimateHeight
                  duration={500}
                  height={this.state.height}
                  style={{ marginTop: "-5px" }}
                >
                  <div className="row no-margin" id="cnt" ref="container">
                    <div className="row">
                      <div id="main-content">
                        <div>
                          <Route
                            exact
                            path="/"
                            render={(props) => (
                              <Home
                                notify={this.notify}
                                updateHeight={this.updateHeight}
                                logged_in={this.state.data.account.logged_in}
                                taxon_address={this.props.taxon_address}
                                taxon_message={this.props.taxon_message}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/products"
                            render={(props) => (
                              <Search
                                updateHeight={this.updateHeight}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            path="/new_products"
                            render={(props) => (
                              <NewProducts
                                notify={this.notify}
                                updateHeight={this.updateHeight}
                              />
                            )}
                          />
                          <Route
                            path="/products/:slug"
                            render={(props) => (
                              <Products
                                notify={this.notify}
                                updateHeight={this.updateHeight}
                                addToCart={this.addToCart}
                                productSuggestions={
                                  this.state.data.cart_suggestions
                                }
                                startLoading={this.startLoading}
                                stopLoading={this.stopLoading}
                                loggedIn={this.state.data.account.logged_in}
                                admin={this.state.data.account.admin}
                                jjPurchaseLimit={
                                  this.state.data.jjPurchaseLimit
                                }
                                {...props}
                              />
                            )}
                          />
                          <Route
                            path="/t/:taxon"
                            render={(props) => (
                              <Taxons
                                notify={this.notify}
                                updateHeight={this.updateHeight}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            path="/tubes_by_amp"
                            render={(props) => (
                              <TBARoutes
                                notify={this.notify}
                                updateHeight={this.updateHeight}
                                addToCart={this.addToCart}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            path="/account"
                            render={(props) => (
                              <Account
                                notify={this.notify}
                                loadStates={this.loadStates}
                                locations={this.state.data.locations}
                                updateHeight={this.updateHeight}
                                defaultAddress={
                                  this.state.data.account.default_address
                                }
                                defaultBillingAddress={
                                  this.state.data.account
                                    .default_billing_address
                                }
                                handleAddressChange={this.handleAddressChange}
                                repeatOrder={this.repeatOrder}
                                startLoading={this.startLoading}
                                stopLoading={this.stopLoading}
                                email={this.state.data.account.email}
                                handleEmailChange={this.handleEmailChange}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            path="/pages"
                            render={(props) => (
                              <PagesRoutes
                                updateHeight={this.updateHeight}
                                notify={this.notify}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            path="/user/spree_user/password/edit"
                            render={(props) => (
                              <ResetPassword
                                updateHeight={this.updateHeight}
                                changePassword={this.changePassword}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            path="/customer_comments/:id"
                            component={CustomerComment}
                          />
                          <Route
                            path="/external_ip_login"
                            render={(props) => (
                              <ExternalIpLogin
                                notify={this.notify}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            path="/stickerdeal.html"
                            component={StickerDeal}
                          />
                          <Route path="/nos_list" component={NosList} />
                        </div>
                      </div>
                    </div>
                  </div>
                </AnimateHeight>

                <Footer
                  image={this.props.ftlogo}
                  orders={this.state.data.orders}
                  subscribed={this.state.data.subscribed}
                  notify={this.notify}
                />
              </div>
              {/* }<%= render :partial => 'spree/shared/google_analytics' %>
        <%= render 'spree/shared/google_trusted_store', product: @product %>
        <%= dump_jsonld if respond_to? :dump_jsonld %> */}
            </span>
          </ScrollToTop>
        </LoadingOverlay>
      </Router>
    );
  }
}

export default Routes;
