import React from "react";
import { Link } from "react-router-dom";

class Orders extends React.Component {
  constructor() {
    super();
    this.state = {
      tubes: [],
      updated: "",
    };
  }

  componentDidMount() {
    var that = this;
    fetch("/react/nos_list")
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        this.setState({
          tubes: data.tubes,
          updated: data.updated,
        });
        document.title = "NOS List | TubeDepot.com";
      })
      .catch(function (err) {
        err.json().then((errorMessage) => {
          that.props.notify("danger", errorMessage.error);
        });
      });
  }

  render() {
    return (
      <div className="constrict" style={{ marginTop: "20px" }}>
        <div>
          These are the tubes that we currently need. The higher the tube is on
          the list, the more we need it. Take a look and let us know if you have
          any of these available. Please send the list to:{" "}
          <b>alex@tubedepot.com</b>
        </div>
        <br />
        <div>Last updated: {this.state.updated}</div>
        <table>
          <thead>
            <tr>
              <th>Sku</th>
              <th>Name</th>
              <th>Desired Quantity</th>
              <th>Target Price</th>
            </tr>
          </thead>
          <tbody>
            {this.state.tubes.map((tube, index) => (
              <tr className="tube" key={index}>
                <td>{tube.sku}</td>
                <td>{tube.name}</td>
                <td>{tube.desired_quantity}</td>
                <td>{tube.desired_price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Orders;
