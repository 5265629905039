import React from "react";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import ReactResizeDetector from "react-resize-detector";
import Products from "../Shared/Products";

class Index extends React.Component {
  constructor() {
    super();
    this.state = {
      products: [],
      moreProducts: false,
      loading: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.location.state.searchTerm ===
      nextProps.location.state.searchTerm
    )
      return;
    this.setState({ loading: true });
    fetch(
      "/react/products/search/" +
        encodeURIComponent(nextProps.location.state.searchTerm),
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          products: data.products,
          moreProducts: data.more_products,
          loading: false,
        });
        document.title = `Search results for '${nextProps.location.state.searchTerm}' | TubeDepot.com`;
        gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      });
  }

  componentDidMount() {
    fetch(
      "/react/products/search/" +
        encodeURIComponent(this.props.location.state.searchTerm),
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          products: data.products,
          moreProducts: data.more_products,
          loading: false,
        });
        document.title = `Search results for '${this.props.location.state.searchTerm}' | TubeDepot.com`;
        gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      });
  }

  render() {
    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={this.props.updateHeight}
      >
        <LoadingOverlay active={this.state.loading} spinner>
          <div className="constrict">
            <h6 className="search-results-title">
              Search results for '{this.props.location.state.searchTerm}'
            </h6>
            <Products products={this.state.products} />
            {this.state.moreProducts ? (
              <h3 style={{ marginLeft: "1em" }}>
                <i className="fa fa-plus-square" />
                &nbsp; Search is limited to 100 products - refine your search
                terms to see more accurate results.
              </h3>
            ) : (
              ""
            )}
          </div>
        </LoadingOverlay>
      </ReactResizeDetector>
    );
  }
}

export default Index;
