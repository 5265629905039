import React from "react";
import { Link } from "react-router-dom";
class Index extends React.Component {
  render() {
    return (
      <div className="row no-margin">
        <footer>
          <div className="pull-right" id="gts-container"></div>
          <div id="ftr-cnt" className="clearfix">
            <div className="constrict">
              <Link to="/" className="pull-left">
                <img id="ftr-logo" src={this.props.image} />
              </Link>
              <div id="ftr-right" className="pull-left">
                <ul id="ftr-links">
                  <li className="pull-left">
                    <Link to="/pages/contact-us" className="pull-left">
                      Contact Us
                    </Link>
                  </li>
                  <li className="pull-left">
                    <Link
                      to="/pages/warranty-and-returns"
                      className="pull-left"
                    >
                      Warranty & Returns
                    </Link>
                  </li>
                  <li className="pull-left">
                    <Link to="/pages/shipping-faq" className="pull-left">
                      Shipping FAQ
                    </Link>
                  </li>
                  <li className="pull-left">
                    <Link
                      to="/pages/privacy-and-security"
                      className="pull-left"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="pull-left">
                    <a href="https://www.facebook.com/TubeDepot">
                      <i className="fa fa-facebook social"></i>
                    </a>
                  </li>
                  <li className="pull-left">
                    <a href="https://twitter.com/TubeDepot">
                      <i className="fa fa-twitter social"></i>
                    </a>
                  </li>
                </ul>
                <div id="ftr-copyright">
                  Copyright © 1997 - {new Date().getFullYear()}&nbsp;TubeDepot,
                  LLC - All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Index;
