import React from "react";
import { Link } from "react-router-dom";
import CheckoutFields from "./CheckoutFields";
import AddressFields from "./AddressFields";
class Index extends React.Component {
  render() {
    return (
      <div className="table-cell checkout moch">
        {this.props.loggedIn == false ? (
          <div style={{ backgroundColor: "#4444", padding: "5px" }}>
            <h6>You are checking out as a guest.</h6>
          </div>
        ) : null}
        <div className="title">
          <h3>Shipping Address</h3>
          <br />
        </div>
        {this.props.checkoutInfo.has_address ? (
          <CheckoutFields
            getShippingRates={this.props.getShippingRates}
            captureNothing={this.props.captureNothing}
            loadingRates={this.props.loadingRates}
            removeStoredCard={this.props.removeStoredCard}
            loggedIn={this.props.loggedIn}
            checkoutInfo={this.props.checkoutInfo}
            comment={this.props.comment}
            submitComment={this.props.submitComment}
            total={this.props.total}
            capturePayPal={this.props.capturePayPal}
            captureBraintree={this.props.captureBraintree}
            captureStoredBraintree={this.props.captureStoredBraintree}
            paymentInfo={this.props.paymentInfo}
            notify={this.props.notify}
            shipments={this.props.shipments}
            setShipping={this.props.setShipping}
            editAddress={this.props.editAddress}
            address={this.props.checkoutInfo}
            loadStates={this.props.loadStates}
            setAddress={this.props.setAddress}
            locations={this.props.locations}
            reCaptchaSiteKey={this.props.reCaptchaSiteKey}
            testEnv={this.props.testEnv}
          />
        ) : (
          <AddressFields
            address={this.props.checkoutInfo.ship_address}
            isShipping={true}
            loadStates={this.props.loadStates}
            setAddress={this.props.setAddress}
            locations={this.props.locations}
            email={this.props.email}
          />
        )}
      </div>
    );
  }
}

export default Index;
