import React from "react";
import { Link } from "react-router-dom";

class Navigation extends React.Component {
  render() {
    return (
      <span>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("Tubes By Brand", "brands")}
        >
          <div className="dropdown dd-color">Tubes By Brand</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("Tube Categories", "categories")}
        >
          <div className="dropdown dd-color">Tube Categories</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li className="dropdown-li">
          <div className="dropdown dd-color">
            <Link to="/tubes_by_amp" onClick={this.props.closeModal}>
              Tube Sets By Amp
            </Link>
          </div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("Quick Picks", "quickpicks")}
        >
          <div className="dropdown dd-color">Quick Picks</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("DIY Central", "diycentral")}
        >
          <div className="dropdown dd-color">DIY Central</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("Guitar Parts", "guitarparts")}
        >
          <div className="dropdown dd-color">Guitar Parts</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("Guitar Tube Amp Kits", "amps")}
        >
          <div className="dropdown dd-color">Guitar Tube Amp Kits</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("Guitar Pedals", "pedals")}
        >
          <div className="dropdown dd-color">Guitar Pedals</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("Guitar Pedal Kits", "pedalkits")}
        >
          <div className="dropdown dd-color">Guitar Pedal Kits</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("HiFi Tube Amps", "hifiamps")}
        >
          <div className="dropdown dd-color">HiFi Tube Amps</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("HiFi Tube Amp Kits", "hifikits")}
        >
          <div className="dropdown dd-color">HiFi Tube Amp Kits</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("HiFi Gear", "hifigear")}
        >
          <div className="dropdown dd-color">HiFi Gear</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu("Other Stuff", "otherstuff")}
        >
          <div className="dropdown dd-color">Other Stuff</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
        {this.props.subscribed == true ? null : (
          <li className="dropdown-li" onClick={() => this.props.closeModal()}>
            <div
              className="dropdown dd-color"
              data-toggle="modal"
              href="#subscribeModal"
            >
              Save 10%
            </div>
            <i className="fa fa-arrow-right dd-arrow" />
          </li>
        )}
        <li
          className="dropdown-li"
          onClick={() => this.props.openMenu(this.props.account.email, "")}
        >
          <div className="dropdown dd-color">Your Account</div>
          <i className="fa fa-arrow-right dd-arrow" />
        </li>
      </span>
    );
  }
}

export default Navigation;
