import React from "react";
class NoPaymentNecessary extends React.Component {
  render() {
    return (
      <div>
        <button
          style={{ width: "100%", marginTop: "10px" }}
          className="btn btn-tubedepot paymentComplete"
          onClick={this.props.captureNothing}
        >
          Complete Your Order
        </button>
      </div>
    );
  }
}

export default NoPaymentNecessary;
