import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class CustomerComment extends React.Component {
  constructor() {
    super();
    this.state = {
      comment: {},
    };
  }

  componentDidMount() {
    fetch("/react/customer_comments/" + this.props.match.params.id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ comment: data });
      });
  }

  setRating = (rating) => {
    let stars = "";
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars = stars + "<i id=" + i + " class='fa fa-star starss'></i>";
      } else {
        stars = stars + "<i id=" + i + " class='fa fa-star starno'></i>";
      }
    }
    return ReactHtmlParser(stars);
  };

  render() {
    if (!this.state.comment.name) return null;
    return (
      <div className="constrict">
        {this.setRating(this.state.comment.star_rating)}
        <br />
        <strong>{this.state.comment.comment}</strong>
        <br />
        <br />
        reviewed by: {this.state.comment.name}
        <hr />
        Review Left For:
        <br />
        <a href={`/products/${this.state.comment.product_slug}`}>
          <img
            src={this.state.comment.product_image}
            alt={this.state.comment.product_image_alt}
          />
          <br />
          {this.state.comment.product_name}
          <br />
          <br />
        </a>
      </div>
    );
  }
}

export default CustomerComment;
