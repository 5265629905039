import React from "react";
import { Link } from "react-router-dom";
import CartItems from "./CartItems";
import CartSuggestions from "./CartSuggestions";
class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.goToCheckout = this.goToCheckout.bind(this);
    this.state = {
      code: "",
    };
  }

  handleChange(e) {
    this.setState({ code: e.target.value });
  }

  closeModal() {
    $("#cartModal").modal("hide");
    $("#mcartModal").modal("hide");
  }

  goToCheckout() {
    window.location.href = "/checkout/address";
    this.closeModal();
  }

  render() {
    if (this.props.order.item_count == 0) {
      return (
        <div style={{ paddingTop: "10px" }} className="table-cell">
          <h1 id="cart-text">Shopping Cart</h1>
          <div id="empty-cart" data-hook="empty_cart">
            <p id="clear_cart_link" data-hook>
              <input
                onClick={this.closeModal}
                type="submit"
                name="commit"
                value="Close"
                className="button btn btn-tubedepot"
              />
            </p>
          </div>
          {this.props.orderCompleted ? (
            <div className="order-complete">
              Thank you for your order! 🎉 A confirmation email has been sent to
              your inbox.{" "}
            </div>
          ) : (
            <p
              className="warning-box"
              style={{ marginTop: "30px", textAlign: "center" }}
            >
              Your cart is empty. Add something!
            </p>
          )}
          <CartSuggestions
            cartSuggestions={this.props.cartSuggestions}
            closeModal={this.closeModal}
          />
        </div>
      );
    } else {
      return (
        <div
          className="table-cell moch"
          style={{ width: "740px", paddingTop: "10px" }}
        >
          <p
            style={{
              margin: "5px",
              backgroundColor: "#fba703",
              display: this.props.international ? "" : "none",
            }}
          >
            Though TubeDepot ships most orders within 24 hours, we regret that
            some International shipping times are currently delayed at the USPS
            carrier level due to the impact of Covid-19. If you need faster
            service, please select FedEx or UPS for International service.
          </p>
          <h1 id="cart-text" style={{ marginLeft: "10px" }}>
            Shopping Cart
          </h1>

          <div id="empty-cart" data-hook>
            <p id="clear_cart_link" data-hook>
              <input
                onClick={this.props.emptyCart}
                type="submit"
                name="commit"
                value="Empty Cart"
                className="button btn btn-tubedepot"
                data-disable-with="Empty Cart"
              />
              <input
                onClick={this.closeModal}
                type="submit"
                name="commit"
                value="Close"
                className="button btn btn-tubedepot"
                style={{ marginLeft: "5px" }}
              />
            </p>
          </div>

          <div data-hook="outside_cart_form">
            <div data-hook="inside_cart_form">
              <div data-hook="cart_items">
                <CartItems
                  isMobile={this.props.isMobile}
                  changeTesting={this.props.changeTesting}
                  closeModal={this.closeModal}
                  changeQuantity={this.props.changeQuantity}
                  order={this.props.order}
                  deleteItem={this.props.deleteItem}
                />
              </div>

              <div className="sticky-bottom" style={{ marginLeft: "10px" }}>
                <div id="cart-subtotal" data-hook>
                  Total:{" "}
                  <span className="order-total">
                    {this.props.order.display_total}
                  </span>
                </div>

                <div
                  className="columns four alpha floatinput bigbox"
                  data-hook="cart_buttons"
                >
                  <input
                    size="10"
                    style={{ marginBottom: "0px" }}
                    type="text"
                    onChange={this.handleChange}
                    value={this.state.code}
                    name="order[coupon_code]"
                    id="order_coupon_code"
                  />
                  <span
                    className={
                      "floatlabel " +
                      (this.state.code == "" || this.state.code == null
                        ? null
                        : "floatme")
                    }
                  >
                    Coupon Code
                  </span>
                  <br />
                  <button
                    name="button"
                    type="submit"
                    className="primary btn btn-tubedepot"
                    id="update-button"
                    style={{ marginTop: "10px" }}
                    onClick={() => this.props.couponCode(this.state.code)}
                  >
                    Add Coupon Code
                  </button>
                  {this.props.newCheckout ? (
                    <button
                      id="go-to-checkout-button"
                      value="Go to Checkout"
                      type="submit"
                      className="primary btn btn-tubedepot"
                      style={{ float: "right", marginTop: "10px" }}
                      onClick={this.goToCheckout}
                    >
                      Go to Checkout
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Cart;
