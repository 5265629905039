import React from "react";

class BlackSableFAQ extends React.Component {
  componentDidMount() {
    gtag("event", "page_view", {
      page_path: window.location.pathname,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="content-margin bare-table constrict">
          <h2 className="title">
            BLACK SABLE Cryogenically Treated Vacuum Tubes FAQ
          </h2>
          <a name="pos0">
            <strong>Frequently Asked BLACK SABLE Questions:</strong>
          </a>
          <br />
          <br />
          <a href="#pos1" className="homelink">
            1.) What does cryogenically treated mean?
          </a>
          <br />
          <a href="#pos2" className="homelink">
            2.) Why is the BLACK SABLE process better than other company’s
            cryogenically treated tubes?
          </a>
          <br />
          <a href="#pos3" className="homelink">
            3.) Why are BLACK SABLE – Cryogenic Tubes more expensive than other
            “Cryo” tubes?
          </a>
          <br />
          <a href="#pos4" className="homelink">
            4.) What sonic improvements will I notice with BLACK SABLE –
            Cryogenic Tubes?
          </a>
          <br />
          <a href="#pos5" className="homelink">
            5.) Can I use BLACK SABLE – Cryogenic Tubes in my guitar amp?
          </a>
          <br />
          <a href="#pos6" className="homelink">
            6.) How does the BLACK SABLE process increase the longevity of a
            tube?
          </a>
          <br />
          <a href="#pos7" className="homelink">
            7.) I have some tubes that I would like to be cryogenically treated.
            Can I send you these tubes?
          </a>
          <br />
          <a href="#pos8" className="homelink">
            8.) What is the BLACK SABLE process in detail?
          </a>
          <br />
          <br />
          <br />
          <a name="pos1">
            <strong>What does cryogenically treated mean?</strong>
          </a>
          <br />
          <br />
          Cryogenic treatment is a process by which a product is cooled down to
          a temperature below -190 Fahrenheit / -123 Celsius. The BLACK SABLE
          process descends all the way down to -300 Fahrenheit / -184 Celsius.
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos2">
            <strong>
              Why is the BLACK SABLE process better than other company’s
              cryogenically treated tubes?
            </strong>
          </a>
          <br />
          <br />
          The BLACK SABLE process is far more than putting tubes in a chamber
          and lowering the temperature. The BLACK SABLE process starts with tube
          selection. Only the best preamp, power and rectifier tubes are chosen
          to undergo this arduous process. All tubes undergo a 24 hour burn-in
          under load. The tubes are then placed in the Cryo-Processor where a
          computer-controlled proprietary system lowers the temperature to -300
          Fahrenheit / -184 Celsius for 24 hours and then slowly ascends back to
          room temperature. Preamp tubes are tested and rated for Gain,
          Microphonics and Noise. Next, all 7/9 pin tubes have their pins
          cleaned for a better electrical connection. Power tubes are matched to
          within 3% for both Ip (Plate Current) and Gm (Transconductance). No
          other cryogenic process comes close. When only the best will do,
          choose BLACK SABLE.
          <br />
          <br />
          Rarely do you actually see our competitors equipment – nor is it
          mentioned in their information. We are very proud of the &nbsp;
          <strong>BLACK SABLE System</strong>
          .
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos3">
            <strong>
              Why are BLACK SABLE – Cryogenic Tubes more expensive than other
              “Cryo” tubes?
            </strong>
          </a>
          <br />
          <br />
          BEWARE of inexpensive “Cryo” treated tubes. We know how much time and
          effort is involved in producing quality cryogenically treated tubes
          and we also know that adding $1 to the cost of a tube and selling it
          as a “Cryo” tube equates to just adding a sticker to the tube box.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos4">
            <strong>
              What sonic improvements will I notice with BLACK SABLE – Cryogenic
              Tubes?
            </strong>
          </a>
          <br />
          <br />
          Some the benefits are:
          <ul>
            <li>Noise reduction</li>
            <li>Decreased noise floor for blacker blacks</li>
            <li>More 3 dimensional and focused stereo soundstage</li>
            <li>Reduction in microphonics</li>
            <li>Increased longevity</li>
          </ul>
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos5">
            <strong>
              Can I use BLACK SABLE – Cryogenic Tubes in my guitar amp?
            </strong>
          </a>
          <br />
          <br />
          Yes you can. BLACK SABLE – Cryogenic Tubes will improve the harmonics
          of your distortion and improve the quality of saturation. The net
          effect is a richer and more satisfying overdrive.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos6">
            <strong>
              How does the BLACK SABLE process increase the longevity of a tube?
            </strong>
          </a>
          <br />
          <br />
          By reducing and aligning internal stresses of the internal metal
          structures, this process allows the electrons to flow less inhibited.
          In addition, the grain structure and arrangement of carbon in the
          metal is more uniform than before cryogenic treatment. The net results
          are lower operating temperatures and longer life.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos7">
            <strong>
              I have some tubes that I would like to be cryogenically treated.
              Can I send you these tubes?
            </strong>
          </a>
          <br />
          <br />
          We do accept tubes to be treated from our customers. It is very
          expensive to run the Cryo-Processor, so we keep runs to a minimum. If
          you don’t mind to wait until we are ready to run the next batch, then
          you will be very satisfied with the outcome.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos8">
            <strong>What is the BLACK SABLE process in detail?</strong>
          </a>
          <br />
          <br />
          Vacuum tubes selected to go under the BLACK SABLE process are burned
          in under load on our burn-in racks for 24 hours. Not only are the
          filaments lit, B+ voltage is applied to the plate to simulate real
          world use. This burning in helps to finish cathode activation and
          reduce popping and static noise. These problems are normal for vacuum
          tubes and result from uneven cathode coatings or impurities. Our 24
          hour long burn-in insures that the cathode is settled down and br
          /oken in. In addition to cathode activation, burning in allows the
          plates, grids and cathode to “settle” into the mica supports. This
          step alone serves to improve the sound and performance of the vacuum
          tube.
          <br />
          <br />
          The vacuum tubes are then placed into our proprietary Cryo-Processor
          where a computer controlled program slowly reduces the temperature
          from ambient / room temperature to -300 Fahrenheit / -184 Celsius. The
          tubes will then soak at this deep cryogenic temperature for 24 hours.
          During this time, the stresses that have built up in the manufacturing
          process are reduced and the tube is annealed. To better grasp what
          this means, try to understand that vacuum tubes undergo several
          different steps in the manufacturing process where high temperatures
          are involved. Each one of these high temperature steps, from plate
          welding to envelope sealing, sets up a stress point in the tube.
          Metaphorically, you can think of these stresses like the molecular
          magnetic poles in steel. When first poured, the molecules of steel are
          all at different angles and alignments. When a strong magnetic force
          is applied to the steel, the magnetic poles of the molecules become
          aligned. The BLACK SABLE process reduces stresses by aligning and
          annealing the stress points in a tube - further reducing internal
          strains and improving performance.
          <br />
          <br />
          Cryogenic treatment has been proven to improve the performance and
          life of many different products. From critical aircraft components to
          expensive surgical tools, cryogenic treatment is essential when you
          want the best performance obtainable.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default BlackSableFAQ;
