import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class Suggest extends React.Component {
  setPrice = (product) => {
    let priceHTML = "";
    if (product.on_sale) {
      priceHTML += `
        <strike>${product.price}</strike>
        <div style="color: red; display: inline-block;">
          ${product.sale_text}
        </div>
      `;
    }
    priceHTML += product.display_price;
    return ReactHtmlParser(`<div class='price selling'>${priceHTML}</div>`);
  };

  render() {
    return (
      <ul className="search-autosuggest hidden">
        {this.props.productSuggest.map((product, index) => (
          <li key={index}>
            <Link to={{ pathname: product.path }}>
              <div className="variant-autocomplete-item media">
                <div className="variant-autocomplete-inner">
                  <div className="media-left pull-left">
                    <img src={product.image} alt={product.alt} />
                  </div>
                  <div className="media-body">
                    <h6>{product.name}</h6>
                    <dl style={{ marginTop: "0px" }}>
                      <dd className="ddprice">
                        <div className="price-container">
                          <strong>{this.setPrice(product)}</strong>
                        </div>
                      </dd>
                      <dd className="ddsku">
                        <strong>{product.sku}</strong>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
        {this.props.productSuggest.length === 5 ? (
          <li style={{ textAlign: "center", padding: "5px" }}>
            <button
              id="product-suggest-see-more"
              className="btn btn-tubedepot btn-large"
              onClick={(event) => this.props.handleSubmit(event)}
            >
              See More
            </button>
          </li>
        ) : (
          ""
        )}
      </ul>
    );
  }
}

export default Suggest;
