import React from "react";

class Options extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    let variantSelect = document.querySelector('select[name="variant_id"]');
    if (variantSelect && this.props.masterId !== prevProps.masterId) {
      variantSelect.selectedIndex = 0;
    }
    if (this.props.matchingTest !== prevProps.matchingTest) {
      this.handleTestSelection();
    }

    let matchingTest = document.querySelector(
      "div#test-types input#Matching-checkbox[type='checkbox']",
    );
    if (
      this.props.quantity <= 1 &&
      matchingTest !== null &&
      matchingTest.checked
    ) {
      matchingTest.checked = false;
      this.handleTestSelection();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.masterId !== nextProps.masterId) {
      Array.from(
        document.querySelectorAll("div#test-types input[type='checkbox']"),
      ).forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }

  setTestDescription = (test) => {
    if (test.name.includes("Matching") && this.props.quantity <= 1) {
      return "Matching will become available after entering 2 or more tubes.";
    } else {
      return (
        test.name + " (add $" + parseFloat(test.price).toFixed(2) + " each)"
      );
    }
  };

  setPriceDiff = (variant) => {
    if (this.props.productPrice !== variant.price) {
      let priceDiff = (
        parseFloat(variant.price) - parseFloat(this.props.productPrice)
      ).toFixed(2);
      return priceDiff > 0
        ? `(Add: $${priceDiff})`
        : `(Subtract: $${priceDiff})`;
    }
  };

  handleVariantChange = (event) => {
    let nextVariant = this.props.variants.find(
      (variant) => variant.id === parseInt(event.target.value),
    );
    this.props.handleChange({ selectedVariant: nextVariant });
    this.props.updatePriceSheet(event.target.value);
  };

  handleTestSelection = () => {
    let selected = Array.from(
      document.querySelectorAll(
        "div#test-types input[type='checkbox']:checked",
      ),
    );
    let selectedTests = {};
    selected.forEach((type) => {
      selectedTests[type.value] = 1;
    });
    this.props.handleChange({ selectedTests: selectedTests });
  };

  render() {
    return (
      <div id="test-types">
        {this.props.testTypes.map((test, index) => (
          <label
            className="checkbox hide"
            style={{ display: "block" }}
            key={index}
          >
            <input
              id={test.name.split(" ")[0] + "-checkbox"}
              type="checkbox"
              name={test.name}
              value={test.id}
              onClick={() => this.handleTestSelection()}
              disabled={
                test.name.includes("Matching") && this.props.quantity <= 1
              }
            />
            {this.setTestDescription(test)}
          </label>
        ))}
        {this.props.variants.length > 0 ? (
          <select
            name="variant_id"
            id="variant_id"
            className="variant-selector"
            style={{ height: "34px" }}
            onChange={(event) => this.handleVariantChange(event)}
          >
            {this.props.variants.map((variant, index) => (
              <option key={index} value={variant.id}>
                {variant.options_text} {this.setPriceDiff(variant)}
              </option>
            ))}
          </select>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Options;
