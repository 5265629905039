import React from "react";
import { Link } from "react-router-dom";
import CartItem from "./CartItem";
import Adjustments from "./Adjustments";
class CartItems extends React.Component {
  render() {
    return (
      <table id="cart-detail" data-hook>
        <colgroup>
          {this.props.isMobile ? null : <col width="10%" />}
          <col width="50%" />
          <col width="5%" />
          <col width="5%" />
          <col width="5%" />
          <col width="5%" />
        </colgroup>
        <thead>
          <tr data-hook="cart_items_headers">
            {this.props.isMobile ? (
              <th className="cart-item-description-header sticky">ITEM</th>
            ) : (
              <th className="cart-item-description-header sticky" colSpan="2">
                ITEM
              </th>
            )}
            <th className="cart-item-price-header sticky">PRICE</th>
            <th className="cart-item-quantity-header sticky">QTY</th>
            <th className="cart-item-total-header sticky">TOTAL</th>
            <th className="cart-item-delete-header sticky"></th>
          </tr>
        </thead>
        <CartItem
          isMobile={this.props.isMobile}
          changeTesting={this.props.changeTesting}
          closeModal={this.props.closeModal}
          changeQuantity={this.props.changeQuantity}
          items={this.props.order.items}
          deleteItem={this.props.deleteItem}
        />
        <Adjustments adjustments={this.props.order.adjustments} />
      </table>
    );
  }
}

export default CartItems;
