import React from "react";

class BulkPricing extends React.Component {
  render() {
    return (
      <div id="bulk-pricing" style={{ display: "block" }}>
        <div className="bulk-title-bar">
          <button
            id="bulk-close"
            className="close"
            onClick={(newState) => this.props.handleChange({ bulkOpen: false })}
          >
            x
          </button>
          <h4>Bulk Pricing Guide</h4>
        </div>
        <div className="module-cnt border">
          <table className="bare-table">
            <tbody>
              {this.props.priceSheet.map((price, index) => (
                <tr key={index}>
                  <td style={{ borderLeft: "none" }}>
                    {price.minimum_quantity}
                    {price.maximum_quantity === null
                      ? "+"
                      : " - " + price.maximum_quantity}
                  </td>
                  <td>(${price.price_per} each)</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default BulkPricing;
