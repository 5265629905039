import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { Redirect } from "react-router-dom";
import Address from "./Address";
import LineItems from "./LineItems";
import Tracking from "./Tracking";

class Order extends React.Component {
  constructor() {
    super();
    this.repeatOrder = this.repeatOrder.bind(this);
    this.state = {
      lineItems: [],
      shipAddress: "",
      billAddress: "",
      shipments: [],
      payments: [],
      totals: {},
      canRepeat: false,
      redirect: false,
    };
  }

  componentDidMount() {
    var that = this;
    fetch("/react/orders/" + this.props.match.params.orderNumber)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      })
      .then((data) => {
        this.setState({
          lineItems: data.line_items,
          shipAddress: data.ship_address,
          billAddress: data.bill_address,
          shipments: data.shipments,
          payments: data.payments,
          totals: data.totals,
          canRepeat: data.can_repeat,
        });
        document.title = "Your Order | TubeDepot.com";
        gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      })
      .catch(function (error) {
        that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
        that.setState({
          redirect: true,
        });
      });
  }

  repeatOrder() {
    this.props.repeatOrder(this.props.match.params.orderNumber);
  }

  setPayments = () => {
    let paymentHTML = "";
    this.state.payments.map((payment) => {
      if (payment.name) {
        paymentHTML =
          paymentHTML + `Ending in ${payment.last_digits}<br />${payment.name}`;
      } else {
        paymentHTML = paymentHTML + `${payment.method_name}`;
      }
    });

    return ReactHtmlParser(paymentHTML);
  };

  render() {
    if (this.state.redirect) return <Redirect to="/" />;
    return (
      <div className="container">
        <div className="_mx-auto content-margin constrict">
          <fieldset id="order_summary" data-hook>
            <legend align="center">
              Order #{this.props.match.params.orderNumber}
              <button
                className="button btn-tubedepot btn btn-large pull-right"
                onClick={this.repeatOrder}
                disabled={!this.state.canRepeat}
              >
                Repeat This Order
              </button>
            </legend>
            <div id="order" data-hook>
              <div className="content-margin">
                <div className="row steps-data">
                  {this.state.shipAddress !== "" ? (
                    <div className="row pad-below">
                      <div
                        className="span3 pull-left"
                        data-hook="order-ship-address"
                      >
                        <h6>Shipping Address</h6>
                        <Address address={this.state.shipAddress} />
                      </div>

                      <div
                        className="span3 pull-left"
                        data-hook="order-bill-address"
                      >
                        <h6>Billing Address</h6>
                        <Address address={this.state.billAddress} />
                      </div>

                      <div className="span4 pull-left">
                        <h6>Shipments</h6>
                        <div className="delivery">
                          {this.state.shipments.map(
                            (shipment) =>
                              shipment.origin + " " + shipment.method,
                          )}
                          <br />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row">
                    <div className="span2 pull-left">
                      <h6>Payment Information</h6>
                      <div className="payment-info">{this.setPayments()}</div>
                    </div>
                    <div className="span6 pull-right">
                      {this.state.lineItems.length !== 0 ? (
                        <Tracking
                          orderNumber={this.props.match.params.orderNumber}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {this.state.lineItems.length !== 0 ? (
                <LineItems
                  lineItems={this.state.lineItems}
                  totals={this.state.totals}
                />
              ) : (
                ""
              )}
            </div>
            <br className="clear" />
            <p data-hook="links"></p>
          </fieldset>
        </div>
      </div>
    );
  }
}

export default Order;
