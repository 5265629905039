import React from "react";

class WarrantyandReturn extends React.Component {
  componentDidMount() {
    gtag("event", "page_view", {
      page_path: window.location.pathname,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="content-margin bare-table constrict">
          <h2 className="title">Warranty and Returns</h2>
          <strong>Warranty Guidelines</strong>
          <br />
          <br />
          All of our tubes are guaranteed to perform as described. We fully test
          each and every tube to ensure this reliability. The warranty for NOS
          (New) tubes is 90 days from date of purchase. Used tubes have a
          warranty of 45 days from date of purchase. If your tube fails within
          these warranty periods, we will replace, refund (if within 14 days) or
          credit your account, depending on current stock. &nbsp;
          <strong>
            There can be no returns for cash refund after 14 days.
          </strong>
          TubeDepot.com will in no way be held responsible (financially or
          otherwise) for equipment that is damaged from the use of tubes or
          parts purchased from us. It is the responsibility of the customer to
          consult with the equipment manufacturer with regards to proper tube
          and parts replacement and recommended / proper biasing procedures.
          <br />
          <br />
          <strong>Returns Guidelines</strong>
          <br />
          <br />
          If you have an item to return or exchange, you must call or email
          TubeDepot to receive authorization. This allows us to ascertain what
          may be wrong and to discuss options with the customer. If
          authorization is given, please make sure to include the TubeDepot
          Invoice that was originally sent with your order. Original and Return
          (to TubeDepot.com) shipping costs are the responsibility of the
          customer and cannot be refunded. If the products are defective,
          TubeDepot.com will gladly waive shipping costs to send a replacement
          order to the customer. The exception to this is when TubeDepot.com has
          made an error, then TubeDepot.com will pay for shipping back to
          TubeDepot.com and then back to the customer.
          <br />
          <br />
          <ul>
            <strong>
              <li>
                All returned goods must be returned in the original product
                packaging and in an adequate shipping container (ie: no padded
                envelopes or otherwise) in order for the return to be processed.
              </li>
            </strong>
          </ul>
          <br />
          <br />
          If the customer places an order and receives the shipment and part or
          all of the contents damaged from rough handling, then TubeDepot.com
          will send replacement items at no charge and file a claim with the
          carrier. If the customer places an order and receives the shipment and
          part or all of the shipment turns out to be defective after it is
          used/installed (Ex: power tube plates turning red), then there are 2
          options.*
          <br />
          <br />
          <ol>
            <li>
              ) If you are in a hurry, we can send you a replacement right away.
              For this we charge you only for the part, not for shipping. When
              you return the defective part to us, we will issue credit back
              onto your credit card or PayPal account.
            </li>
            <br />
            <li>
              ) The slower option is for you to return the part to us via the
              most affordable method (usually USPS First Class) and we will send
              the replacement to you free of charge once we receive the
              defective item.
            </li>
          </ol>
          <br />
          <br />
          Whichever option you choose, please put a note describing what the
          problem was and the plan of action taken. A printout of any email
          correspondence with us is perfect.
          <br />
          <br />
          <strong>Ship all returns to:</strong>
          <br />
          TubeDepot.com
          <br />
          1958 Vanderhorn Drive
          <br />
          Memphis, TN 38134
          <br />
          <br />
          * Because TubeDepot.com needs to test these items to determine mode of
          failure, a free replacement cannot be sent.
          <br />
          <br />
          <strong>Re-Stock Fee Guidelines</strong>
          <br />
          <br />A 20% restock fee is standard for the following:
          <ul>
            <li>
              Items are ordered and then the shipment is refused (Non-Defective
              Refusal), we charge restock plus the FedEx $10 refused fee.
            </li>
            <li>
              Items are ordered and received, but returned with no use and with
              original, unmarked packaging (Non-Defective Return).
            </li>
            <li>
              Items are ordered, received and used, then returned in its
              original condition with no marks on the product or packaging
              (Non-Defective Original Condition Return).
            </li>
            <li>
              Items are ordered, received and used (with apparent use), then
              returned to us (Non-Defective Apparent Use Return), then the items
              will be sent back at the customer’s expense.
            </li>
            <li>
              The restock fee serves to insure that the product we restock meets
              our manufacturer's quality standards. Returned product is often
              sent back to the manufacturer for quality verification or
              replacement. The restock fee also gives our customers peace of
              mind that their tubes and / or parts have not been 'tried out' by
              a previous customer.
            </li>
          </ul>
          <strong>
            There are no cash refunds after 14 days only store credit
          </strong>
          <br />
          <br />
          The following items &nbsp;
          <strong>cannot</strong>
          &nbsp; be returned:
          <ul>
            <li>Anything cut to length (Wire, G-10 Board etc)</li>
            <li>If a speaker is installed.</li>
            <li>Consumables (Solder, Chemicals etc.).</li>
            <li>Any kit, once started.</li>
          </ul>
          TubeDepot.com reserves the right to amend / change these guidelines
          without notice. If you have any questions, &nbsp;
          <a href="mailto:info@tubedepot.com">email us</a>
          &nbsp; and we will get back to you as soon as possible.
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default WarrantyandReturn;
