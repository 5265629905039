import React from "react";
import { Link } from "react-router-dom";
class Tubes extends React.Component {
  render() {
    return (
      <li
        className="dropdown"
        onMouseEnter={() => this.props.open("Tubes")}
        onMouseLeave={this.props.close}
      >
        <a href="#">
          Tubes<b className="caret"></b>
        </a>
        <ul
          className="dropdown-menu nav-tubes"
          style={{
            visibility: this.props.opened == "Tubes" ? "visible" : "hidden",
          }}
        >
          <li>
            <div className="row">
              <div className="pull-left multi-border">
                <h5>Tubes by Brand</h5>
                <ul className="multi-dropdown-menu pull-left limitcolumns">
                  {this.props.brands.map((itemMap, key) => (
                    <li onClick={this.props.close} key={key}>
                      <Link to={itemMap.path}>{itemMap.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="pull-left multi-border">
                <h5>Tube Categories</h5>
                <ul className="multi-dropdown-menu pull-left">
                  {this.props.categories.map((itemMap, key) => (
                    <li onClick={this.props.close} key={key}>
                      <Link to={itemMap.path}>{itemMap.name}</Link>
                    </li>
                  ))}
                  <li onClick={this.props.close}>
                    <Link to="/t/nixie-tubes-and-clocks">
                      Nixie Tubes and Clocks
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="pull-left">
                <h5>Tubes By Amp</h5>
                <ul className="multi-dropdown-menu pull-left">
                  <li onClick={this.props.close}>
                    <Link to="/tubes_by_amp/Guitar%2FBass+Amp">
                      Guitar/Bass Amp
                    </Link>
                  </li>
                  <li onClick={this.props.close}>
                    <Link to="/tubes_by_amp/Home+Audio">Home Audio</Link>
                  </li>
                  <li onClick={this.props.close}>
                    <Link to="/tubes_by_amp/Organ">Organ</Link>
                  </li>
                  <li onClick={this.props.close}>
                    <Link to="/tubes_by_amp/Recording+Gear">
                      Recording Gear
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row border-top">
              <div className="pull-left">
                <h5>Quick Picks</h5>
                <ul className="multi-dropdown-menu-columns">
                  <li>
                    <ul className="multi-dropdown-menu limitcolumns5">
                      {this.props.quickpicks.map((itemMap, key) => (
                        <li
                          onClick={this.props.close}
                          className="qpwidth"
                          key={key}
                        >
                          <Link to={itemMap.path}>{itemMap.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </li>
    );
  }
}

export default Tubes;
