import React from "react";
import { ReCaptcha } from "react-recaptcha-google";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class Details extends React.Component {
  constructor() {
    super();
    this.state = {
      comment: "",
      starPreview: 0,
      starRating: 0,
      name: "",
      recaptchaResponse: "",
    };
  }

  componentDidMount() {
    Array.from(document.querySelectorAll("#comment-modal i.fa-star")).forEach(
      (star) => {
        star.addEventListener("click", this.handleStarChange);
        star.addEventListener("mouseover", this.handleStarPreview);
        star.addEventListener("mouseout", this.handleStarPreview);
      },
    );
    this.captchaDemo.reset();
  }

  componentWillUnmount() {
    Array.from(document.querySelectorAll("#comment-modal i.fa-star")).forEach(
      (star) => {
        star.removeEventListener("click", this.handleStarChange);
        star.removeEventListener("mouseover", this.handleStarPreview);
        star.removeEventListener("mouseout", this.handleStarPreview);
      },
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const that = this;
    this.props.startLoading();
    fetch("/react/customer_comments", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector("meta[name='csrf-token']")
          .getAttribute("content"),
      },
      body: JSON.stringify({
        customer_comment: {
          comment: this.state.comment,
          star_rating: this.state.starRating == 0 ? 5 : this.state.starRating,
          name: this.state.name,
          product_id: this.props.productId,
        },
        "g-recaptcha-response": this.state.recaptchaResponse,
      }),
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw "whoops";
      })
      .then((data) => {
        that.props.stopLoading();
        that.props.notify(
          "success",
          "Your review has been submitted. Thank you!",
        );
        return this.props.updateComments(data);
      })
      .catch(function (error) {
        that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  };

  handleChange = () => {
    let state_name = event.target.id.match(/\-(.*)/)[1];
    let newState = {};
    newState[state_name] = event.target.value;
    this.setState(newState);
  };

  handleStarPreview = (event) => {
    let preview;
    if (event.type === "mouseover") {
      preview = parseInt(event.target.id);
    } else if (event.type === "mouseout") {
      preview = this.state.starRating;
    }

    this.setState({
      starPreview: preview,
    });
  };

  handleStarChange = (event) => {
    this.setState({
      starPreview: parseInt(event.target.id),
      starRating: parseInt(event.target.id),
    });
  };

  verifyCallback = (recaptchaToken) => {
    this.setState({ recaptchaResponse: recaptchaToken });
  };

  render() {
    return (
      <div id="comment-modal">
        <form onSubmit={this.handleSubmit}>
          <div style={{ fontSize: "24px", cursor: "pointer" }}>
            {this.props.setRating(this.state.starPreview)}
            <br />
            <br />
            <ReCaptcha
              ref={(el) => {
                this.captchaDemo = el;
              }}
              size="normal"
              data-theme="dark"
              render="explicit"
              sitekey={this.props.recaptchaKey}
              verifyCallback={this.verifyCallback}
            />
          </div>
          <label>
            Name:
            <br />
            <input
              id="comment-name"
              type="text"
              value={this.state.name}
              onChange={this.handleChange}
              required
            />
          </label>
          <label>
            Review:
            <br />
            <textarea
              id="comment-comment"
              style={{ height: "50px" }}
              value={this.state.comment}
              onChange={this.handleChange}
              maxLength="500"
              required
            />
            <br />
            <i>
              {500 -
                (this.state.comment.length +
                  (this.state.comment.match(/\n/g) || []).length)}{" "}
              Characters Remaining
            </i>
          </label>
          <button className="btn btn-tubedepot" type="submit">
            <span>Submit Review</span>
          </button>
        </form>
        <button
          className="btn btn-tubedepot"
          style={{ marginLeft: "20px", cursor: "pointer" }}
          onClick={this.props.toggleModal}
        >
          <span>Cancel</span>
        </button>
      </div>
    );
  }
}

export default Details;
