import React from "react";
import { Link } from "react-router-dom";
class OtherStuff extends React.Component {
  render() {
    return (
      <li
        className="dropdown"
        onMouseEnter={() => this.props.open("Other")}
        onMouseLeave={this.props.close}
      >
        <a href="#" data-toggle="dropdown">
          Other Stuff<b className="caret"></b>
        </a>
        <ul
          className="dropdown-menu"
          style={{
            visibility: this.props.opened == "Other" ? "visible" : "hidden",
          }}
        >
          <div className="nav-other pull-left">
            <h5>Other Stuff</h5>
            <ul className="multi-dropdown-menu pull-left limitcolumns">
              {this.props.otherstuff.map((itemMap, key) => (
                <li
                  onClick={this.props.close}
                  style={{ maxWidth: "200px" }}
                  key={key}
                >
                  <Link to={itemMap.path}>{itemMap.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </ul>
      </li>
    );
  }
}

export default OtherStuff;
