import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class ShippingFAQ extends React.Component {
  componentDidMount() {
    gtag("event", "page_view", {
      page_path: window.location.pathname,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="content-margin bare-table constrict">
          <h2 className="title">Shipping FAQ</h2>
          <a name="pos0">
            <strong>Frequently Asked Shipping Questions:</strong>
          </a>
          <br />
          <br />
          <a href="#pos1" className="homelink">
            Does my order qualify for free shipping?
          </a>
          <br />
          <a href="#pos2" className="homelink">
            What shipping options do you offer?
          </a>
          <br />
          <a href="#pos11" className="homelink">
            Are international customers subject to additional duty and tax on
            their orders?
          </a>
          <br />
          <a href="#pos3" className="homelink">
            I placed my order for "FedEx Overnight Delivery". Will I receive it
            on the next day?
          </a>
          <br />
          <a href="#pos4" className="homelink">
            Can I get my package on a Saturday?
          </a>
          <br />
          <a href="#pos5" className="homelink">
            Do you ship to Post Office boxes?
          </a>
          <br />
          <a href="#pos6" className="homelink">
            What if I know I won't be home when FedEx tries to deliver. Will
            they leave it at the door?
          </a>
          <br />
          <a href="#pos7" className="homelink">
            I just placed an order for rare and expensive glass tubes. Will you
            pack them well so they arrive unharmed?
          </a>
          <br />
          <a href="#pos8" className="homelink">
            I want to place an international order. Will you ship to my county?
          </a>
          <br />
          <a href="#pos9" className="homelink">
            Will I get a tracking number for my order?
          </a>
          <br />
          <a href="#pos10" className="homelink">
            How will you ship my order with hazardous materials?
          </a>
          <br />
          <br />
          <br />
          <a name="pos1">
            <strong>Does my order qualify for free shipping?</strong>
          </a>
          <br />
          <br />
          Qualified orders over $99 will ship to you without incurring a
          shipping fee. The following guidelines should be taken into account:
          <br />
          <br />
          <li>
            The free shipping option allows TubeDepot to choose the most
            appropriate carrier and service.
          </li>
          <li>
            Domestic US shipments under 1 pound usually ship USPS First Class.
          </li>
          <li>
            Domestic US shipments between 1-7.9 pounds usually ship FedEx Ground
            or USPS Priority Mail.
          </li>
          <li>
            Domestic US shipments over 8 pounds will be subject to shipping
            fees. If you place an order for a heavy or bulky item over 8 pounds,
            you will see your shipping options at checkout. Since TubeDepot is
            in Memphis, TN, the home and main hub for FedEx and USPS, we get
            very good rates, so rest assured your heavy item will be shipped at
            the most affordable rates.
          </li>
          <li>
            Free shipping is limited to the contiguous 48 states. Free shipping
            is not available to Alaska, Hawaii, U.S. Territories and Outlying
            Areas, APO/FPO/DPOs or International destinations.
          </li>
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos2">
            <strong>What Shipping options do you offer?</strong>
          </a>
          <br />
          <br />
          All Orders (Domestic and International) will ship to you via FedEx or
          USPS service. At checkout, you will be offered a multitude of shipping
          options. The cost of these shipping services will depend on the weight
          and destination of your order. You can select the best option based on
          your time requirements and cost preference.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos3">
            <strong>
              I placed my order for "FedEx Overnight Delivery". Will I receive
              it on the next day?
            </strong>
          </a>
          <br />
          <br />
          If you placed an order for "FedEx Overnight Delivery" during the work
          week (Monday - Thursday) before 3:00 PM Central time, then you will
          receive your order the next day. If you place your order on Friday for
          "FedEx Overnight Delivery", then your order will be shipped on Friday,
          but will be delivered on the next business day - Monday. If there is a
          holiday, then that day should be taken into account. FedEx will
          deliver on a Saturday, but you must call this request in to us. There
          will be an extra fee for this ultra-premium service. FedEx does not
          deliver on Sunday.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos11">
            <strong>
              Are international customers subject to additional duty and tax on
              their orders?
            </strong>
          </a>
          <br />
          <br />
          Yes, international customers will be subject to any additional duty
          and tax applied by customs in their country. TubeDepot is not notified
          when or if import tax will be applied by customs.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos4">
            <strong>Can I get my package on a Saturday?</strong>
          </a>
          <br />
          <br />
          Yes, you must place your order by 3:00 PM Central time on Friday AND
          you must notify us by calling our Toll Free number (877-289-7994).
          There will be an additional charge applied to your order for this
          service.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos5">
            <strong>Do you ship to Post Office boxes?</strong>
          </a>
          <br />
          <br />
          Yes, shipping to a PO Box requires that you choose a USPS shipping.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos6">
            <strong>
              What if I know I won't be home when FedEx tries to deliver. Will
              they leave it at the door?
            </strong>
          </a>
          <br />
          <br />
          Yes, FedEx by default leaves all packages at the door. If you want to
          sign for your package, there will be an extra $1.50 charge.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos7">
            <strong>
              I just placed an order for rare and expensive glass tubes. Will
              you pack them well so they arrive unharmed?
            </strong>
          </a>
          <br />
          <br />
          We use new, corrugated brown boxes for every order. Tubes are wrapped
          in triple-ply Kraft paper. Because of its natural give and stiffness,
          Kraft paper provides a much better cushion for tubes as compared to
          styrofoam peanuts or foam - and it can be recycled. All of our
          products are packaged this way regardless of value.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos8">
            <strong>
              I want to place an international order. Will you ship to my
              county?
            </strong>
          </a>
          <br />
          <br />
          Yes, we ship worldwide.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos9">
            <strong>Will I get a tracking number for my order?</strong>
          </a>
          <br />
          <br />
          Yes, we will email tracking information and your invoice upon
          shipment. If you placed an order and you have not received an email
          with a tracking number, it may be because of "Spam Blocking".
          Unfortunately these "Spam Blockers" may see our tracking confirmation
          email as Spam. We have no control over your Internet Service Provider
          and the way they stop Spam. If you have not received a tracking number
          for your shipment, please email or call (Toll Free: 877-289-7994) us
          and we will be happy to provide it to you.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos10">
            <strong>
              How will you ship my order with hazardous materials?
            </strong>
          </a>
          <br />
          <br />
          Some of our products are flammable or pressurized. These products can
          only ship by domestic ground service. No international or air shipping
          is available for these items. If you request a domestic air shipping
          service, the hazardous materials will ship by ground in a separate
          package. For international orders, these items must be removed. We can
          ship these items FedEx ground international to Canada.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default ShippingFAQ;
