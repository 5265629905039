import React from "react";
import { Link } from "react-router-dom";

class Category extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        items: [],
      },
      display: [],
      searchTerm: "",
    };
    this.searchTba = this.searchTba.bind(this);
  }

  componentDidMount() {
    const that = this;
    return fetch("/react/tbacategorydata")
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState({ data: data, display: data.items });
        document.title = "Tubes by Amp | TubeDepot.com";
        gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      })
      .catch(function (error) {
        that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  searchTba(e) {
    if (e.target.value == "") {
      this.setState({
        display: this.state.data.items,
        searchTerm: e.target.value,
      });
    } else {
      var search = this.props.search.search;
      var filtered = search.filter((amp) =>
        amp.name.toLowerCase().includes(e.target.value.toLowerCase()),
      );
      this.setState({ display: filtered, searchTerm: e.target.value });
    }
  }

  render() {
    return (
      <div>
        <br />
        <div className="constrict">
          <div className="title">
            <h1>Tube Sets By Amp</h1>
          </div>
          <p>
            Whether you are replacing the tubes in your guitar/bass amp;
            updating your home audio system: re-tubing your microphone or
            changing out the organ tubes, we have what you need right here. Each
            selection below will lead you to the brands and models you love for
            all of your equipment. We’ll then show you the entire set you need
            to fill your gear and we’ll offer several different brands to choose
            from, from standard to premium.
          </p>
          <div className="floatinput checkout-field">
            <input
              type="text"
              className="tbasearch"
              onChange={this.searchTba}
            />
            <span
              className={
                "floatlabel " +
                (this.state.searchTerm == "" || this.state.searchTerm == null
                  ? null
                  : "floatme")
              }
            >
              Search for your amplifier..
            </span>
          </div>
          <br />
          <br />
          <ul
            id="products"
            className="inline product-listing non-filter marpr tba-products"
            data-hook
          >
            {this.state.display.map((itemMap, key) => (
              <li
                id={itemMap.id}
                key={key}
                className="span3"
                data-hook="products_list_item"
                itemScope
                itemType="http://schema.org/Product"
              >
                <Link to={itemMap.path}>
                  <div className="taxon-product">
                    <div className="taxon-product-image">
                      <object>
                        <a>
                          <img itemProp="image" src={itemMap.image} />
                        </a>
                      </object>
                    </div>
                    <div className="taxon-product-info">
                      <object>
                        <a>{itemMap.name}</a>
                      </object>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          <i>
            All registered trademarks and copyrights are the property of their
            respective owners.
          </i>
        </div>
      </div>
    );
  }
}

export default Category;
