import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import SearchLocationInput from "./SearchLocationInput";
class AddressFields extends React.Component {
  constructor(props) {
    super(props);
    this.addressChange = this.addressChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.unsetLoading = this.unsetLoading.bind(this);
    this.changeTextState = this.changeTextState.bind(this);
    this.changeAutofillState = this.changeAutofillState.bind(this);
    this.searchStates = this.searchStates.bind(this);
    this.state = {
      email: props.email == "Account" ? "" : props.email,
      name: props.address.name,
      city: props.address.city,
      state:
        props.address.state_id == null
          ? props.address.state
          : { label: props.address.state, value: props.address.state_id },
      country:
        props.address.country == null
          ? { label: "United States", iso: "US" }
          : { label: props.address.country, iso: props.address.iso },
      zipcode: props.address.zipcode,
      phone: props.address.phone,
      address1: props.address.address1,
      address2: props.address.address2,
      isLoading: false,
    };
  }

  addressChange(e) {
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.setAddress(this.state, this.props.isShipping);
  }

  changeCountry = (selectedOption) => {
    this.setState({ country: selectedOption, isLoading: true, state: "" });
    this.props.loadStates(selectedOption.value, this.unsetLoading);
  };

  unsetLoading() {
    this.setState({ isLoading: false });
  }

  changeState = (selectedOption) => {
    this.setState({ state: selectedOption });
  };

  changeAutofillState(e) {
    var state = this.searchStates(e.target.value);

    this.setState({ state: state });
  }

  searchStates(state) {
    var states = this.props.locations.states;
    for (var i = 0; i < states.length; i++) {
      if (states[i].label === state || states[i].value === state) {
        return states[i];
      }
    }
  }

  changeTextState(e) {
    this.setState({ state: e.target.value });
  }

  resultsMethod = (results) => {
    let parsedAddress = {};
    let streetLine = [];
    let country;
    results.address_components.forEach((component) => {
      if (component.types.includes("street_number")) {
        streetLine.unshift(component.long_name);
      } else if (component.types.includes("route")) {
        streetLine.push(component.short_name);
      } else if (
        component.types.includes("locality") ||
        component.types.includes("neighborhood")
      ) {
        parsedAddress["city"] = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        parsedAddress["state"] = {
          label: component.long_name,
          value: component.short_name,
        };
      } else if (component.types.includes("country")) {
        country = this.props.locations.countries_and_states.find(
          (country) => country.label === component.long_name,
        );
        parsedAddress["country"] = { label: country.label, iso: country.iso };
      } else if (component.types.includes("postal_code")) {
        parsedAddress["zipcode"] = component.long_name;
      }
    });
    parsedAddress["address1"] = streetLine.join(" ");
    parsedAddress["state"] =
      country.states.find(
        (state) =>
          state.label === parsedAddress["state"].label ||
          state.value === parsedAddress["state"].value,
      ) || null;

    this.setState({ ...this.state, ...parsedAddress }, () =>
      this.props.loadStates(parsedAddress["country"].value, this.unsetLoading),
    );
  };

  render() {
    return (
      <div className="checkout-field">
        <div>
          <SearchLocationInput resultsMethod={this.resultsMethod} />
        </div>
        <form onSubmit={this.handleSubmit}>
          {this.props.isShipping && this.props.email == "Account" ? (
            <div className="floatinput checkout-fullwidth">
              <input
                type="text"
                value={this.state.email}
                name="email"
                onChange={this.addressChange}
                required
                autoComplete="email"
              />
              <span
                className={
                  "floatlabel " +
                  (this.state.email == "" || this.state.email == null
                    ? null
                    : "floatme")
                }
              >
                Email
              </span>
            </div>
          ) : null}
          <div className="floatinput checkout-fullwidth">
            <input
              type="text"
              value={this.state.name}
              name="name"
              onChange={this.addressChange}
              required
              autoComplete="name"
            />
            <span
              className={
                "floatlabel " +
                (this.state.name == "" || this.state.name == null
                  ? null
                  : "floatme")
              }
            >
              Name
            </span>
          </div>
          <div className="floatinput checkout-fullwidth">
            <input
              type="text"
              value={this.state.address1}
              name="address1"
              onChange={this.addressChange}
              required
              autoComplete="address-line1"
            />
            <span
              className={
                "floatlabel " +
                (this.state.address1 == "" || this.state.address1 == null
                  ? null
                  : "floatme")
              }
            >
              Street Address
            </span>
          </div>
          <div className="floatinput checkout-width">
            <input
              type="text"
              value={this.state.address2}
              name="address2"
              onChange={this.addressChange}
              autoComplete="address-line2"
            />
            <span
              className={
                "floatlabel " +
                (this.state.address2 == "" || this.state.address2 == null
                  ? null
                  : "floatme")
              }
            >
              Address 2
            </span>
          </div>
          <div className="floatinput checkout-width">
            <input
              type="text"
              value={this.state.city}
              name="city"
              onChange={this.addressChange}
              required
            />
            <span
              className={
                "floatlabel " +
                (this.state.city == "" || this.state.city == null
                  ? null
                  : "floatme")
              }
            >
              City
            </span>
          </div>
          <div className="floatinput checkout-fullwidth">
            <Select
              className="location-select"
              value={this.state.country}
              onChange={this.changeCountry}
              options={this.props.locations.countries}
              placeholder=""
              name="country"
              isSearchable
              autoComplete="country"
            />
            <span className="floatlabel floatme">Country</span>
          </div>
          {this.props.locations.states === undefined ||
          this.props.locations.states.length == 0 ? (
            <div className="floatinput checkout-fullwidth">
              <input
                required
                type="text"
                value={this.state.state}
                name="state"
                onChange={this.changeTextState}
              />
              <span
                className={
                  "floatlabel " +
                  (this.state.state == "" || this.state.state == null
                    ? null
                    : "floatme")
                }
              >
                State / Province
              </span>
            </div>
          ) : (
            <div className="chrome-autofix floatinput checkout-fullwidth">
              <input
                style={{
                  width: "0px",
                  height: "0px",
                  opacity: "0",
                  position: "absolute",
                }}
                type="text"
                name="state"
                onChange={this.changeAutofillState}
              />
              <Select
                className="location-select"
                disabled={this.state.isLoading}
                isLoading={this.state.isLoading}
                name="state"
                value={this.state.state}
                onChange={this.changeState}
                placeholder=""
                options={this.props.locations.states}
                isSearchable
                matchPos="start"
                required
              />
              <span className="floatlabel floatme">State / Province</span>
            </div>
          )}
          <div className="floatinput checkout-width">
            <input
              type="text"
              value={this.state.zipcode}
              name="zipcode"
              onChange={this.addressChange}
              required
              autoComplete="postal_code"
            />
            <span
              className={
                "floatlabel " +
                (this.state.zipcode == "" || this.state.zipcode == null
                  ? null
                  : "floatme")
              }
            >
              Postal Code
            </span>
          </div>
          <div className="floatinput checkout-width">
            <input
              type="text"
              value={this.state.phone}
              name="phone"
              onChange={this.addressChange}
              required
              autoComplete="tel"
            />
            <span
              className={
                "floatlabel " +
                (this.state.phone == "" || this.state.phone == null
                  ? null
                  : "floatme")
              }
            >
              Phone
            </span>
          </div>
          <input
            style={{ height: "auto", width: "225px" }}
            type="submit"
            value="Submit"
            className="btn btn-tubedepot"
          />
        </form>
      </div>
    );
  }
}

export default AddressFields;
