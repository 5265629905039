import React from "react";
import { Link } from "react-router-dom";
import Tubes from "./Navbar/Tubes";
import Parts from "./Navbar/Parts";
import GuitarAmps from "./Navbar/GuitarAmps";
import Pedals from "./Navbar/Pedals";
import HiFiGear from "./Navbar/HiFiGear";
import OtherStuff from "./Navbar/OtherStuff";
import UtilityNav from "./Navbar/UtilityNav";
import { findDOMNode } from "react-dom";
class Navbar extends React.Component {
  constructor() {
    super();
    this.navRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.state = {
      opened: "",
    };
  }

  open(name) {
    this.setState({ opened: name });
  }

  close() {
    this.setState({ opened: "" });
  }

  componentDidMount() {
    this.offset = 74; //Initial offset of navbar - if navbar height changes, this needs to be changed too!
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    var $window = $(window),
      $stickyEl = $(this.navRef.current);
    $stickyEl.toggleClass("sticky", $window.scrollTop() > this.offset);
  }

  render() {
    return (
      <div className="navbar" ref={this.navRef}>
        <div className="navbar-inner constrict">
          <div className="container">
            <ul className="nav">
              <Tubes
                opened={this.state.opened}
                open={this.open}
                close={this.close}
                brands={this.props.links.brands}
                quickpicks={this.props.links.quickpicks}
                categories={this.props.links.categories}
              />
              <Parts
                opened={this.state.opened}
                open={this.open}
                close={this.close}
                guitarparts={this.props.links.guitarparts}
                diycentral={this.props.links.diycentral}
              />
              <GuitarAmps
                opened={this.state.opened}
                open={this.open}
                close={this.close}
                amps={this.props.links.amps}
              />
              <Pedals
                opened={this.state.opened}
                open={this.open}
                close={this.close}
                pedals={this.props.links.pedals}
                pedalkits={this.props.links.pedalkits}
              />
              <HiFiGear
                opened={this.state.opened}
                open={this.open}
                close={this.close}
                hifikits={this.props.links.hifikits}
                hifiamps={this.props.links.hifiamps}
                hifigear={this.props.links.hifigear}
              />
              <OtherStuff
                opened={this.state.opened}
                open={this.open}
                close={this.close}
                otherstuff={this.props.links.otherstuff}
              />
            </ul>
            <UtilityNav
              opened={this.state.opened}
              open={this.open}
              close={this.close}
              subscribed={this.props.subscribed}
              getShippingRates={this.props.getShippingRates}
              showDropdown={this.props.showDropdown}
              orderCompleted={this.props.orderCompleted}
              capturePayPal={this.props.capturePayPal}
              captureBraintree={this.props.captureBraintree}
              paymentInfo={this.props.paymentInfo}
              devise={this.props.devise}
              changeDeviseInfo={this.props.changeDeviseInfo}
              forgotPassword={this.props.forgotPassword}
              register={this.props.register}
              logIn={this.props.logIn}
              notify={this.props.notify}
              changeQuantity={this.props.changeQuantity}
              setShipping={this.props.setShipping}
              editAddress={this.props.editAddress}
              loadStates={this.props.loadStates}
              locations={this.props.locations}
              setAddress={this.props.setAddress}
              checkoutInfo={this.props.checkoutInfo}
              cartSuggestions={this.props.cartSuggestions}
              account={this.props.account}
              logOut={this.props.logOut}
              emptyCart={this.props.emptyCart}
              checkoutImage={this.props.checkoutImage}
              couponCode={this.props.couponCode}
              deleteItem={this.props.deleteItem}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
