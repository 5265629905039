import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";

const UnsubscribeTracking = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [orderNumber, setOrderNumber] = useState(undefined);

  useEffect(() => {
    fetch("/react/unsubscribe_tracking_updates", {
      method: "post",
      body: JSON.stringify({ shipment_id: props.match.params.shipment_id }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector("meta[name='csrf-token']")
          .getAttribute("content"),
      },
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then((data) => {
        setOrderNumber(data.order_number);
      })
      .catch((error) => {
        setRedirect(true);
      });
  }, []);

  if (redirect) {
    return <Redirect to={{ pathname: "/" }} />;
  } else if (orderNumber != undefined) {
    return (
      <div
        style={{
          maxWidth: "640px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "15px",
        }}
      >
        <div
          style={{
            color: "#505050",
            fontFamily: "Arial",
            fontSize: "14px",
            lineHeight: "150%",
            textAlign: "center",
          }}
        >
          No worries, we won't send you any more tracking updates about your
          shipment for {orderNumber}.
          <br />
          <br />
          <br />
          Let us know if we can help with anything else.
          <br />
          <br />
          <Link
            to={{
              pathname: "/",
            }}
          >
            <button className="btn btn-tubedepot">Tubedepot Home</button>
          </Link>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

export default UnsubscribeTracking;
