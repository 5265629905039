import React from "react";

class ImageModal extends React.Component {
  componentDidMount() {
    this.addZoom();
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    this.removeZoom();
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentImage.original !== prevProps.currentImage.original) {
      this.removeZoom();
      this.addZoom();
    }
  }

  addZoom = () => {
    $("#product-image-zoom")
      .data("zoomImage", this.props.currentImage.original)
      .elevateZoom({
        zoomType: "lens",
        lensShape: "round",
        lensSize: 300,
      });
  };

  removeZoom = () => {
    let zoomContainers = Array.from(
      document.querySelectorAll("div.zoomContainer"),
    );
    zoomContainers.forEach((zoom) => zoom.parentNode.removeChild(zoom));
  };

  handleKeyDown = (event) => {
    this.props.handleImageChange(event.key);
  };

  render() {
    return (
      <div>
        <div className="overlay" onClick={this.props.toggleModal}></div>
        {this.props.singleImage ? (
          <div className="image-modal">
            <i
              className="fa fa-times pull-right"
              aria-hidden="true"
              onClick={this.props.toggleModal}
            />
            <img
              id="product-image-zoom"
              itemProp="image"
              src={this.props.currentImage.large}
              alt={this.props.currentImage.alt}
              data-zoom-image={this.props.currentImage.original}
            />
          </div>
        ) : (
          <div>
            <i
              id="image-arrow-left"
              className="fa fa-arrow-circle-left"
              onClick={() => this.props.handleImageChange("ArrowLeft")}
            />
            <div className="image-modal">
              <i
                className="fa fa-times pull-right"
                aria-hidden="true"
                onClick={this.props.toggleModal}
              />
              <img
                id="product-image-zoom"
                itemProp="image"
                src={this.props.currentImage.large}
                alt={this.props.currentImage.alt}
                data-zoom-image={this.props.currentImage.original}
              />
            </div>
            <i
              id="image-arrow-right"
              className="fa fa-arrow-circle-right pull-right"
              onClick={() => this.props.handleImageChange("ArrowRight")}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ImageModal;
