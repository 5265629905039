import React from "react";
import { Link } from "react-router-dom";
class AddressDisplay extends React.Component {
  render() {
    return (
      <div className="checkout-field">
        <a
          onClick={this.props.editAddress}
          href="javascript:;"
          style={{ marginRight: "10px", float: "right" }}
        >
          Edit
        </a>
        <h5>{this.props.address.name}</h5>
        <h5>{this.props.address.address1}</h5>
        {this.props.address.address2 == "" ? null : (
          <h5>{this.props.address.address2}</h5>
        )}
        <h5>
          {this.props.address.city}, {this.props.address.state}{" "}
          {this.props.address.zipcode}
        </h5>
        {this.props.address.country == "United States" ? null : (
          <h5>{this.props.address.country}</h5>
        )}
        {this.props.international == true ? (
          <h5>Duties and taxes may apply</h5>
        ) : null}
      </div>
    );
  }
}

export default AddressDisplay;
