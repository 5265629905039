import React from "react";
import { Link } from "react-router-dom";
class History extends React.Component {
  render() {
    if (this.props.user == true) {
      return (
        <div className="historycontcont">
          <div className="historycont">
            <div className="constrict">
              <div className="historytcont">
                <i className="fa fa-history fa-2x"></i>
                <span className="historytext">
                  <b>Need to repeat a past order?</b>
                </span>
              </div>
              <Link to="/account">
                <button className="historybutton btn btn-tubedepot">
                  See Past Orders
                </button>
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      return <div className="historycontcont"></div>;
    }
  }
}

export default History;
