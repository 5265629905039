import React from "react";
import Index from "./Index";
import ReactResizeDetector from "react-resize-detector";

class Data extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        new_products: [],
        slider_image: "",
        see_more_link: "",
        current_spree_user: false,
        sale_prods: [],
        taxons: [],
        products: [],
        order: [],
        dd_date: "",
        one_product_sale: false,
        video_url: "",
      },
    };
  }

  componentDidMount() {
    const that = this;
    fetch("/react/homedata")
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState({ data: data });
        document.title = "Welcome to TubeDepot!";
        gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      })
      .catch(function (error) {
        that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  render() {
    return (
      <div>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.props.updateHeight}
        >
          <Index
            data={this.state.data}
            logged_in={this.props.logged_in}
            taxon_address={this.props.taxon_address}
            taxon_message={this.props.taxon_message}
          />
        </ReactResizeDetector>
      </div>
    );
  }
}

export default Data;
