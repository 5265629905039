import React from "react";
import { Link } from "react-router-dom";
import { Parallax, Background } from "react-parallax";
class TaxonBanner extends React.Component {
  mainMessage = () => {
    return this.props.taxonMessage.match(/[^%]+(?=(%|&))/g);
  };

  secondaryMessage = () => {
    return this.props.taxonMessage.match(/[^&]+$/g);
  };

  render() {
    return (
      <div className="hero">
        <div className="constrict">
          <Link to={this.props.taxonAddress}>
            <Parallax
              bgImage={this.props.slider_image}
              bgImageAlt={this.props.taxonMessage}
              strength={200}
            >
              <div className="mainimg parallax-window">
                <div className="mainimg parallax-window" data-parallax="scroll">
                  {this.props.slider_image == "" ? null : (
                    <div className="transback">
                      <div className="mainimgtext">
                        <h1>
                          {this.mainMessage().map((line, key) => (
                            <div key={key}>
                              <span>{line}</span>
                            </div>
                          ))}
                        </h1>
                        <h1 className="herobutton">
                          {this.secondaryMessage().map((line, key) => (
                            <div key={key}>
                              <span>{line}</span>
                            </div>
                          ))}
                        </h1>
                      </div>
                      <button className="mainimgbutton btn btn-tubedepot">
                        Learn More
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Parallax>
          </Link>
        </div>
      </div>
    );
  }
}

export default TaxonBanner;
