import React from "react";
import { Link } from "react-router-dom";

class AlsoBought extends React.Component {
  setProducts = () => {
    if (this.props.alsoBought.length === 0)
      return this.props.productSuggestions;
    return this.props.alsoBought;
  };

  render() {
    return (
      <div>
        <h3>Customers Who Viewed This Item Also Purchased...</h3>
        <div className="ab-list">
          {this.setProducts().map((product, index) => (
            <Link
              to={{
                pathname: product.path,
                state: { product_id: product.id },
              }}
              key={index}
            >
              <div className="abp">
                <div className="abpinner">
                  <div className="abpimage">
                    <img
                      itemProp="image"
                      src={product.image}
                      alt={product.image_alt}
                    />
                  </div>
                  <div className="abpname">{product.name}</div>
                  <div className="pricing">{product.display_price}</div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default AlsoBought;
