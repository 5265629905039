import React from "react";
class PurchaseOrderSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      searchTerm: "",
      foundPurchaseOrders: [],
    };
  }

  purchaseOrderSearch = () => {
    if (this.state.searchTerm.length < 3)
      return this.setState({ foundPurchaseOrders: [] });
    fetch(`/admin/purchase_orders/?search_term=${this.state.searchTerm}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          foundPurchaseOrders: data,
        });
      });
  };

  handleSearchFocus = (event) => {
    if (event.type === "focus") {
      document.addEventListener("input", this.handleInput);
      document
        .querySelector(
          "div#purchase-order-search-form table#purchase-order-results-table",
        )
        .classList.remove("hidden");
    } else if (event.type === "blur") {
      document.removeEventListener("input", this.handleInput);
      setTimeout(() => {
        document
          .querySelector(
            "div#purchase-order-search-form table#purchase-order-results-table",
          )
          .classList.add("hidden");
      }, 200);
    }
  };

  handleInput = () => {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.purchaseOrderSearch();
    }, 100);
  };

  render() {
    return (
      <div id="purchase-order-search-form" className="form-horizontal">
        <fieldset>
          <legend align="center">Search</legend>
          <input
            id="purchase-order-search-input"
            value={this.state.searchTerm}
            size="16"
            autoComplete="off"
            onChange={(e) => this.setState({ searchTerm: e.target.value })}
            onFocus={(e) => this.handleSearchFocus(e)}
            onBlur={(e) => this.handleSearchFocus(e)}
            style={{ paddingTop: "10px", height: "22px", fontSize: "16px" }}
          />
          <table className="constrict hidden" id="purchase-order-results-table">
            <thead>
              <tr>
                <td>Number</td>
                <td>State</td>
                <td>Vendor</td>
                <td>Edit</td>
              </tr>
            </thead>
            <tbody>
              {this.state.foundPurchaseOrders.map((purchaseOrder, index) => (
                <tr key={index}>
                  <td>{purchaseOrder.number}</td>
                  <td>{purchaseOrder.state}</td>
                  <td>
                    <a
                      href={
                        "/admin/purchase_orders/?vendor_id=" +
                        purchaseOrder.vendor_id
                      }
                    >
                      {purchaseOrder.vendor_name}
                    </a>
                  </td>
                  <td>
                    <a
                      href={
                        "/admin/purchase_orders/" + purchaseOrder.id + "/edit"
                      }
                    >
                      <button>Edit</button>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </fieldset>
      </div>
    );
  }
}

export default PurchaseOrderSearch;
