import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Category from "./Category";
import Item from "./Item";

class Routes extends React.Component {
  render() {
    return (
      <div>
        <Route
          exact
          path="/tubes_by_amp"
          render={(props) => (
            <Category
              {...props}
              notify={this.props.notify}
              search={this.props.search}
              updateHeight={this.props.updateHeight}
            />
          )}
        />
        <Route
          path="/tubes_by_amp/:slug"
          render={(props) => (
            <Item
              {...props}
              notify={this.props.notify}
              search={this.props.search}
              updateHeight={this.props.updateHeight}
              addToCart={this.props.addToCart}
            />
          )}
        />
      </div>
    );
  }
}

export default Routes;
