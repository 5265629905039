// CSS
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

// Base Class Import
import Quill from "quill/core";
// import Delta from 'quill-delta';

// Parchment and Blots
const Parchment = Quill.import("parchment");
const Delta = Quill.import("delta");
import Emitter from "quill/core/emitter";
const Inline = Quill.import("blots/inline");
const BlockEmbed = Quill.import("blots/block/embed");
import Tooltip from "quill/ui/tooltip";

// Modules
import Toolbar from "quill/modules/toolbar";

// Themes
import Snow from "quill/themes/snow";

// Basic Formats
import Bold from "quill/formats/bold";
import Italic from "quill/formats/italic";
import Underline from "quill/formats/underline";
import Strike from "quill/formats/strike";
import Blockquote from "quill/formats/blockquote";
import Header from "quill/formats/header";
import Video from "quill/formats/video";

// Link Format
class LinkBlot extends Inline {
  static blotName = "link";
  static tagName = "a";

  static create(value) {
    let node = super.create();
    node.setAttribute("href", value);
    return node;
  }

  static formats(node) {
    return node.getAttribute("href");
  }
}

// Image Format
class ImageBlot extends BlockEmbed {
  static blotName = "image";
  static tagName = "img";

  static create(value) {
    let node = super.create();
    node.setAttribute("alt", value.alt);
    node.setAttribute("src", value.url);
    node.setAttribute("data-id", value.id);
    node.setAttribute("data-viewable-id", value.viewableId);
    node.setAttribute("data-viewable-type", value.viewableType);
    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute("alt"),
      url: node.getAttribute("src"),
      id: node.getAttribute("data-id"),
      viewableType: node.getAttribute("data-viewable-type"),
      viewableId: node.getAttribute("data-viewable-id"),
    };
  }
}

Quill.register({
  "modules/toolbar": Toolbar,
  "themes/snow": Snow,
  "formats/bold": Bold,
  "formats/italic": Italic,
  "formats/underline": Underline,
  "formats/strike": Strike,
  "formats/blockquote": Blockquote,
  "formats/header": Header,
  "formats/link": LinkBlot,
  "formats/image": ImageBlot,
  "formats/video": Video,
  "ui/tooltip": Tooltip,
});

export { Quill as default, Delta, Emitter, Tooltip };
