import React from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import AddressForm from "./AddressForm";
class AddressForms extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="constrict">
        <AddressForm
          notify={this.props.notify}
          startLoading={this.props.startLoading}
          stopLoading={this.props.stopLoading}
          locations={this.props.locations}
          loadStates={this.props.loadStates}
          address={this.props.address}
          handleAddressChange={this.props.handleAddressChange}
          addressType={this.props.addressType}
        />
      </div>
    );
  }
}

export default AddressForms;
