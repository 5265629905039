import React from "react";
class VendorDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      orderProductIds: [],
      velocity: props.velocity,
      forecast: props.forecast,
    };
  }

  componentDidMount() {
    $(".tablesorter").tablesorter({ sortList: [[3, 0]] });
  }

  determineStatusColor = (daysOnHand) => {
    let statusColor;
    if (daysOnHand === null) {
      statusColor = "blue";
    } else if (daysOnHand < this.props.forecast * 0.25) {
      statusColor = "red";
    } else if (daysOnHand < this.props.forecast * 0.5) {
      statusColor = "orange";
    } else if (daysOnHand < this.props.forecast * 0.75) {
      statusColor = "yellow";
    } else {
      statusColor = "green";
    }
    return statusColor;
  };

  addToOrder = (productIndex, totalCost) => {
    let newTotal = this.state.total;
    let newProductIds = this.state.orderProductIds;

    if (event.target.checked === true) {
      newTotal += parseFloat(totalCost);
      newProductIds.push(productIndex);
    } else if (event.target.checked === false) {
      newTotal -= parseFloat(totalCost);
      newProductIds = newProductIds.filter((id) => id !== productIndex);
    }

    this.setState((prevState) => ({
      total: newTotal,
      orderProductIds: newProductIds,
    }));
  };

  updatePeriods = () => {
    window.location.href =
      "/admin/dashboard/" +
      this.props.vendor_id +
      "/show?velocity=" +
      this.state.velocity +
      ";forecast=" +
      this.state.forecast;
  };

  buildProductLines = () => {
    let product;
    let line;
    return this.state.orderProductIds.map((id) => {
      product = this.props.products[id];
      line = {};
      line["category_id"] = product.category_id || 10;
      line["po_description"] = [
        product.sku,
        product.manufacturer_id,
        product.name,
      ].join(" - ");
      line["quantity"] = product.quantity_needed;
      line["cost_price"] = product.cost_price;
      line["variant_id"] = product.variant_id;
      return line;
    });
  };

  buildMoneyAmount = (price, decimal) => {
    let priceString;
    if (price === null) {
      priceString = "";
    } else {
      priceString =
        "$" +
        price.toLocaleString(undefined, { maximumFractionDigits: decimal });
    }
    return priceString;
  };

  handleOnOrderNotification = () => {
    let onOrder = this.props.products
      .filter(
        (product, index) =>
          this.state.orderProductIds.includes(index) && product.on_order > 0,
      )
      .map((product) => product.sku);
    let message =
      onOrder.join(",\n") +
      ",\nThese products are currently on order. Are you sure you want these on this purchase order?";
    if (onOrder.length > 0) return confirm(message);
    return true;
  };

  handleSubmit = () => {
    if (!this.handleOnOrderNotification()) return;
    fetch("/admin/purchase_orders", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector("meta[name='csrf-token']")
          .getAttribute("content"),
      },
      body: JSON.stringify({
        purchase_order: {
          vendor_id: this.props.vendor_id,
          po_line_items_attributes: this.buildProductLines(),
        },
      }),
    })
      .then((response) => {
        if (response.ok) {
          window.location.href = "/admin/dashboard";
        } else {
          throw "Purchase order could not be created at this time.";
        }
      })
      .catch((error) => {
        console.log(error);
        window.alert("Something when wrong. Please try again later.");
      });
  };

  render() {
    return (
      <div>
        <h2 className="pull-left">
          Total: {this.buildMoneyAmount(this.state.total, 2)}
        </h2>
        <br />
        <br />
        <h4>
          Velocity Period: &nbsp;
          <select
            defaultValue={this.props.velocity}
            onChange={(e) =>
              this.setState({ velocity: parseInt(e.target.value) })
            }
          >
            {this.props.period_options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          &nbsp; Forecast Period: &nbsp;
          <select
            defaultValue={this.props.forecast}
            onChange={(e) =>
              this.setState({ forecast: parseInt(e.target.value) })
            }
          >
            {this.props.period_options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          &nbsp;
          <button className="btn btn-primary" onClick={this.updatePeriods}>
            Update
          </button>
          <button
            className="btn btn-primary pull-right"
            onClick={this.handleSubmit}
          >
            Create
          </button>
        </h4>
        <table className="tablesorter">
          <thead>
            <tr>
              <th>Status</th>
              <th>Count on Hand</th>
              <th>SKU</th>
              <th>Days on Hand</th>
              <th>Velocity in Days</th>
              <th>On Order</th>
              <th>Minimum</th>
              <th>Quantity Needed</th>
              <th>Cost Price</th>
              <th>Total</th>
              <th>Order</th>
            </tr>
          </thead>
          <tbody>
            {this.props.products.map((product, index) => (
              <tr key={index}>
                <td
                  style={{
                    backgroundColor: this.determineStatusColor(
                      product.days_on_hand,
                    ),
                  }}
                ></td>
                <td>{product.count_on_hand}</td>
                <td>
                  <a href={"/admin/purchasing_variants/" + product.slug}>
                    {product.sku}
                  </a>
                </td>
                <td>{product.days_on_hand}</td>
                <td>{product.velocity_in_days}</td>
                <td>{product.on_order}</td>
                <td>{product.minimum}</td>
                <td>{product.quantity_needed}</td>
                <td>{this.buildMoneyAmount(product.cost_price, 4)}</td>
                <td>{this.buildMoneyAmount(product.total_cost, 2)}</td>
                <td>
                  <input
                    type="checkbox"
                    disabled={product.quantity_needed === null}
                    readOnly=""
                    tabIndex="0"
                    onClick={() => this.addToOrder(index, product.total_cost)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default VendorDashboard;
