import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class Description extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  setQuantityText = () => {
    let quantityText = "Price is for ";

    if (this.props.quantity == 1) {
      quantityText = quantityText + "a single";
    } else {
      quantityText = quantityText + this.props.quantity;
    }

    return quantityText;
  };

  setTestingText = () => {
    let testKeys = Object.keys(this.props.selectedTests);
    let selectedTypes = this.props.testTypes.filter(
      (type) =>
        type != this.props.matchingTest &&
        testKeys.includes(type.id.toString()),
    );

    if (testKeys.length === 0) return " with standard testing";

    let testingText = "";

    if (selectedTypes.length === 1) {
      testingText = " with " + selectedTypes[0].name;
    } else if (selectedTypes.length === 2) {
      testingText =
        " with " + selectedTypes[0].name + " and " + selectedTypes[1].name;
    } else if (selectedTypes.length === 3) {
      testingText =
        " with " +
        selectedTypes[0].name +
        ", " +
        selectedTypes[1].name +
        ", and " +
        selectedTypes[2].name;
    }

    return testingText;
  };

  setMatchingText = () => {
    if (this.props.quantity == 1) return "tube";

    let matchingText;

    if (
      Object.keys(this.props.selectedTests).includes(
        this.props.matchingTest.id.toString(),
      )
    ) {
      matchingText = "matched";
    } else {
      matchingText = "<font color='#aa3333'>unmatched</font>";
    }

    return matchingText + " tubes";
  };

  setDescriptionText = () => {
    if (this.props.testTypes.length === 0) return;
    return ReactHtmlParser(
      this.setQuantityText() +
        " " +
        this.setMatchingText() +
        this.setTestingText() +
        ".",
    );
  };

  render() {
    return (
      <div id="price-description">
        <div id="price-text-container">
          <div id="price-text">{this.setDescriptionText()}</div>
        </div>
      </div>
    );
  }
}

export default Description;
