import React from "react";
import { Link } from "react-router-dom";
class Taxons extends React.Component {
  render() {
    return (
      <span>
        <div className="title constrict">
          <h1>Popular Categories</h1>
        </div>
        <div className="taxons constrict">
          <div className="taxoncont">
            {this.props.taxons.map((itemMap, key) => (
              <Link
                key={key}
                to={{ pathname: itemMap.permalink, query: { id: itemMap.id } }}
              >
                <div className="tcircle">
                  <img className="taximg" src={itemMap.image} />
                </div>
                <br />
                {itemMap.name}
              </Link>
            ))}
          </div>
        </div>
      </span>
    );
  }
}

export default Taxons;
