import React from "react";
import { Link } from "react-router-dom";
import Products from "../Shared/Products";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class Index extends React.Component {
  isEmpty(obj) {
    for (var prop in obj) {
      return false;
    }
    return true;
  }

  filterColor = (key, filter) => {
    let filters = this.props.applicableFilters[key] || [];
    let checked = this.props.checked[key] || [];

    return filters.includes(filter) || checked.includes(filter)
      ? "black"
      : "grey";
  };

  setDescription = () => {
    let description = this.props.data.description;
    return ReactHtmlParser(description);
  };

  render() {
    return (
      <div className="react-container">
        <div id="sidebar" className="span3">
          <div className="push-right">
            {this.isEmpty(this.props.checked) ? null : (
              <button
                className="btn btn-tubedepot filter-removal"
                onClick={this.props.removeFilters}
              >
                Remove All Filters
              </button>
            )}
            {Object.keys(this.props.data.filters).map((key) => (
              <div className="navigation" data-hook="navigation" key={key}>
                <div className="title-bar">
                  <h4 className="filter-title">{key}</h4>
                </div>
                <div className="module-cnt border filter_box">
                  <ul className="filter_choices">
                    {this.props.data.filters[key].map((itemMap, key2) => (
                      <li className="nowrap module-li" key={key2}>
                        <input
                          checked={
                            this.props.checked[key]
                              ? this.props.checked[key].includes(itemMap)
                              : false
                          }
                          className={key}
                          id={itemMap}
                          type="checkbox"
                          autoComplete="off"
                          value={itemMap}
                          onChange={this.props.check}
                        />
                        <label
                          htmlFor={itemMap}
                          className="nowrap filter_label"
                          style={{ color: this.filterColor(key, itemMap) }}
                        >
                          {" "}
                          {itemMap}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="taxon-cnt constrict">
          <div className="title">
            <h1>{this.props.data.name}</h1>
          </div>
          <div id="#taxon-description" className="vertical-align-top">
            {this.setDescription()}
          </div>
          {this.props.displayProducts.length === 0 &&
          Object.keys(this.props.checked).length > 0 ? (
            <div>
              <br />
              <br />
              <h3>
                No products match the current filters--please be less specific.
              </h3>
              <hr />
            </div>
          ) : (
            ""
          )}
          <div id="parent-taxon" data-hook="taxon_products">
            <Products
              products={
                this.props.displayProducts.length === 0
                  ? this.props.data.products
                  : this.props.displayProducts
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
