import React from "react";
import { Link } from "react-router-dom";
class InstagramWidget extends React.Component {
  constructor() {
    super();
    this.state = {
      edges: {},
      profile_picture: "",
    };
  }

  componentDidMount() {
    const that = this;
    fetch("https://instagram.com/tubedepot/?__a=1")
      .then((results) => {
        return results.json();
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState({
          edges:
            data["graphql"]["user"]["edge_owner_to_timeline_media"]["edges"],
          profile_picture: data["graphql"]["user"]["profile_pic_url_hd"],
        });
      });
  }

  render() {
    if (this.state.edges.length > 0) {
      return (
        <span>
          <div className="title constrict">
            <h1>TubeDepot on Instagram</h1>
          </div>
          <div className="instagram-widget constrict">
            {this.state.edges.map((data, index) => (
              <span key={index}>
                {index < 8 ? (
                  <div className="instagram-object">
                    <a
                      target="_blank"
                      href={
                        "https://instagram.com/p/" + data["node"]["shortcode"]
                      }
                    >
                      <div className="instagram-box">
                        <img src={data["node"]["thumbnail_src"]} />
                      </div>
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </span>
            ))}
          </div>
        </span>
      );
    } else {
      return <span />;
    }
  }
}

export default InstagramWidget;
