import React from "react";

class PrivacyandSecurity extends React.Component {
  componentDidMount() {
    gtag("event", "page_view", {
      page_path: window.location.pathname,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="content-margin bare-table constrict">
          <h2 className="title">TubeDepot.com Privacy and Security Policy</h2>
          <p>
            This privacy policy ("Privacy Policy") describes how we handle the
            personal information that you provide to us on this web site and
            related properties of TubeDepot.com (the "Web Site"). This Privacy
            Policy applies only to the Web Site of TubeDepot, LLC (the
            "Company"), not to any third party sites that may be accessible from
            here.
          </p>
          <p>
            After reading this Privacy Policy, which is incorporated into and is
            a part of this Web Site's Terms of Use, you will know:
          </p>
          <p>
            What information the Company collects from you;
            <br />
            How the Company uses your information;
            <br />
            What choices you have about how the Company treats your information;
            <br />
            How the Company protects your privacy;
            <br />
            About third party links you access through the Web Site;
            <br />
            How you can contact the Company.
            <br />
          </p>
          <p>
            Please be sure to read this Privacy Policy before using the Web Site
            or submitting information to us. Our Privacy Policy may change from
            time to time and your continued use of the Web Site is acceptance of
            such changes, so please check this area periodically for updates to
            this Privacy Policy.
          </p>
          <p>
            <strong>What Information the Company Collects From You</strong>
          </p>
          <p>
            You can visit the Web Site to read product and company information
            without telling us who you are and without revealing any personally
            identifiable information. There are instances, however, where we
            request personally identifiable information to provide you a
            service, such as processing purchases, or to respond to a request
            for information. Described below are the ways in which we actively
            and passively collect information during a Web Site visit.
          </p>
          <p>Active Information Collection</p>
          <p>
            Like many other websites, we actively collect information from our
            visitors both by asking specific questions on our Web Site and by
            permitting you to communicate directly with us via online forms and
            e-mail. Some of the information that you submit may be personally
            identifiable (that is, information that can be uniquely identified
            with you, such as your name, business name, address, phone number,
            e-mail address, etc.).
          </p>
          <p>
            You now have the choice to store your credit card information for
            future use. We do not keep your actual credit card number on file
            and are granted the ability to charge your card from our payment
            processor - PayPal - with your express permission. The intent is to
            speed up future orders and credits. This is only an option and is
            not required to do business with TubeDepot. No payment information
            is ever stored on our servers.
          </p>
          <p>Passive Information Collection</p>
          <p>
            As you navigate through a website, certain information may be
            passively collected (that is, gathered without you actively
            providing the information) using various technologies and means,
            such as navigational data collection. Like most website operators,
            we track IP addresses, assign internet tags, and use session,
            persistent, and flash cookies, and any other successor tracker
            technology.
          </p>
          <p>
            Our system also automatically gathers information about the areas
            you visit on this Web Site and collects operational information
            about the technology you use, such as the web site that referred you
            to us and other information.
          </p>
          <p>
            We reserve the right to use electronic images known as web beacons,
            also referred to as single-pixel gifs, and any other successor
            technology, that permit us to count users who have visited certain
            Web Site(s) pages and for other related statistics (e.g., recording
            the popularity of certain Web Site(s) content and verifying system
            and server integrity).
          </p>
          <p>
            <strong>How the Company Uses Your Information</strong>
          </p>
          <p>
            The Company uses your personal information for a variety of
            purposes, including to enhance the operation of the Web Site, allow
            you to use all of its features, better understand what features and
            services may be of interest to you, and provide you with the
            products and services that you request.
          </p>
          <p>
            We may send you information about our products and services and may
            contact you about new features or products in which we believe you
            may be interested. We also may use your contact information for
            marketing and other purposes. In addition, we may provide aggregate
            statistics about our customers, sales, online traffic patterns and
            related information to third parties, but these statistics will not
            include any personally identifying information. It is our policy
            that we will not share, barter, sell, or trade your confidential
            information. If required by law or an appropriate government
            authority, we will disclose any information that it is required.
          </p>
          <p>
            <strong>
              What Choices You Have About How the Company Treats Your
              Information
            </strong>
          </p>
          <p>
            We strive to provide you with choices regarding the personal
            information you provide to us.
          </p>
          <p>
            You may send us an e-mail message at info@tubedepot.com to request
            access to, correct or delete any personal information that you have
            provided to us. To protect your privacy, we will only respond to
            e-mail messages sent from the e-mail account you specified when you
            gave us your contact information. Should you elect to have your
            information deleted, we will also delete your account.
          </p>
          <p>
            You also may opt out of having your e-mail address used for
            promotional purposes by us. If you no longer wish to receive
            information or promotional materials from us by e-mail you may opt
            out of receiving these communications by clicking the unsubscribe
            link in the email or newsletter.
          </p>
          <p>
            If you do not wish us to collect cookies, you may set your browser
            to refuse cookies, or to alert you when cookies are being sent. If
            you do so, please note that some parts of our Web Site may then be
            inaccessible or not function properly.
          </p>
          <p>
            <strong>How the Company Protects Your Privacy</strong>
          </p>
          <p>
            When you submit a new password to us, we offer the use of a secure
            server. Your browser will encrypt all sensitive information you
            input before it is sent to us. Furthermore, every reasonable effort
            is made to protect customer data from unauthorized access. We use
            both technical and procedural methods to maintain the integrity and
            security of our databases, including but not limited to SSL
            encryption for data transfer, firewalls, restricted access to data,
            employee training, and other methods. The safety and security of
            your information also depends on you. Never share your password with
            anyone else, notify us promptly if you believe your password
            security has been breached, and remember to log off of this Web Site
            before you leave your computer.
          </p>
          <p>
            <strong>
              About Third Party Links You Access Through the Web Site
            </strong>
          </p>
          <p>
            The Web Site contains links to other web sites operated by third
            parties that may be of interest to you. We cannot control these
            third party sites, which may collect personal information from you.
            When you follow a link and leave our Web Site, you do so at your own
            risk.
          </p>
          <p>
            We do not have control over the privacy policies applied by any
            other party to whom you may disclose information. We are not
            responsible for their content or privacy policies. If you would like
            information on another party's privacy policy, you should contact
            that party directly, and we encourage you to do so.
          </p>
          <p>
            <strong>How You Can Contact the Company</strong>
          </p>
          <p>
            If you have any questions, comments, or concerns about this Privacy
            Policy or the information practices of the Web Site, please contact
            us at: info@tubedepot.com
          </p>
          <p>This Privacy Policy was last updated on June 11, 2019.</p>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default PrivacyandSecurity;
