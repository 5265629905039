import React from "react";
import ReactResizeDetector from "react-resize-detector";
import Products from "../Shared/Products";

class NewProducts extends React.Component {
  constructor() {
    super();
    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    fetch("/react/new_products")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          products: data,
        });
        document.title = "New Products | TubeDepot.com";
        gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      })
      .catch((error) => {
        that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  render() {
    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={this.props.updateHeight}
      >
        <div className="constrict">
          <div className="title constrict">
            <h1>New Products</h1>
          </div>
          <Products products={this.state.products} />
        </div>
      </ReactResizeDetector>
    );
  }
}

export default NewProducts;
