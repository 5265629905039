import React from "react";
import { Route, Switch } from "react-router-dom";
import Contact from "./Contact";
import WarrantyandReturn from "./WarrantyandReturn";
import ShippingFAQ from "./ShippingFAQ";
import PrivacyandSecurity from "./PrivacyandSecurity";
import OrderingFAQ from "./OrderingFAQ";
import BlackSableFAQ from "./BlackSableFAQ";
import UnsubscribeTracking from "./UnsubscribeTracking";
import StaticContent from "./StaticContent";
import ReactResizeDetector from "react-resize-detector";

class Routes extends React.Component {
  constructor() {
    super();
    this.log = this.log.bind(this);
  }

  componentDidMount() {
    document.title = "Welcome to TubeDepot!";
    this.log("is viewing static page " + this.props.location.pathname);
  }

  componentWillReceiveProps(nextProps) {
    if (!(this.props.location.pathname == nextProps.location.pathname)) {
      this.log("is viewing static page" + nextProps.location.pathname);
    }
  }

  log(message) {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/log_static", {
      method: "post",
      body: JSON.stringify({ message: message }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    });
  }

  render() {
    return (
      <div>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.props.updateHeight}
        >
          <Switch>
            <Route
              path={`${this.props.match.path}/contact-us`}
              component={Contact}
            />
            <Route
              path={`${this.props.match.path}/warranty-and-returns`}
              component={WarrantyandReturn}
            />
            <Route
              path={`${this.props.match.path}/shipping-faq`}
              component={ShippingFAQ}
            />
            <Route
              path={`${this.props.match.path}/privacy-and-security`}
              component={PrivacyandSecurity}
            />
            <Route
              path={`${this.props.match.path}/how-to-order`}
              component={OrderingFAQ}
            />
            <Route
              path={`${this.props.match.path}/black-sable-faq`}
              component={BlackSableFAQ}
            />
            <Route
              path={`${this.props.match.path}/unsubscribe_tracking/:shipment_id`}
              component={UnsubscribeTracking}
            />
            <Route
              path={`${this.props.match.path}/:slug`}
              render={(props) => (
                <StaticContent notify={this.props.notify} {...props} />
              )}
            />
          </Switch>
        </ReactResizeDetector>
      </div>
    );
  }
}

export default Routes;
