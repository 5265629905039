import { Delta, Emitter } from "../quill_setup";

function buildImageInput() {
  const fileInput = document.createElement("input");

  fileInput.setAttribute("type", "file");
  fileInput.setAttribute("accept", "image/*");
  fileInput.setAttribute("name", "image[attachment]");
  fileInput.setAttribute("id", "image_attachment");

  return fileInput;
}

function buildImageForm(viewableId, file, alt) {
  let formData = new FormData();

  formData.append("discourse_image[attachment]", file);
  formData.append("discourse_image[viewable_id]", viewableId);
  formData.append("discourse_image[alt]", alt);

  return formData;
}

function insertImage(image, quill) {
  const range = quill.getSelection(true);
  let imageObject = {
    url: image.url,
    alt: image.alt,
    id: image.id,
    viewableId: image.viewable_id,
    viewableType: image.viewable_type,
  };

  quill.updateContents(
    new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert({ image: imageObject }),
    Emitter.sources.USER,
  );
}

function imageHandler() {
  const imageInput = buildImageInput(),
    quill = this.quill,
    { tooltip } = quill.theme,
    viewableId = quill.container.dataset.viewableId,
    viewableClass = document.querySelector("meta[name='scanner']").attributes
      .controller.value;

  imageInput.click();

  imageInput.addEventListener("change", () => {
    let alt = prompt("Please enter alt message for the image");
    if (
      imageInput.files != null &&
      imageInput.files[0] != null &&
      alt != null &&
      alt.length > 0
    ) {
      Spree.ajax({
        url: ["/api", viewableClass, viewableId, "discourse_images"].join("/"),
        type: "POST",
        dataType: "json",
        data: buildImageForm(viewableId, imageInput.files[0]),
        processData: false,
        contentType: false,
      })
        .done(function (response) {
          insertImage(response, quill);
        })
        .error(function (jqXHR, textStatus, errorThrown) {
          console.log("it broke");
        });
    }
  });
}

export default imageHandler;
