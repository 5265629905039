import React from "react";
import { Link } from "react-router-dom";
import CartItemAdjustments from "./CartItemAdjustments";
class CartItems extends React.Component {
  constructor(props) {
    super();
    this.changeQuantity = this.changeQuantity.bind(this);
    this.changeTimeout = this.changeTimeout.bind(this);
    this.state = {
      id: null,
      e: null,
    };
  }

  componentWillMount() {
    this.time = null;
  }

  changeTimeout(id, e) {
    clearTimeout(this.timer);
    var quantity = Math.max(e.target.value, 1);
    this.setState({ id: id, e: quantity });
    this.timer = setTimeout(this.changeQuantity, 500);
  }

  changeQuantity() {
    $(".line_item_quantity").blur();
    this.props.changeQuantity(this.state.id, this.state.e).then((result) => {
      if (result === false) {
        let input = document.querySelector(
          "tr[data-item-id='" + this.state.id + "'] input.line_item_quantity",
        );
        input.value = this.props.items.find(
          (item) => item.id === this.state.id,
        ).quantity;
      }
    });
  }

  itemAdjustments = (adjustments) => {
    if (Object.keys(adjustments).includes("bulk_label")) return adjustments;
    let otherAdjustments = adjustments.filter(
      (adjustment) => !adjustment.label.includes("Bulk"),
    );
    let bulkAdjustment = adjustments.find((adjustment) =>
      adjustment.label.includes("Bulk"),
    ) || { bulk_label: "", bulk_amount: "" };
    return {
      bulk_label: bulkAdjustment.label,
      bulk_amount: bulkAdjustment.amount,
      other_adjustments: otherAdjustments,
    };
  };

  addSelectedTests = (item) => {
    if (item.tests.length === 0) return [];
    return item.tests.map((test) => {
      test["selected"] = item.selectedTests.includes(test.id.toString());
      return test;
    });
  };

  render() {
    return (
      <tbody id="line_items" data-hook>
        {this.props.items.map((itemMap, key) => (
          <tr className="line-item" data-item-id={itemMap.id} key={key}>
            {this.props.isMobile ? null : (
              <td className="cart-item-image" data-hook="cart_item_image">
                <Link to={itemMap.slug} onClick={this.props.closeModal}>
                  <img
                    src={itemMap.image}
                    alt={itemMap.alt}
                    className="li-img"
                  />
                </Link>
              </td>
            )}
            <td
              className="cart-item-description"
              data-hook="cart_item_description"
            >
              <h4>
                <Link to={itemMap.slug} onClick={this.props.closeModal}>
                  {itemMap.name}
                </Link>
              </h4>
              <h5>
                {itemMap.option} {itemMap.status}
              </h5>
              {itemMap.fulfillable ? null : (
                <span className="out-of-stock">
                  Out of Stock&nbsp;&nbsp;
                  <br />
                </span>
              )}
              <CartItemAdjustments
                li_id={itemMap.id}
                changeTesting={this.props.changeTesting}
                adjustments={this.itemAdjustments(itemMap.adjustments)}
                tests={this.addSelectedTests(itemMap)}
              />
            </td>
            <td className="cart-item-price" data-hook="cart_item_price">
              {itemMap.price}
            </td>
            <td
              className="cart-item-quantity width-auto"
              data-hook="cart_item_quantity"
              key={itemMap.quantity}
            >
              <input
                min="0"
                onChange={(e) => this.changeTimeout(itemMap.id, e)}
                className="line_item_quantity"
                type="number"
                defaultValue={itemMap.quantity}
                style={{ width: "50px" }}
              />
            </td>
            <td className="cart-item-total" data-hook="cart_item_total">
              {itemMap.total}
            </td>
            <td className="cart-item-delete" data-hook="cart_item_delete">
              <i
                className="fa fa-times"
                onClick={() => this.props.deleteItem(itemMap.id)}
                style={{ color: "red", cursor: "pointer" }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
}

export default CartItems;
