import React from "react";
import { Link } from "react-router-dom";

class Contact extends React.Component {
  componentDidMount() {
    gtag("event", "page_view", {
      page_path: window.location.pathname,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="content-margin bare-table constrict">
          <h2 className="title">Contact TubeDepot.com</h2>
          <br />
          TubeDepot.com
          <br />
          1958 Vanderhorn Drive
          <br />
          Memphis, TN 38134
          <br />
          <br />
          TOLL FREE Phone: 877-289-7994
          <br />
          Phone: 901-507-2726
          <br />
          Fax: 901-388-2405
          <br />
          <br />
          <strong>EMAIL:</strong>
          <br />
          <br />
          FOR INFORMATION ( &nbsp;
          <a href="mailto:info@tubedepot.com">
            <font style={{ fontSize: "medium" }}>info@tubedepot.com</font>
          </a>
          &nbsp; )
          <br />
          TO PURCHASE ( &nbsp;
          <a href="mailto:sales@tubedepot.com">
            <font style={{ fontSize: "medium" }}>sales@tubedepot.com</font>
          </a>
          &nbsp; )
          <br />
          <br />
          <strong>TO ORDER:</strong>
          <br />
          <br />
          For a more in-depth look at ordering, read our Ordering FAQ, &nbsp;
          <a className="homelink" href="/pages/how-to-order">
            <strong>Click Here.</strong>
          </a>
          <br />
          <br />
          <ul>
            <li>
              On-line ordering is easy! To place an order online, just click on
              the "Add to Cart" button found at every item. Online ordering and
              security is provided by Amazon Web Services (AWS) - One of the
              most trusted names on the internet. For more information about our
              security protocols, &nbsp;
              <Link
                to={{
                  pathname: "/pages/privacy-and-security",
                }}
                className="homelink"
              >
                <strong>Click Here.</strong>
              </Link>
            </li>
            <br />
            <li>
              If you want to place a call in order, just Call 901-507-2726 and
              we will be happy to take care of you.
            </li>
            <br />
            <li>
              If fax / email is your preferred method for ordering, please make
              sure to include the following information:
            </li>
            <ul>
              <li>Full Name</li>
              <li>Billing Address</li>
              <li>Shipping Address</li>
              <li>Phone / Fax Number</li>
              <li>Credit Card Number</li>
              <li>Credit Card Expiration Date</li>
            </ul>
            We will call or email you to confirm the order.
          </ul>
          <strong>METHODS FOR SHIPPING:</strong>
          <br />
          <ul>
            All Orders (Domestic and International) will ship to you via FedEx
            or USPS service. At checkout, you will be offered a multitude of
            shipping options. The cost of these shipping services will depend on
            the weight and destination of your order. You can select the best
            option based on your time requirements and cost preference.
          </ul>
          <strong>ACCEPTED FORMS OF PAYMENT:</strong>
          <br />
          <ul>
            <li>Credit Card (Visa, MasterCard, Discover, Amex)</li>
            <li>Personal Check</li>
            <li>Cashier's check</li>
            <li>Money Order</li>
            <li>Wire Transfer</li>
            <li>PayPal - Our address is paypal_merchant@tubedepot.com</li>
          </ul>
          <strong>OUR GUARANTEE AND RETURN POLICY:</strong>
          <ul>
            <li>
              All of our tubes are guaranteed to perform as described. We fully
              test each and every tube to ensure this reliability. The warranty
              for NOS (New) tubes is 90 days from date of purchase. Used tubes
              have a warranty of 45 days from date of purchase. If your tube
              fails and is within these warranty periods, we will replace,
              refund or credit your account, depending on current stock. More
              details can be found on the &nbsp;
              <Link
                to={{
                  pathname: "/pages/warranty-and-returns",
                }}
                className="homelink"
              >
                <strong>Warranty and Returns</strong>
              </Link>
              &nbsp; page.
            </li>
            <br />
            <li>
              TubeDepot.com will in no way be held responsible (financially or
              otherwise) for equipment that is damaged from the use of tubes or
              parts purchased from us. It is the responsibility of the customer
              to consult with the equipment manufacturer with regards to proper
              tube and parts replacement and recommended / proper biasing
              procedures.
            </li>
            <br />
            <li>
              If you have any questions about anything, do not hesitate to ask.
              We will get back to you immediately.
            </li>
          </ul>
          <strong>Thank you for choosing TubeDepot.com.</strong>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default Contact;
