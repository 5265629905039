import React from "react";
import { Link } from "react-router-dom";
class Amp extends React.Component {
  render() {
    return (
      <div className="constrict">
        {this.props.data.multiple == true ? (
          <div className="buttoncont">
            {this.props.data.standard == true ? (
              <button className="tsbutton btn" id="stsbutton" disabled>
                Standard Tube Set
              </button>
            ) : null}
            {this.props.data.high == true ? (
              <button className="tsbutton btn" id="hqtsbutton">
                High Quality Tube Set
              </button>
            ) : null}
            {this.props.data.best == true ? (
              <button className="tsbutton btn" id="bqtsbutton">
                Best Quality Tube Set
              </button>
            ) : null}
          </div>
        ) : null}
        <div className="toplev">
          <legend align="center">{this.props.data.type}</legend>
          <div className="ampstyle">
            <ul id="products" className="inline product-listing non-filter">
              {this.props.display.map((itemPart, key) => (
                <li
                  key={key}
                  id={itemPart.id}
                  className="span3"
                  data-hook="products_list_item"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                  <Link className="info" to={itemPart.path}>
                    <div className="taxon-product">
                      <div className="taxon-product-image">
                        <object>
                          <a>
                            <img itemProp="image" src={itemPart.image} />
                          </a>
                        </object>
                        <br />
                        <b>
                          <div className="quantityamp">
                            X<span className="quan">{itemPart.quantity}</span>
                            {/*<% unless part.variant.tba_collection.nil? %>
                          <select style="width:150px" className="tselect sel-<%= part.variant.id %>"><option disabled selected>Similar Tubes</option>
                            <% part.variant.tba_collection.variants.order(:sku).each do |variant| %>
                              <option value="<%= variant.id %>"><%= variant.name %></option>
                            <% end %>
                          </select>
                        <% end %> */}
                          </div>
                        </b>
                      </div>
                      <div className="taxon-product-info">
                        <object>
                          <a>{itemPart.name}</a>
                        </object>
                        <br />
                        <b>
                          <div className="stock-notifier">
                            {itemPart.in_stock == false ? "Out of Stock" : null}
                          </div>
                        </b>
                        {itemPart.note == "" ? null : (
                          <div className="partnote">{itemPart.note}</div>
                        )}
                        <div className="price-container">
                          <div className="price selling" itemProp="price">
                            {itemPart.price}
                          </div>
                          <div className="sku" itemProp="sku">
                            {itemPart.sku}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="price-cnt border pull-right ampprice">
            {/*  <%= render :partial => "cart_form", :locals => {:ids => ids, :price => price} %> */}
            <br />
            <br />
            <br />
          </div>
        </div>

        <br />
        <br />
        <br />
        <div className="pull-left">
          <i>
            All registered trademarks and copyrights are the property of their
            respective owners.
          </i>
        </div>
      </div>
    );
  }
}

export default Amp;
