import React from "react";
import ShippingOptions from "./ShippingOptions";
class PrintLabels extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
    this.getRates = this.getRates.bind(this);
    this.changeChecked = this.changeChecked.bind(this);
    this.nixRates = this.nixRates.bind(this);
    this.state = {
      address: {
        country_id: "49",
        return_label: true,
      },
      rates: [],
    };
  }

  nixRates() {
    this.setState({ rates: [] });
  }

  changeValue(e) {
    const {
      target: { name, value },
    } = e;
    this.setState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        [name]: value,
      },
    }));
  }

  changeChecked(e) {
    var checked = e.target.checked;
    this.setState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        return_label: checked,
      },
    }));
  }

  getRates(e) {
    e.preventDefault();
    fetch("/api/return_postage/get_rates", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "token " + this.props.token,
      },
      body: JSON.stringify({ label_info: this.state.address }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        this.setState({ rates: data });
      });
  }

  render() {
    return (
      <div className="row no-margin">
        <form
          style={{ width: "100%" }}
          data-remote="true"
          onSubmit={this.getRates}
        >
          <input name="utf8" type="hidden" value="✓" />
          <div style={{ display: "flex" }}>
            <div
              id="shipping"
              data-hook="address_fields"
              style={{ width: "40%", display: "inline-block" }}
            >
              <h4>Shipping Address</h4>
              <div className="field shipping-row">
                <label htmlFor="label_info_name">Name</label>
                <input
                  className="fullwidth"
                  type="text"
                  name="name"
                  id="label_info_name"
                  value={this.state.address.name}
                  onChange={this.changeValue}
                />
              </div>

              <div className="field shipping-row">
                <label htmlFor="label_info_address1">Address1</label>
                <input
                  className="fullwidth"
                  type="text"
                  name="address1"
                  id="label_info_address1"
                  value={this.state.address.address1}
                  onChange={this.changeValue}
                />
              </div>

              <div className="field shipping-row">
                <label htmlFor="label_info_address2">Address2</label>
                <input
                  className="fullwidth"
                  type="text"
                  name="address2"
                  id="label_info_address2"
                  value={this.state.address.address2}
                  onChange={this.changeValue}
                />
              </div>

              <div className="field shipping-row">
                <label htmlFor="label_info_city">City</label>
                <input
                  className="fullwidth"
                  type="text"
                  name="city"
                  id="label_info_city"
                  value={this.state.address.city}
                  onChange={this.changeValue}
                />
              </div>

              <div className="field shipping-row">
                <label htmlFor="label_info_zipcode">Zipcode</label>
                <input
                  className="fullwidth"
                  type="text"
                  name="zipcode"
                  id="label_info_zipcode"
                  value={this.state.address.zipcode}
                  onChange={this.changeValue}
                />
              </div>

              <div className="field shipping-row">
                <label htmlFor="label_info_country_id">Country</label>
                <span id="shipping_country">
                  <select
                    className="custom-select fullwidth js-country_id"
                    name="country_id"
                    id="label_info_country_id"
                    value={this.state.address.country_id}
                    onChange={this.changeValue}
                  >
                    {this.props.countries.map((country, index) => (
                      <option value={country.id} key={index}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </span>
              </div>

              <div className="field shipping-row">
                <label htmlFor="label_info_state_id">State</label>
                <input
                  className="fullwidth state_name js-state_name"
                  type="text"
                  name="state_name"
                  id="label_info_state_name"
                  value={this.state.address.state_name}
                  onChange={this.changeValue}
                />
              </div>

              <div className="field shipping-row">
                <label htmlFor="label_info_phone">Phone</label>
                <input
                  className="fullwidth"
                  type="tel"
                  name="phone"
                  id="label_info_phone"
                  value={this.state.address.phone}
                  onChange={this.changeValue}
                />
              </div>
            </div>

            <div
              className="label-info"
              style={{
                width: "40%",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              <h4>Label Info</h4>
              <div className="field shipping-row">
                <label htmlFor="label_info_total_weight">
                  Total weight (ounces)
                </label>
                <input
                  className="fullwidth"
                  type="number"
                  name="total_weight"
                  id="label_info_total_weight"
                  value={this.state.address.total_weight}
                  onChange={this.changeValue}
                />
              </div>
              <div className="field shipping-row">
                <label htmlFor="label_info_return_label">Return Label?</label>
                <input
                  type="checkbox"
                  name="return_label"
                  checked={this.state.address.return_label}
                  onChange={this.changeChecked}
                />
              </div>
              <input
                type="submit"
                name="commit"
                value="Get Rates"
                data-disable-with="Get Rates"
              />
              <br />
              <br />
              <br />
              <ShippingOptions
                options={this.state.rates}
                nixRates={this.nixRates}
                address={this.state.address}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default PrintLabels;
