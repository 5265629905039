import React from "react";
class ShippingSegments extends React.Component {
  constructor(props) {
    super();
    this.state = {
      segments: [],
      description: "",
      primary: false,
    };
  }

  componentDidMount() {
    fetch("/api/shipping_segments/" + this.props.variant_id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ segments: data.segments });
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  handleDelete = (segmentId) => {
    fetch("/api/shipping_segments/" + segmentId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          segments: this.state.segments.filter(
            (segment) => segment.id !== data.segment_id,
          ),
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  handleSubmit = () => {
    fetch("/api/shipping_segments/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.token,
      },
      body: JSON.stringify({
        shipping_segment: {
          variant_id: this.props.variant_id,
          product_id: this.props.product_id,
          description: this.state.description,
        },
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ segments: [...this.state.segments, data.segment] });
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  render() {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {this.state.segments.map((segment, index) => (
              <tr key={index}>
                <td className="shipping-segment-attribute-cell">
                  {segment.description}
                </td>
                <td className="shipping-segment-btn-cell">
                  <button
                    className="shipping-segment-btn"
                    type="button"
                    onClick={() => this.handleDelete(segment.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
            <tr>
              <td className="shipping-segment-attribute-cell">
                <input
                  type="text"
                  placeHolder="Description"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                />
              </td>
              <td className="shipping-segment-btn-cell">
                <button
                  className="shipping-segment-btn"
                  type="button"
                  onClick={this.handleSubmit}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ShippingSegments;
