import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import CustomerComments from "./CustomerComments";
import LoadingOverlay from "react-loading-overlay";
class ShippingOptions extends React.Component {
  constructor() {
    super();
    this.changeShipping = this.changeShipping.bind(this);
  }

  changeShipping = (selectedOption) => {
    this.props.setShipping(selectedOption);
  };

  render() {
    return (
      <span>
        <LoadingOverlay
          active={this.props.loadingRates}
          spinner
          text="Loading shipping rates..."
        >
          <div className="title">
            <h3>Shipping Options</h3>
            <br />
          </div>
          {this.props.shipments.map((itemMap, key) => (
            <div key={key} style={{ margin: "5px" }}>
              <h5 className="checkout-field">{itemMap.status}</h5>
              {itemMap.selected_rate == undefined ? (
                <i className="fa fa-warning" style={{ fontSize: "small" }}>
                  Could not generate shipping methods for this shipment
                </i>
              ) : (
                <div>
                  <Select
                    className="shippingOption"
                    value={itemMap.selected_rate}
                    options={itemMap.rates}
                    onChange={this.changeShipping}
                  />
                  {itemMap.rates.find((rate) =>
                    rate.label.includes("Free Shipping"),
                  ) == undefined ? (
                    ""
                  ) : (
                    <p className="_text-xs _font-medium _italic">
                      *Free Shipping will be sent via{" "}
                      <a href="/pages/a-note-on-usps-delays" target="_blank">
                        USPS
                      </a>
                    </p>
                  )}
                </div>
              )}
            </div>
          ))}
          {(this.props.shipments === undefined ||
            this.props.shipments.length == 0) &&
          this.props.loadingRates == false ? (
            <button
              onClick={this.props.getShippingRates}
              className="btn btn-tubedepot btn-large"
              style={{ display: "block", margin: "0 auto" }}
            >
              <i className="fa fa-refresh" /> Calculate Shipping
            </button>
          ) : (
            <CustomerComments
              submitComment={this.props.submitComment}
              comment={this.props.comment}
            />
          )}
        </LoadingOverlay>
      </span>
    );
  }
}

export default ShippingOptions;
