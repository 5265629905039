import React from "react";
import { Link } from "react-router-dom";
import { Parallax, Background } from "react-parallax";
class TubesByAmp extends React.Component {
  render() {
    return (
      <div className="hero">
        <div className="constrict">
          <Link to="/tubes_by_amp">
            <Parallax
              bgImage={this.props.slider_image}
              bgImageAlt="Find your amp. Find your tubes. Done."
              strength={200}
            >
              <div className="mainimg parallax-window">
                <div className="mainimg parallax-window" data-parallax="scroll">
                  {this.props.slider_image == "" ? null : (
                    <div className="transback">
                      <div className="mainimgtext">
                        <h1>
                          Find your amp.
                          <br />
                          Find your tubes.
                          <br />
                        </h1>
                        <h1 className="herobutton">Done.</h1>
                      </div>
                      <button className="mainimgbutton btn btn-tubedepot">
                        Search Tubes By Amp
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Parallax>
          </Link>
        </div>
      </div>
    );
  }
}

export default TubesByAmp;
