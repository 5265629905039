import React from "react";
import { Braintree, HostedField } from "react-braintree-fields";
import ReCAPTCHA from "react-google-recaptcha";

class BraintreeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.completeCheckout = this.completeCheckout.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCardTypeChange = this.onCardTypeChange.bind(this);
    this.onError = this.onError.bind(this);
    this.onAuthorizationSuccess = this.onAuthorizationSuccess.bind(this);
    this.onDataCollectorInstanceReady =
      this.onDataCollectorInstanceReady.bind(this);
    this.setStore = this.setStore.bind(this);
    this.state = {
      isBraintreeReady: false,
      card: "",
      device_data: "",
      storeCard: false,
      disabled: false,
      reCaptchaToken: "[empty]",
    };
    this.recaptcha = React.createRef();
  }

  setStore(e) {
    this.setState({ storeCard: e.target.checked });
  }

  handleChange = (reCaptchaToken) => {
    this.setState({ reCaptchaToken });
    this.completeCheckout();
  };

  onClick = () => {
    if (this.props.testEnv) {
      this.completeCheckout();
    } else {
      this.recaptcha.current.execute();
    }
  };

  completeCheckout(e) {
    this.setState({ disabled: true });
    var that = this;
    const captchaValue = this.props.testEnv || this.state.reCaptchaToken;
    this.getToken({ cardholderName: this.props.bill_address.name })
      .then((payload) => {
        this.props.captureBraintree(
          payload.nonce,
          this.state.device_data,
          this.state.storeCard,
          captchaValue,
        );
        this.setState({ disabled: false });
      })
      .catch(function (error) {
        that.setState({ disabled: false });
        console.log(error);
        that.props.notify(
          "danger",
          "Something went wrong. Please check your payment info and try again.",
        );
      });
  }

  onCardTypeChange({ cards }) {
    this.setState({ card: 1 === cards.length ? cards[0].type : "" });
  }

  onError(err) {
    this.props.notify("danger", err);
    this.ccNum.focus(); // focus number field
  }

  onAuthorizationSuccess() {
    this.setState({ isBraintreeReady: true });
  }

  onDataCollectorInstanceReady(err, dataCollectorInstance) {
    if (!err) this.setState({ device_data: dataCollectorInstance.deviceData });
  }

  render() {
    return (
      <Braintree
        className={this.state.isBraintreeReady ? "" : "disabled"}
        authorization={this.props.paymentInfo.authorization}
        onAuthorizationSuccess={this.onAuthorizationSuccess}
        onDataCollectorInstanceReady={this.onDataCollectorInstanceReady}
        onError={this.handleError}
        onCardTypeChange={this.onCardTypeChange}
        getTokenRef={(ref) => (this.getToken = ref)}
        styles={{
          input: {
            "font-size": "16px",
            "font-family": "helvetica, tahoma, calibri, sans-serif",
            color: "#3a3a3a",
            height: "46px",
            "padding-top": "10px;",
          },
          ":focus": {
            color: "black",
          },
        }}
      >
        <div className="floatinput">
          <HostedField
            placeholder=""
            type="number"
            ref={(ccNum) => (this.ccNum = ccNum)}
          />
          <span className="floatlabel floatme">Card Number</span>
        </div>
        <div className="floatinput btleft">
          <HostedField placeholder="" type="expirationDate" />
          <span className="floatlabel floatme" style={{ paddingTop: "5px" }}>
            Expiration Date
          </span>
        </div>
        <div className="floatinput btright">
          <HostedField placeholder="" type="cvv" />
          <span className="floatlabel floatme" style={{ paddingTop: "5px" }}>
            CVV
          </span>
        </div>
        {this.props.loggedIn ? (
          <div
            style={{
              marginTop: "50px",
              marginBottom: "-10px",
              textAlign: "center",
            }}
          >
            <label>
              <input
                type="checkbox"
                onChange={this.setStore}
                defaultChecked={this.state.storeCard}
              />{" "}
              Store this card for future use
            </label>
          </div>
        ) : null}
        {this.props.testEnv ? null : (
          <ReCAPTCHA
            sitekey={this.props.reCaptchaSiteKey}
            ref={this.recaptcha}
            size="invisible"
            onChange={this.handleChange}
          />
        )}
        <button
          disabled={this.state.disabled}
          style={{ width: "100%", marginTop: "10px" }}
          className="btn btn-tubedepot checkoutPadding"
          onClick={this.onClick}
        >
          Complete Your Order
        </button>
      </Braintree>
    );
  }
}

export default BraintreeComponent;
