import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class Address extends React.Component {
  setStreetAddress = () => {
    let streetAddress = this.props.address.address1;
    if (this.props.address.address2) {
      streetAddress = `${streetAddress} <br /> ${this.props.address.address2}`;
    }
    return ReactHtmlParser(streetAddress);
  };

  setCompany = () => {
    let company;

    if (this.props.address.company) {
      company = `
      <div class="org">
        ${this.props.address.company}
      </div>
    `;
    }

    return ReactHtmlParser(company);
  };

  setPhone = () => {
    let phone = "";

    if (this.props.address.phone) {
      phone =
        phone +
        `
      <div class="tel">
        <span class="type">
          Phone
        </span>
        ${this.props.address.phone}
      </div>
    `;
    }

    if (this.props.address.alternative_phone) {
      phone =
        phone +
        `
      <div class="alternative-phone tel">
        <span className="type">
          Alternative Phone
        </span>
        ${this.props.address.alternative_phone}
      </div>
    `;
    }

    return ReactHtmlParser(phone);
  };

  render() {
    return (
      <div className="address vcard">
        <div className="fn">{this.props.address.name}</div>
        {this.setCompany()}
        <div className="adr">
          <div className="street-address">
            <div className="street-address-line">{this.setStreetAddress()}</div>
          </div>
          <div className="local">
            <span className="locality">{this.props.address.city}</span>
            &nbsp;
            <span className="region">{this.props.address.state.abbr}</span>
            &nbsp;
            <span className="postal-code">{this.props.address.zipcode}</span>
            <div className="country-name">
              {this.props.address.country.name}
            </div>
          </div>
        </div>
        {this.setPhone()}
      </div>
    );
  }
}

export default Address;
