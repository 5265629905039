import React from "react";
import { Switch, Route } from "react-router-dom";
import ReactResizeDetector from "react-resize-detector";
import Orders from "./Orders";
import Order from "./Order";
import Form from "./Form";
import AddressForms from "./AddressForms";

class Account extends React.Component {
  render() {
    return (
      <div>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.props.updateHeight}
        >
          <Switch>
            <Route
              exact
              path={`${this.props.match.path}`}
              render={(props) => (
                <Orders
                  defaultAddress={this.props.defaultAddress}
                  defaultBillingAddress={this.props.defaultBillingAddress}
                  repeatOrder={this.props.repeatOrder}
                  notify={this.props.notify}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/form`}
              render={(props) => (
                <Form
                  notify={this.props.notify}
                  startLoading={this.props.startLoading}
                  stopLoading={this.props.stopLoading}
                  email={this.props.email}
                  handleEmailChange={this.props.handleEmailChange}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/shipping_address_form`}
              render={(props) => (
                <AddressForms
                  notify={this.props.notify}
                  startLoading={this.props.startLoading}
                  stopLoading={this.props.stopLoading}
                  locations={this.props.locations}
                  loadStates={this.props.loadStates}
                  address={this.props.defaultAddress}
                  handleAddressChange={this.props.handleAddressChange}
                  addressType="Shipping"
                  {...props}
                />
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/billing_address_form`}
              render={(props) => (
                <AddressForms
                  notify={this.props.notify}
                  startLoading={this.props.startLoading}
                  stopLoading={this.props.stopLoading}
                  locations={this.props.locations}
                  loadStates={this.props.loadStates}
                  address={this.props.defaultBillingAddress}
                  handleAddressChange={this.props.handleAddressChange}
                  addressType="Billing"
                  {...props}
                />
              )}
            />
            <Route
              path={`${this.props.match.path}/:orderNumber`}
              render={(props) => (
                <Order
                  notify={this.props.notify}
                  repeatOrder={this.props.repeatOrder}
                  {...props}
                />
              )}
            />
          </Switch>
        </ReactResizeDetector>
      </div>
    );
  }
}

export default Account;
