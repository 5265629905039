import React from "react";
class ShippingOptions extends React.Component {
  constructor(props) {
    super(props);
    this.printZPL = this.printZPL.bind(this);
    this.printPDF = this.printPDF.bind(this);
  }

  printZPL(e) {
    var name = e.target.attributes.name.value;
    e.preventDefault();
    this.props.nixRates();
    fetch("/api/return_postage/print_zpl", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        address: this.props.address,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      });
  }

  printPDF(e) {
    var name = e.target.attributes.name.value;
    e.preventDefault();
    this.props.nixRates();
    fetch("/api/return_postage/print_pdf", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        address: this.props.address,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        window.location.href = data.label_url;
      });
  }

  render() {
    return (
      <table>
        <tbody>
          <tr>
            <td>Carrier</td>
            <td>Price</td>
            <td>Label</td>
          </tr>
          {this.props.options.map((rate, index) => (
            <tr key={index}>
              <td>
                {rate.carrier} {rate.service}
              </td>
              <td>{rate.rate}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                <div
                  onClick={this.printPDF}
                  name={rate.id}
                  className="btn btn-tubedepot btn-success"
                >
                  Print PDF
                </div>
                <div
                  onClick={this.printZPL}
                  name={rate.id}
                  className="btn btn-tubedepot btn-warning"
                  style={{ marginLeft: "5px" }}
                >
                  Print ZPL
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default ShippingOptions;
