import React from "react";
import { withRouter, Link, Redirect } from "react-router-dom";

class Form extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      redirect: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.email === "Account") {
      this.setState({ redirect: true });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const that = this;
    this.props.startLoading();
    fetch("/react/users/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector("meta[name='csrf-token']")
          .getAttribute("content"),
      },
      body: JSON.stringify({
        user: {
          email: this.state.email,
        },
        email: this.props.email,
        password: this.state.password,
      }),
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw "whoops";
      })
      .then((data) => {
        that.props.stopLoading();
        that.props.notify(
          "success",
          "Your email has been sucessfully updated.",
        );
        this.props.handleEmailChange(data);
        this.setState({ redirect: true });
      })
      .catch(function (error) {
        that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  };

  handleChange = (event) => {
    let state_name = event.target.id;
    let newState = {};
    newState[state_name] = event.target.value;
    this.setState(newState);
  };

  render() {
    if (this.state.redirect) return <Redirect push to={{ pathname: "/" }} />;
    return (
      <div className="constrict">
        <legend>Update Account</legend>
        <form onSubmit={this.handleSubmit}>
          <label>
            New Email:
            <br />
            <input
              id="email"
              type="text"
              value={this.state.email}
              onChange={(event) => this.handleChange(event)}
              required
            />
          </label>
          <label>
            Password:
            <br />
            <input
              id="password"
              type="password"
              value={this.state.password}
              onChange={(event) => this.handleChange(event)}
              required
            />
          </label>
          <button className="btn btn-tubedepot" type="submit">
            <span>Update Account</span>
          </button>
          <Link
            to={{
              pathname: "/account",
            }}
          >
            <button
              className="btn btn-tubedepot"
              type="button"
              style={{ marginLeft: "20px", cursor: "pointer" }}
            >
              <span>Cancel</span>
            </button>
          </Link>
        </form>
      </div>
    );
  }
}

export default Form;
