import React from "react";

class OrderingFAQ extends React.Component {
  componentDidMount() {
    gtag("event", "page_view", {
      page_path: window.location.pathname,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="content-margin bare-table constrict">
          <h2 className="title">How To Order</h2>
          <a name="pos0">
            <strong>Frequently Asked Ordering Questions:</strong>
          </a>
          <br />
          <br />
          <a href="#pos1" className="homelink">
            How do I place an order?
          </a>
          <br />
          <a href="#pos3" className="homelink">
            Will you confirm my order with an email?
          </a>
          <br />
          <a href="#pos4" className="homelink">
            Is your online ordering system secure?
          </a>
          <br />
          <a href="#pos5" className="homelink">
            I want to order multiple items, but the shopping cart won't hold
            more than one item.
          </a>
          <br />
          <a href="#pos6" className="homelink">
            Can I place a phone order?
          </a>
          <br />
          <a href="#pos7" className="homelink">
            What if I want to fax or email an order?
          </a>
          <br />
          <a href="#pos8" className="homelink">
            What options do you have for shipping?
          </a>
          <br />
          <a href="#pos9" className="homelink">
            I live overseas. Can you ship to me?
          </a>
          <br />
          <a href="#pos10" className="homelink">
            When will you ship my order?
          </a>
          <br />
          <a href="#pos11" className="homelink">
            Do your tubes have a warranty? How long?
          </a>
          <br />
          <a href="#pos12" className="homelink">
            What methods of payment do you accept?
          </a>
          <br />
          <br />
          <br />
          <a name="pos1">
            <strong>How do I place an order?</strong>
          </a>
          <br />
          <br />
          Ordering couldn't be easier.
          <br />
          <br />
          Once you've decided on what you want, just click the "Add to Cart"
          button for that product. That product will be dropped into your cart.
          You will notice that you don't have to leave the product page for each
          item you place in your basket. When ordering tubes, remember all of
          our tubes are sold singly. By default, the number 1 is shown in the
          quantity field. If you need a matched pair of power tubes, just enter
          the number 2 or click the up arrow on the right side of the quantity
          field and you will get a matched pair. If you need a matched quad,
          input the quantity 4 and select the "Match these tubes" radio button.
          You will automatically receive a matched quad.
          <br />
          <br />
          If you are finished shopping and ready to checkout then go up to the
          right corner of your screen and click on the shopping cart. You can
          checkout as a guest, but if you want a faster experience, then go
          ahead and create your account. You will be able to track your orders
          and see your order history.
          <br />
          <br />
          During the checkout process, you will provide your billing and
          shipping information in the fields on the left. Please make sure to
          provide accurate billing and shipping information as orders with
          incorrect information can lead to delays or cancellation. The billing
          address is the address where your credit card statement is sent. You
          will then choose your preferred method of shipping, input your payment
          information, review your order and send it. That’s it.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos3">
            <strong>Will you confirm my order with an email?</strong>
          </a>
          <br />
          <br />
          Yes, we will email tracking information and your invoice upon
          shipment. If you placed an order and you have not received an email
          with a tracking number, it may be because of "Spam Blocking".
          Unfortunately these "Spam Blockers" may see our tracking confirmation
          email as Spam. We have no control over your Internet Service Provider
          and the way they stop Spam. If you have not received a tracking number
          for your shipment, please email or call (Toll Free: 877-289-7994) us
          and we will be happy to provide it to you.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos4">
            <strong>Is your online ordering system secure?</strong>
          </a>
          <br />
          <br />
          TubeDepot is certified fully PCI Compliant. This means that we follow
          all best practices as established by the Payment Card Industry
          Security Standards Council. All transactions on our site take place
          over a secure SSL connection with 128-bit encryption, so your
          information can't be intercepted. Additionally, we do not store any of
          your payment information on-site and do not have access to your actual
          payment information.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos5">
            <strong>
              I want to order multiple items, but the shopping cart won't hold
              more than one item.
            </strong>
          </a>
          <br />
          <br />
          The reason for this may be because of your privacy / security
          settings. If you do not allow cookies, then the system cannot remember
          what you ordered. To fix this problem set your browser to allow
          cookies. Now, go back and add as many items as you want.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos6">
            <strong>Can I place a phone order?</strong>
          </a>
          <br />
          <br />
          We welcome phone orders. Please call us toll-free at 877-289-7994 and
          we will gladly answer your questions and take your order.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos7">
            <strong>What if I want to fax or email an order?</strong>
          </a>
          <br />
          <br />
          To fax or email an order, please provide us with what you want to
          purchase. Please be as descriptive as possible. Also, provide us with
          the following information
          <br />
          <br />
          Your Full name
          <br />
          Billing Address (for your credit card)
          <br />
          Shipping Address
          <br />
          Phone Number
          <br />
          Credit Card Number
          <br />
          Expiration Date
          <br />
          Email Address (if you have one)
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos8">
            <strong>What options do you have for shipping?</strong>
          </a>
          <br />
          <br />
          All Orders (Domestic and International) will ship to you via FedEx or
          USPS service. At checkout, you will be offered a multitude of shipping
          options. The cost of these shipping services will depend on the weight
          and destination of your order. You can select the best option based on
          your time requirements and cost preference.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos9">
            <strong>I live overseas. Can you ship to me?</strong>
          </a>
          <br />
          <br />
          Yes, we ship worldwide.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos10">
            <strong>When do you ship the tubes?</strong>
          </a>
          <br />
          <br />
          All orders, with in-stock items, placed before 3:00PM Central Monday
          through Friday are shipped on the same day. Since we operate at the
          FedEx and USPS hub city, all orders are delivered in an expeditious
          time frame. We have many customers that are surprised when they select
          3 day shipping and receive it the next day.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos11">
            <strong>Do your tubes have a warranty? How long?</strong>
          </a>
          <br />
          <br />
          All of our tubes have a warranty of 90 days from the date of purchase.
          If your tube fails and is within this time frame, please contact us to
          receive an RMA number. Once we receive the tube back, we will
          immediately issue credit to you and you can redeem this credit as a
          replacement, company credit, or a refund.
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
          <a name="pos12">
            <strong>What methods of payment do you accept?</strong>
          </a>
          <br />
          <br />
          We accept the following form of payment:
          <br />
          <br />
          <ul>
            <li>Credit Card (Visa, MasterCard, Discover, Amex)</li>
            <li>Personal Check</li>
            <li>Cashier's check</li>
            <li>Money Order</li>
            <li>Wire Transfer</li>
            <li>PayPal - Our address is paypal_merchant@tubedepot.com</li>
          </ul>
          <br />
          <br />
          <center>
            <strong>
              <a href="#pos0">Back to Top</a>
            </strong>
          </center>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default OrderingFAQ;
