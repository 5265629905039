import React from "react";

class GiftCardRecipient extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div id="test-types">
        <div className="floatinput">
          <input
            type="email"
            name="recipient"
            required=""
            value={this.props.recipient}
            onChange={this.props.changeRecipient}
            style={{
              height: "40px",
              fontSize: "larger",
              width: "100%",
              boxSizing: "border-box",
            }}
          />
          <span
            className={
              "floatlabel " + (this.props.recipient == "" ? null : "floatme")
            }
            style={{ top: "10px" }}
          >
            Recipient
          </span>
        </div>
      </div>
    );
  }
}

export default GiftCardRecipient;
