import React from "react";
import { Link } from "react-router-dom";
import TubesByAmp from "./TubesByAmp";
import TaxonBanner from "./TaxonBanner";
import NewProducts from "./NewProducts";
import History from "./History";
import DailyDeals from "./DailyDeals";
import Taxons from "./Taxons";
import Products from "./Products";
import Video from "./Video";
import SaleyDeals from "./SaleyDeals";
import OneSaleyDeals from "./OneSaleyDeals";
import SaleHero from "./SaleHero";
import InstagramWidget from "./InstagramWidget";

class Index extends React.Component {
  constructor() {
    super();
    this.jack = "Taxon";
  }

  setBanner = () => {
    if (this.props.taxon_address && this.props.taxon_message) {
      return (
        <TaxonBanner
          taxonMessage={this.props.taxon_message}
          taxonAddress={this.props.taxon_address}
          slider_image={this.props.data.slider_image}
        />
      );
    } else {
      return <TubesByAmp slider_image={this.props.data.slider_image} />;
    }
  };

  render() {
    const Components = {
      Taxon: <Taxons taxons={this.props.data.taxons} key={1} />,
      History: <History user={this.props.logged_in} key={0} />,
      NewProducts: (
        <NewProducts
          key={2}
          new_products={this.props.data.new_products}
          see_more_button={this.props.data.see_more_button}
        />
      ),
      Products: <Products key={3} products={this.props.data.products} />,
      InstagramWidget: <InstagramWidget key={4} />,
      Video: <Video key={5} video_url={this.props.data.video_url} />,
    };
    return (
      <div className="react-container">
        {this.props.data.on_sale && !this.props.data.one_product_sale ? (
          <SaleHero
            slider_image={this.props.data.slider_image}
            sale_text_1={this.props.data.sale_text_1}
            sale_text_2={this.props.data.sale_text_2}
          />
        ) : (
          this.setBanner()
        )}
        <div className="conten">
          {this.props.data.on_sale ? (
            <span>
              {this.props.data.one_product_sale ? (
                <OneSaleyDeals
                  timer_text_1={this.props.data.timer_text_1}
                  timer_text_2={this.props.data.timer_text_2}
                  slider={this.props.data.single_slider_image}
                  slider_link={this.props.data.single_slider_link}
                  sale_end={this.props.data.sale_end}
                />
              ) : (
                <SaleyDeals
                  coupon_code_display={this.props.data.coupon_code_display}
                  timer_text_1={this.props.data.timer_text_1}
                  timer_text_2={this.props.data.timer_text_2}
                  sale_prods={this.props.data.sale_prods}
                  sale_end={this.props.data.sale_end}
                />
              )}
            </span>
          ) : (
            <DailyDeals
              sale_prods={this.props.data.sale_prods}
              sale_end={this.props.data.sale_end}
            />
          )}
          {this.props.data.order.map((name, index) => Components[name])}
        </div>
      </div>
    );
  }
}

export default Index;
