// Import TinyMCE
import tinymce from "tinymce/tinymce";

// Default icons are required for TinyMCE 5.3 or above
import "tinymce/icons/default";

// A theme is also required
require("tinymce/skins/ui/oxide/skin.min.css");
import "tinymce/themes/silver";

// Any plugins you want to use has to be imported
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/lists";
import "tinymce/plugins/table";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/preview";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/directionality";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/template";

document.addEventListener("DOMContentLoaded", function () {
  const editors = document.querySelectorAll(".tinymce");

  editors.forEach(function (editor) {
    const viewableClass = editor.dataset.viewableClass,
      viewableId = editor.dataset.viewableId;

    tinymce.init({
      selector: "#" + editor.id,
      mode: "exact",
      skin: false,
      content_css: false,
      schema: "html5-strict",
      element_format: "html",
      resize: true,
      plugins:
        "image, link, lists, table, insertdatetime, preview, media, searchreplace, paste, directionality, fullscreen, noneditable, visualchars, nonbreaking, template",
      toolbar:
        "undo redo | bold italic underline strikethrough blockquote backcolor | bullist numlist | outdent indent | link unlink image media | preview fullscreen",
      images_upload_handler: function (blobInfo, success, failure) {
        var formData = new FormData();

        formData.append("discourse_image[attachment]", blobInfo.blob());
        formData.append("discourse_image[viewable_id]", viewableId);

        Spree.ajax({
          url: ["/api", viewableClass, viewableId, "discourse_images"].join(
            "/",
          ),
          type: "POST",
          dataType: "json",
          data: formData,
          processData: false,
          contentType: false,
        })
          .done(function (response) {
            success(response.url);
          })
          .error(function (jqXHR, textStatus, errorThrown) {
            console.log("it broke");
          });
      },
    });
  });
});
