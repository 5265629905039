import React from "react";
import BraintreeComponent from "./BraintreeComponent";
class BraintreeStoredPayment extends React.Component {
  constructor() {
    super();
    this.changeCard = this.changeCard.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.removeStoredCard = this.removeStoredCard.bind(this);
    this.deleteIcon = React.createRef();
    this.state = {
      newCard: false,
      selectedToken: "",
      actualValue: "",
      disabled: false,
    };
  }

  removeStoredCard() {
    if (this.state.selectedToken != "") {
      this.props.removeStoredCard(this.state.selectedToken);
      this.setState({ selectedToken: "", actualValue: "new", newCard: true });
      $(this.deleteIcon.current).hide();
    }
  }

  onSubmit(e) {
    this.setState({ disabled: true });
    if (this.state.selectedToken == "") {
      alert("Please select a card.");
      this.setState({ disabled: false });
    } else {
      this.props.captureStoredBraintree(
        this.state.selectedToken,
        this.state.cvv,
        null,
      );
      this.setState({ disabled: false });
    }
  }

  changeCard(e) {
    this.setState({ actualValue: e.target.value });
    if (e.target.value == "new") {
      this.setState({ newCard: true, selectedToken: "" });
      $(this.deleteIcon.current).hide();
    } else {
      this.setState({ newCard: false, selectedToken: e.target.value });
      $(this.deleteIcon.current).show();
    }
  }

  componentDidMount() {
    var cid = this.props.paymentInfo.storedCards[0][2];
    this.setState({ selectedToken: cid, actualValue: cid });
  }

  render() {
    return (
      <div>
        <select
          className="payment-selector"
          onChange={this.changeCard}
          value={this.state.actualValue}
        >
          {this.props.paymentInfo.storedCards.map((item, index) => (
            <option key={index} value={item[2]}>
              {item[1]} ending in {item[0]}
            </option>
          ))}
          <option value="new">Use a new payment method</option>
        </select>
        <i
          ref={this.deleteIcon}
          className="fa fa-times remove-card"
          onClick={() => {
            if (
              window.confirm(
                "Are you sure you wish to delete this stored card?",
              )
            )
              this.removeStoredCard();
          }}
        />
        {this.state.newCard ? (
          <BraintreeComponent
            loggedIn={this.props.loggedIn}
            captureBraintree={this.props.captureBraintree}
            paymentInfo={this.props.paymentInfo}
            bill_address={this.props.bill_address}
            notify={this.props.notify}
          />
        ) : (
          <button
            disabled={this.state.disabled}
            style={{ width: "100%", marginTop: "10px" }}
            className="btn btn-tubedepot"
            onClick={this.onSubmit}
          >
            Complete Your Order
          </button>
        )}
      </div>
    );
  }
}

export default BraintreeStoredPayment;
