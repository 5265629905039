import React from "react";
import { Link } from "react-router-dom";

class CartSuggestions extends React.Component {
  render() {
    return (
      <div>
        <h3>You might be interested in...</h3>
        <div className="ab-list">
          {this.props.cartSuggestions.map((product, index) => (
            <Link
              to={{
                pathname: product.path,
                state: { product_id: product.id },
              }}
              onClick={this.props.closeModal}
              key={index}
            >
              <div className="abp">
                <div className="abpinner">
                  <div className="abpimage">
                    <img itemProp="image" src={product.image} />
                  </div>
                  <div className="abpname">{product.name}</div>
                  <div className="pricing">{product.display_price}</div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default CartSuggestions;
