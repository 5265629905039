import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import Suggest from "./Search/Suggest";

class Search extends React.Component {
  constructor() {
    super();
    this.state = {
      searchTerm: "",
      productSuggest: [],
      redirect: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.redirect) {
      this.setState({
        redirect: false,
        searchTerm: "",
      });
    } else if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        searchTerm: "",
        productSuggest: [],
      });
    }
  }

  componentDidMount() {
    this.setState({ redirect: false });
  }

  searchSuggest = () => {
    if (
      this.state.searchTerm.length <= 2 ||
      document.documentElement.clientWidth < 940
    )
      return this.setState({ productSuggest: [] });
    fetch(
      `/react/products/search_suggest/${encodeURIComponent(this.state.searchTerm)}`,
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          productSuggest: data,
        });
      });
  };

  handleSearchFocus = (event) => {
    if (event.type === "focus") {
      document.addEventListener("keydown", this.handleSubmit);
      document.addEventListener("input", this.handleInput);
      document
        .querySelector("div#header-search-form ul.search-autosuggest")
        .classList.remove("hidden");
    } else if (event.type === "blur") {
      document.removeEventListener("keydown", this.handleSubmit);
      document.removeEventListener("input", this.handleInput);
      setTimeout(() => {
        document
          .querySelector("div#header-search-form ul.search-autosuggest")
          .classList.add("hidden");
      }, 200);
    }
  };

  handleSubmit = (event) => {
    if (this.state.searchTerm === "") return;
    if (event.key === "Enter") {
      this.setState({ redirect: true });
    } else if (
      event.type === "click" &&
      (event.target.closest("button").id === "header-search" ||
        event.target.id === "product-suggest-see-more")
    ) {
      this.setState({ redirect: true });
    }
  };

  handleInput = () => {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.searchSuggest();
    }, 2000);
  };

  render() {
    if (this.state.redirect)
      return (
        <Redirect
          push
          to={{
            pathname: "/products",
            state: { searchTerm: this.state.searchTerm },
          }}
        />
      );
    return (
      <div id="header-search-form" className="form-horizontal _relative">
        <fieldset>
          <div className="floatinput">
            <input
              type="search"
              name="keywords"
              id="keywords"
              value={this.state.searchTerm}
              className="span4"
              size="16"
              autoComplete="off"
              onChange={(e) => this.setState({ searchTerm: e.target.value })}
              onFocus={(e) => this.handleSearchFocus(e)}
              onBlur={(e) => this.handleSearchFocus(e)}
              style={{ paddingTop: "10px", height: "22px", fontSize: "16px" }}
            />
            <span
              className={
                "floatlabel " +
                (this.state.searchTerm == "" || this.state.searchTerm == null
                  ? null
                  : "floatme")
              }
              style={{ top: "10px" }}
            >
              Search tubes, parts, kits and more...
            </span>
            <button
              type="submit"
              id="header-search"
              className="_absolute _right-1 _top-2 _bg-transparent _border-transparent"
              onClick={(e) => this.handleSubmit(e)}
            >
              <i className="fa fa-search fa-white"></i>
            </button>
          </div>
          <Suggest
            productSuggest={this.state.productSuggest}
            handleSubmit={(event) => this.handleSubmit(event)}
          />
        </fieldset>
      </div>
    );
  }
}

export default withRouter(Search);
