import React from "react";
import { Link } from "react-router-dom";
class Adjustments extends React.Component {
  render() {
    return (
      <React.Fragment>
        <thead>
          <tr data-hook="cart_adjustments_headers">
            <th className="cart-adjustment-header" colSpan="6">
              Adjustments
            </th>
          </tr>
        </thead>
        <tbody id="cart_adjustments" data-hook>
          {this.props.adjustments.map((itemMap, key) => (
            <tr key={key}>
              <td colSpan="4">{itemMap.label}</td>
              <td>{itemMap.price}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </React.Fragment>
    );
  }
}

export default Adjustments;
