import React from "react";
import Routes from "./Routes";
import ReactResizeDetector from "react-resize-detector";

class Data extends React.Component {
  constructor() {
    super();
    this.state = {
      search: [],
    };
  }

  componentDidMount() {
    const that = this;
    return fetch("/react/tbasearchdata")
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState({ search: data });
      })
      .catch(function (error) {
        that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }
  render() {
    return (
      <div>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.props.updateHeight}
        >
          <Routes
            notify={this.props.notify}
            search={this.state.search}
            addToCart={this.props.addToCart}
          />
        </ReactResizeDetector>
      </div>
    );
  }
}

export default Data;
