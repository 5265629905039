import React from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import ReactResizeDetector from "react-resize-detector";

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.changeInfo = this.changeInfo.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.state = {
      password: "",
      password_confirmation: "",
      reset_password_token: "",
    };
  }

  componentDidMount() {
    this.setState({
      reset_password_token: queryString.parse(this.props.location.search)
        .reset_password_token,
    });
  }

  changeInfo(e) {
    const {
      target: { name, value },
    } = e;
    this.setState({
      [name]: value,
    });
  }

  changePassword(e) {
    e.preventDefault();
    this.props.changePassword(this.state);
  }

  render() {
    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={this.props.updateHeight}
      >
        <div className="content-margin constrict">
          <div id="change-password">
            <h6>Change My Password</h6>

            <form onSubmit={this.changePassword}>
              <label htmlFor="password">Reset Password</label>
              <input
                name="password"
                type="password"
                onChange={this.changeInfo}
                placeholder="Password"
                required
              />
              <label htmlFor="password_confirmation">
                Confirm New Password
              </label>
              <input
                name="password_confirmation"
                type="password"
                onChange={this.changeInfo}
                placeholder="Confirm Password"
                required
              />

              <br />
              <input
                type="submit"
                value="Update Password"
                className="btn btn-tubedepot"
              />
            </form>
          </div>
        </div>
      </ReactResizeDetector>
    );
  }
}

export default ResetPassword;
