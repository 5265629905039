import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slide-out";
import Cart from "../Header/Cart/Cart";
import Checkout from "../Header/Checkout/Index";
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalAndMenu = this.closeModalAndMenu.bind(this);
    this.capturePayPal = this.capturePayPal.bind(this);
    this.captureBraintree = this.captureBraintree.bind(this);
    this.captureStoredBraintree = this.captureStoredBraintree.bind(this);
    this.state = {
      open: false,
    };
  }

  capturePayPal(nonce) {
    this.props.capturePayPal(nonce);
    this.closeMenu();
  }

  captureBraintree(nonce, device_data, storeCard) {
    this.props.captureBraintree(nonce, device_data, storeCard);
    this.closeMenu();
  }

  captureStoredBraintree(selectedToken, cvv, storeCard) {
    this.props.captureStoredBraintree(selectedToken, cvv, storeCard);
    this.closeMenu();
  }

  closeMenu() {
    this.setState({ open: false });
  }

  openMenu() {
    if (this.props.newCheckout) {
      window.location.href = "/checkout/address";
      this.closeMenu();
    } else {
      this.setState({ open: true });
      $("#navModal").animate({ scrollTop: 0 }, 0);
    }
  }

  closeModal() {
    $("#mcartModal").modal("hide");
  }

  closeModalAndMenu() {
    $("#mcartModal").modal("hide");
    var that = this;
    setTimeout(function () {
      that.closeMenu();
    }, 500);
  }

  render() {
    return (
      <div className="mobile-navbar">
        <div className="navbar-inner constrict">
          <div className="container">
            <ul
              className="nav"
              style={
                this.state.open ? { overflow: "hidden", height: "80vh" } : {}
              }
            >
              {this.props.itemCount == 0 ? null : (
                <li className="dropdown-li">
                  <button
                    className="unbuttonize slicky abli"
                    onClick={this.openMenu}
                  >
                    <div className="dropdown dd-color">Checkout</div>
                    <i className="fa fa-arrow-right dd-arrow" />
                  </button>
                </li>
              )}
              <Cart
                isMobile={true}
                changeTesting={this.props.changeTesting}
                orderCompleted={this.props.orderCompleted}
                changeQuantity={this.props.changeQuantity}
                cartSuggestions={this.props.cartSuggestions}
                order={this.props.order}
                emptyCart={this.props.emptyCart}
                checkoutImage={this.props.checkoutImage}
                couponCode={this.props.couponCode}
                deleteItem={this.props.deleteItem}
                international={this.props.checkoutInfo.international}
              />
              <Slider isOpen={this.state.open}>
                <li
                  className="dropdown-li slicky abli"
                  onClick={this.closeMenu}
                >
                  <div className="dropdown dd-color">Shopping Cart</div>
                  <i className="fa fa-arrow-left dd-arrow-left" />
                </li>
                <Checkout
                  getShippingRates={this.props.getShippingRates}
                  loadingRates={this.props.loadingRates}
                  removeStoredCard={this.props.removeStoredCard}
                  comment={this.props.comment}
                  submitComment={this.props.submitComment}
                  email={this.props.email}
                  capturePayPal={this.capturePayPal}
                  captureBraintree={this.captureBraintree}
                  captureStoredBraintree={this.captureStoredBraintree}
                  paymentInfo={this.props.paymentInfo}
                  loggedIn={this.props.loggedIn}
                  notify={this.props.notify}
                  shipments={this.props.shipments}
                  setShipping={this.props.setShipping}
                  editAddress={this.props.editAddress}
                  loadStates={this.props.loadStates}
                  checkoutInfo={this.props.checkoutInfo}
                  setAddress={this.props.setAddress}
                  locations={this.props.locations}
                  total={this.props.total}
                  reCaptchaSiteKey={this.props.reCaptchaSiteKey}
                  testEnv={this.props.testEnv}
                />
              </Slider>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
