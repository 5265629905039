import React from "react";
import { Link } from "react-router-dom";
class Parts extends React.Component {
  render() {
    return (
      <li
        className="dropdown"
        onMouseEnter={() => this.props.open("Parts")}
        onMouseLeave={this.props.close}
      >
        <a href="#" data-toggle="dropdown">
          Parts & Accessories<b className="caret"></b>
        </a>
        <ul
          className="nav-parts dropdown-menu"
          style={{
            visibility: this.props.opened == "Parts" ? "visible" : "hidden",
          }}
        >
          <li>
            <div className="pull-left multi-border">
              <h5>DIY Central</h5>
              <ul className="multi-dropdown-menu pull-left limitcolumns">
                {this.props.diycentral.map((itemMap, key) => (
                  <li onClick={this.props.close} key={key}>
                    <Link to={itemMap.path}>{itemMap.name}</Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="pull-left">
              <h5>Guitar Parts</h5>
              <ul className="multi-dropdown-menu">
                {this.props.guitarparts.map((itemMap, key) => (
                  <li onClick={this.props.close} key={key}>
                    <Link to={itemMap.path}>{itemMap.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        </ul>
      </li>
    );
  }
}

export default Parts;
