import React from "react";

class HomeIndex extends React.Component {
  constructor() {
    super();
    this.changeIndex = this.changeIndex.bind(this);
    this.changeVideo = this.changeVideo.bind(this);
    this.state = {
      sequence: [],
      video: "",
    };
  }

  componentDidMount() {
    this.setState({ sequence: this.props.sequence, video: this.props.video });
  }

  changeVideo(e) {
    this.setState({ video: e.target.value });
  }

  changeIndex(e, key) {
    var arr = this.state.sequence;
    arr[key] = e.target.value;
    this.setState({ sequence: arr });
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-md-6">
          {this.props.sequence.map((item, index) => (
            <span key={index}>
              Home Item #{index + 1}:
              <select
                onChange={(event) => this.changeIndex(event, index)}
                value={this.state.sequence[index]}
              >
                <option value="History">History</option>
                <option value="Taxon">Taxon</option>
                <option value="NewProducts">NewProducts</option>
                <option value="Products">Products</option>
                <option value="InstagramWidget">InstagramWidget</option>
                <option value="Video">Video</option>
              </select>
              <br />
              <br />
            </span>
          ))}
          <input
            name="store[home_sequence]"
            value={this.state.sequence}
            type="hidden"
          />
        </div>
        <div className="col-12 col-md-6">
          Video Id (from YouTube):
          <input
            name="store[video_id]"
            type="text"
            value={this.state.video}
            onChange={this.changeVideo}
          />
          <br />
          <br />
          Video preview:
          <br />
          <iframe
            className="videoframe"
            width="340"
            height="180"
            src={"https://www.youtube.com/embed/" + this.state.video}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    );
  }
}

export default HomeIndex;
