import React from "react";
import ImageModal from "./ImageModal";

class Image extends React.Component {
  constructor() {
    super();
    this.state = {
      previewImageIndex: 0,
      currentImageIndex: 0,
      modalOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.images !== nextProps.images) {
      this.setState({
        previewImageIndex: 0,
        currentImageIndex: 0,
      });
    }
  }

  handleImageChange = (key) => {
    let index = this.state.currentImageIndex;
    if (key === "ArrowRight") {
      this.nextImage(index + 1);
    } else if (key === "ArrowLeft") {
      this.previousImage(index - 1);
    }
  };

  nextImage = (index) => {
    if (index > this.props.images.length - 1) {
      this.changeImage(0);
    } else {
      this.changeImage(index);
    }
  };

  previousImage = (index) => {
    if (index < 0) {
      this.changeImage(this.props.images.length - 1);
    } else {
      this.changeImage(index);
    }
  };

  changeImage = (index) => {
    this.setState({
      previewImageIndex: index,
      currentImageIndex: index,
    });
  };

  addPreviewImage = (index) => {
    this.setState({
      previewImageIndex: index,
    });
  };

  removePreviewImage = () => {
    this.setState({
      previewImageIndex: this.state.currentImageIndex,
    });
  };

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  render() {
    return (
      <div>
        <div
          id="main-image"
          style={{ cursor: "pointer" }}
          onClick={() => this.toggleModal()}
        >
          <div id="productImage">
            <img
              itemProp="image"
              src={this.props.images[this.state.previewImageIndex].default}
              alt={this.props.images[this.state.previewImageIndex].alt}
            />
            <button className="btn btn-mini" id="imageZoom">
              <i className="icon-zoom-in"></i>
            </button>
          </div>
        </div>
        <div id="thumbnails">
          <ul id="product-thumbnails" className="thumbnails inline">
            {this.props.images.length > 1
              ? this.props.images.map((image, index) => (
                  <li
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => this.changeImage(index)}
                    onMouseOver={() => this.addPreviewImage(index)}
                    onMouseOut={() => this.removePreviewImage()}
                  >
                    <img itemProp="image" src={image.mini} alt={image.alt} />
                  </li>
                ))
              : ""}
          </ul>
        </div>
        {this.state.modalOpen ? (
          <ImageModal
            toggleModal={() => this.toggleModal()}
            handleImageChange={(key) => this.handleImageChange(key)}
            currentImage={this.props.images[this.state.currentImageIndex]}
            singleImage={this.props.images.length === 1}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Image;
