import React from "react";
import { Link } from "react-router-dom";
import AddressDisplay from "./AddressDisplay";
import AddressFields from "./AddressFields";
import ShippingOptions from "./ShippingOptions";
import Payment from "./Payment";
class CheckoutFields extends React.Component {
  constructor() {
    super();
    this.getAddress = this.getAddress.bind(this);
    this.setBillAddress = this.setBillAddress.bind(this);
    this.state = {
      setting_address: false,
    };
  }

  getAddress() {
    this.setState({ setting_address: true });
  }

  setBillAddress(state, isShipping) {
    this.setState({ setting_address: false });
    this.props.setAddress(state, isShipping);
  }

  render() {
    if (
      (this.props.shipments === undefined ||
        this.props.shipments.length == 0) &&
      this.props.loadingRates == false
    ) {
      return (
        <div>
          <AddressDisplay
            international={this.props.checkoutInfo.international}
            editAddress={this.props.editAddress}
            address={this.props.address.ship_address}
          />
          <ShippingOptions
            getShippingRates={this.props.getShippingRates}
            loadingRates={this.props.loadingRates}
            comment={this.props.comment}
            submitComment={this.props.submitComment}
            shipments={this.props.shipments}
            setShipping={this.props.setShipping}
          />
        </div>
      );
    } else {
      return (
        <div>
          <AddressDisplay
            international={this.props.checkoutInfo.international}
            editAddress={this.props.editAddress}
            address={this.props.address.ship_address}
          />
          <ShippingOptions
            getShippingRates={this.props.getShippingRates}
            loadingRates={this.props.loadingRates}
            comment={this.props.comment}
            submitComment={this.props.submitComment}
            shipments={this.props.shipments}
            setShipping={this.props.setShipping}
          />
          {this.state.setting_address ? (
            <span>
              <div className="title">
                <h3>Billing Address</h3>
                <br />
              </div>
              <AddressFields
                address={this.props.checkoutInfo.bill_address}
                isShipping={false}
                loadStates={this.props.loadStates}
                setAddress={this.setBillAddress}
                locations={this.props.locations}
              />
            </span>
          ) : (
            <Payment
              captureNothing={this.props.captureNothing}
              removeStoredCard={this.props.removeStoredCard}
              loggedIn={this.props.loggedIn}
              total={this.props.total}
              capturePayPal={this.props.capturePayPal}
              captureBraintree={this.props.captureBraintree}
              captureStoredBraintree={this.props.captureStoredBraintree}
              paymentInfo={this.props.paymentInfo}
              notify={this.props.notify}
              getAddress={this.getAddress}
              bill_address={this.props.address.bill_address}
              reCaptchaSiteKey={this.props.reCaptchaSiteKey}
              testEnv={this.props.testEnv}
            />
          )}
        </div>
      );
    }
  }
}

export default CheckoutFields;
