import React from "react";
import { Link } from "react-router-dom";
class Index extends React.Component {
  constructor() {
    super();
    this.logIn = this.logIn.bind(this);
    this.register = this.register.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.submitForgot = this.submitForgot.bind(this);
    this.state = {
      email: "",
    };
  }

  changeEmail(e) {
    this.setState({ email: e.target.value });
  }

  submitForgot(e) {
    e.preventDefault();
    this.props.forgotPassword(this.state);
    $("#forgotmodal").modal("hide");
  }

  forgotPassword() {
    $("#loginmodal").modal("hide");
    $("#forgotmodal").modal("show");
  }

  logIn(e) {
    e.preventDefault();
    this.props.logIn(e);
    $("#loginmodal").modal("hide");
  }

  register(e) {
    e.preventDefault();
    this.props.register(e);
    $("#registermodal").modal("hide");
  }

  render() {
    return (
      <div id="modals">
        <div className="modal lrmodal in fade" id="registermodal">
          <form className="lrform" onSubmit={this.register}>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              onChange={this.props.changeDeviseInfo}
              placeholder="Email"
              required
            />
            <label htmlFor="password">Password</label>
            <input
              name="password"
              type="password"
              onChange={this.props.changeDeviseInfo}
              placeholder="Password"
              required
            />
            <label htmlFor="password_confirmation">Confirm Password</label>
            <input
              name="password_confirmation"
              type="password"
              onChange={this.props.changeDeviseInfo}
              placeholder="Confirm Password"
              required
            />
            <br />
            <input
              type="submit"
              value="Register"
              className="btn btn-tubedepot"
            />
          </form>
        </div>
        <div className="modal lrmodal in fade" id="loginmodal">
          <form className="lrform" onSubmit={this.logIn} action="login">
            <div style={{ position: "relative" }}>
              <label htmlFor="email">Email</label>
              <input
                name="email"
                type="email"
                onChange={this.props.changeDeviseInfo}
                placeholder="Email"
                required
              />
              <label htmlFor="password">Password</label>
              <input
                name="password"
                type="password"
                onChange={this.props.changeDeviseInfo}
                placeholder="Password"
                required
              />
              <a
                href="javascript:;"
                onClick={this.forgotPassword}
                id="signin-forgot-pw"
              >
                Forgot Password?
              </a>
            </div>
            <br />
            <input type="submit" value="Log In" className="btn btn-tubedepot" />
          </form>
        </div>
        <div className="modal lrmodal in fade" id="forgotmodal">
          <h3>Forgot Your Password?</h3>
          <div style={{ color: "black" }}>
            Enter the email that you registered with below - we'll send you
            instructions to reset your password.
          </div>
          <br />
          <form className="lrform" onSubmit={this.submitForgot}>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              onChange={this.changeEmail}
              placeholder="Email"
              required
            />
            <br />
            <input
              type="submit"
              value="Request Password"
              className="btn btn-tubedepot"
            />
          </form>
        </div>
      </div>
    );
  }
}

export default Index;
