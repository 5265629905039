import React from "react";

class SubscribeModal extends React.Component {
  constructor() {
    super();
    this.state = { value: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    this.props.startLoading();
    var that = this;
    event.preventDefault();
    fetch("/react/subscribe?email=" + this.state.value, {
      method: "POST",
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        // $("#subscribeModal").modal("hide")}).then(jsonData => {
        // that.props.notify("success","Success! Check your email for the 10% off code.")
        that.props.notify("success", "Success!");
        this.showCode();
      })
      .catch((err) => {
        err.json().then((errorMessage) => {
          that.props.notify("danger", errorMessage.error);
        });
      });
  }

  showCode = () => {
    document.querySelector("#mailchimp-subscribe-form").style.display = "none";
    document.querySelector("#mailchimp-subscribe-code").style.display = "table";
  };

  render() {
    return (
      <div style={{ paddingTop: "50px" }}>
        <div style={{ height: "100%", marginBottom: "50px" }}>
          <div
            id="mailchimp-subscribe-code"
            className="container constrict"
            style={{ display: "none" }}
          >
            <h1 id="cart-text" style={{ marginLeft: "10px" }}>
              Thanks for subscribing!
            </h1>
            <br />
            <br />
            <label htmlFor="fieldEmail blc" style={{ maxWidth: "600px" }}>
              Use the sale code below at checkout to receive 10% off your next
              order. And look forward to more great deals, sent straight to your
              inbox!
            </label>
            <br />
            <br />
            <h3 id="cart-text" style={{ marginLeft: "10px" }}>
              Coupon Code: "NEWSUB"
            </h3>
          </div>
          <div
            id="mailchimp-subscribe-form"
            className="container constrict"
            style={{ height: "100%", display: "table" }}
          >
            <h1 id="cart-text" style={{ marginLeft: "10px" }}>
              Subscribe & Save
            </h1>
            <div className="modal-body blc">
              <div className="control-group">
                <label htmlFor="fieldEmail blc" style={{ maxWidth: "600px" }}>
                  Subscribe to our newsletter and receive a 10% off code for
                  your next order.
                </label>
                <form onSubmit={this.handleSubmit}>
                  <div className="floatinput checkout-field">
                    <input
                      type="email"
                      name="email"
                      value={this.state.value}
                      onChange={this.handleChange}
                    />
                    <span
                      className={
                        "floatlabel " +
                        (this.state.value == "" || this.state.value == null
                          ? null
                          : "floatme")
                      }
                    >
                      Email
                    </span>
                  </div>
                  <input
                    type="submit"
                    value="Subscribe"
                    className="btn btn-tubedepot"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubscribeModal;
