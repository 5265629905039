import React from "react";

class PayPalComponent extends React.Component {
  constructor() {
    super();
    this.initPayPal = this.initPayPal.bind(this);
    this.state = {
      env: "sandbox",
      commit: true,
    };
  }

  componentDidMount() {
    this.initPayPal();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.total != this.props.total) {
      $("#paypal-button").html(""); //Clear out previous PayPal button
      this.initPayPal();
    }
  }

  initPayPal() {
    var that = this;
    braintree.client.create(
      {
        authorization: this.props.paymentInfo.authorization,
      },
      function (clientErr, clientInstance) {
        if (clientErr) {
          console.error("Error creating client:", clientErr);
          return;
        }
        braintree.paypalCheckout.create(
          {
            client: clientInstance,
          },
          function (paypalCheckoutErr, paypalCheckoutInstance) {
            if (paypalCheckoutErr) {
              console.error(
                "Error creating PayPal Checkout:",
                paypalCheckoutErr,
              );
              return;
            }
            paypal.Button.render(
              {
                env: that.props.paymentInfo.env, // 'production' or 'sandbox'

                payment: function () {
                  return paypalCheckoutInstance.createPayment({
                    flow: "checkout",
                    intent: "authorize",
                    currency: "USD",
                    amount: that.props.total,
                  });
                },

                onAuthorize: function (data, actions) {
                  return paypalCheckoutInstance.tokenizePayment(
                    data,
                    function (err, payload) {
                      that.props.capturePayPal(payload.nonce);
                    },
                  );
                },

                onCancel: function (data) {
                  console.log("Payment cancelled");
                },

                onError: function (err) {
                  console.error("checkout.js error", err);
                  this.props.notify("danger", err);
                },
              },
              "#paypal-button",
            ).then(function () {
              // PayPal button is ready!
            });
          },
        );
      },
    );
  }

  render() {
    return (
      <div
        id="paypal-button"
        className="_mt-4"
        style={{ textAlign: "center", opacity: "0.99" }}
      />
    );
  }
}

export default PayPalComponent;
