import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import CommentModal from "./CommentModal";

class Details extends React.Component {
  constructor() {
    super();
    this.state = {
      commentModalOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.productId !== nextProps.productId) {
      let prev = document.querySelectorAll("div#product-details .active");
      let next = [
        document.querySelector("ul#tab-cnt a#tab1").parentElement,
        document.querySelector("div.tab-content div#tab1"),
      ];
      this.tabChange(Array.from(prev), Array.from(next));
      this.setState({ commentModalOpen: false });
    }
  }

  handleTabChange = (event) => {
    let prev = document.querySelectorAll("div#product-details .active");
    let next = [
      document.querySelector("ul#tab-cnt a#" + event.target.id).parentElement,
      document.querySelector("div.tab-content div#" + event.target.id),
    ];
    this.tabChange(Array.from(prev), Array.from(next));
    this.setState({ commentModalOpen: false });
  };

  tabChange = (prevElements, nextElements) => {
    prevElements.forEach((element) => {
      element.classList.remove("active");
    });
    nextElements.forEach((element) => {
      element.classList.add("active");
    });
  };

  updateComments = (comments) => {
    this.toggleModal();
    this.props.handleProductChange({ comments: comments });
  };

  toggleModal = () => {
    this.setState({
      commentModalOpen: !this.state.commentModalOpen,
    });
  };

  setRating = (rating) => {
    let stars = "";
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars = stars + "<i id=" + i + " class='fa fa-star starss'></i>";
      } else {
        stars = stars + "<i id=" + i + " class='fa fa-star starno'></i>";
      }
    }
    return ReactHtmlParser(stars);
  };

  setFeaturesText = () => {
    let text = this.props.featuresText;
    return ReactHtmlParser(text);
  };

  setAttachedFiles = () => {
    let attachments = "";
    let name;
    this.props.attachedFiles.map((file, index) => {
      if (file.display_as === "") {
        name = "Attachment " + (index + 1);
      } else {
        name = file.display_as;
      }
      attachments =
        attachments + "<li><a href='" + file.url + "'>" + name + "</a></li>";
    });
    return ReactHtmlParser(attachments);
  };

  render() {
    return (
      <div id="product-details">
        <ul
          id="tab-cnt"
          className="nav nav-tabs product-tabs"
          style={{ marginRight: "0px", marginBottom: "0px" }}
        >
          <li className="active" style={{ cursor: "pointer" }}>
            <a
              id="tab1"
              data-toggle="tab"
              onClick={(event) => this.handleTabChange(event)}
            >
              Reviews
            </a>
          </li>
          {this.props.featuresText !== "" ? (
            <li style={{ cursor: "pointer" }}>
              <a
                id="tab2"
                data-toggle="tab"
                onClick={(event) => this.handleTabChange(event)}
              >
                Features
              </a>
            </li>
          ) : (
            ""
          )}
          {this.props.attachedFiles.length !== 0 ? (
            <li style={{ cursor: "pointer" }}>
              <a
                id="tab3"
                data-toggle="tab"
                onClick={(event) => this.handleTabChange(event)}
              >
                Technical Documentation
              </a>
            </li>
          ) : (
            ""
          )}
        </ul>
        <div
          className="tab-content tab-box-shadow"
          style={{
            overflow: "scroll",
            height: "400px",
            paddingTop: "20px",
          }}
        >
          <div id="tab1" className="tab-pane active">
            {this.props.loggedIn ? (
              <div className="comment-modal">
                {this.state.commentModalOpen === true ? (
                  <CommentModal
                    notify={this.props.notify}
                    toggleModal={() => this.toggleModal()}
                    setRating={(rating) => this.setRating(rating)}
                    updateComments={(comments) => this.updateComments(comments)}
                    productId={this.props.productId}
                    authenticityToken={this.props.authenticityToken}
                    recaptchaKey={this.props.recaptchaKey}
                    startLoading={this.props.startLoading}
                    stopLoading={this.props.stopLoading}
                  />
                ) : (
                  <button
                    className="btn btn-tubedepot"
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={() => this.toggleModal()}
                  >
                    <span>Leave a Review</span>
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
            <br />
            <br />
            <ul style={{ marginRight: "20px" }}>
              {this.props.comments.length !== 0
                ? this.props.comments.map((comment, index) => (
                    <li key={index} style={{ listStyleType: "none" }}>
                      {this.setRating(parseInt(comment.star_rating))}
                      <br />
                      <strong>{comment.comment}</strong>
                      <br />
                      <br />
                      reviewed by: {comment.name}
                      <hr />
                    </li>
                  ))
                : "This product has no reviews."}
            </ul>
          </div>
          <div id="tab2" className="tab-pane">
            {this.setFeaturesText()}
          </div>
          <div id="tab3" className="tab-pane">
            <ul>{this.setAttachedFiles()}</ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Details;
