import Quill from "./quill_setup";
import imageHandler from "./QuillHandlers/ImageHandler";

const handleChange = (value, inputId) => {
  let formInput = document.querySelector("#" + inputId);
  formInput.value = value;
};

document.addEventListener("DOMContentLoaded", function () {
  var toolbarOptions = {
    container: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ header: 1 }, { header: 2 }],
      ["link", "image", "video"],
    ],
    handlers: { image: imageHandler },
  };

  document.querySelectorAll("#quill-container").forEach((container) => {
    let quill = new Quill(container, {
      modules: { toolbar: toolbarOptions },
      theme: "snow",
    });
    quill.on("text-change", function (delta, oldDelta, source) {
      handleChange(quill.root.innerHTML, quill.container.dataset.fieldId);
    });
  });
});
