import React from "react";
import { Link } from "react-router-dom";
class Pedals extends React.Component {
  render() {
    return (
      <li
        className="dropdown"
        onMouseEnter={() => this.props.open("Pedals")}
        onMouseLeave={this.props.close}
      >
        <a href="#" data-toggle="dropdown">
          Pedals
          <b className="caret"></b>
        </a>
        <ul
          className="dropdown-menu nav-parts"
          style={{
            visibility: this.props.opened == "Pedals" ? "visible" : "hidden",
          }}
        >
          <div className="pull-left multi-border">
            <h5>Guitar Pedals</h5>
            {this.props.pedals.map((itemMap, key) => (
              <li onClick={this.props.close} key={key}>
                <Link to={itemMap.path}>{itemMap.name}</Link>
              </li>
            ))}
          </div>

          <div className="pull-left size-control">
            <h5>Guitar Pedal Kits</h5>
            {this.props.pedalkits.map((itemMap, key) => (
              <li onClick={this.props.close} key={key}>
                <Link to={itemMap.path}>{itemMap.name}</Link>
              </li>
            ))}
          </div>
        </ul>
      </li>
    );
  }
}

export default Pedals;
