import React from "react";

class ExternalIpLogin extends React.Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.state = {
      code: "",
    };
  }

  componentDidMount() {
    gtag("event", "page_view", {
      page_path: window.location.pathname,
    });
  }

  onChange(e) {
    this.setState({ code: e.target.value });
  }

  onClick(e) {
    e.preventDefault();
    const that = this;
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    return fetch("/react/external_ip_login", {
      method: "post",
      body: JSON.stringify(this.state),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          that.props.notify("success", "Success!");
          return "";
        }
        throw results;
      })
      .catch(function (error) {
        that.props.notify(
          "danger",
          "Something went wrong. Please check the information you entered and try again.",
        );
      });
  }

  render() {
    return (
      <div className="constrict">
        <br />
        <form onSubmit={this.onClick}>
          <input
            type="password"
            value={this.state.code}
            onChange={this.onChange}
            name="code"
          />
          <input
            type="submit"
            className="btn btn-tubedepot"
            style={{ marginBottom: "10px" }}
          />
        </form>
      </div>
    );
  }
}

export default ExternalIpLogin;
