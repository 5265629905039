import React from "react";
import { Link } from "react-router-dom";
import Search from "./Search";
class Index extends React.Component {
  constructor() {
    super();
    this.openCart = this.openCart.bind(this);
    this.openNav = this.openNav.bind(this);
  }

  openCart() {
    {
      /* Not sure why I have to do this! */
    }

    $("#mcartModal").modal("show");
  }

  openNav() {
    {
      /* Not sure why I have to do this! */
    }
    $("#navModal").modal("show");
  }

  render() {
    return (
      <div className="constrict" id="header">
        <Link to="/">
          <img
            id="hdr-logo"
            className="sitelogo"
            src={this.props.logo}
            title="Welcome to TubeDepot!"
            style={{ width: "241px" }}
          />
        </Link>
        <div id="hdr-right" className="pull-right">
          <i
            className="menubarthree fa fa-bars"
            style={{ display: "none" }}
            data-toggle="modal"
            href="#navModal"
            onClick={this.openNav}
          />
          <div id="hdr-free-shipping" className="pull-left">
            <div id="search">
              <Search />
            </div>
          </div>
          <i
            className="mobile-cart-link fa fa-shopping-cart"
            style={{ display: "none" }}
            data-toggle="modal"
            href="#mcartModal"
            onClick={this.openCart}
          />
        </div>
      </div>
    );
  }
}

export default Index;
