import React from "react";

class Index extends React.Component {
  render() {
    return (
      <div className="constrict" style={{ marginTop: "10px" }}>
        Enter the code <strong>NY{new Date().getFullYear()}</strong> at checkout
        to receive 5% off of your order!
      </div>
    );
  }
}

export default Index;
