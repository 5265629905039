import React from "react";
import { Link } from "react-router-dom";

class FreeShipping extends React.Component {
  render() {
    return (
      <div style={{ paddingTop: "50px" }}>
        <div style={{ height: "100%", marginBottom: "50px" }}>
          <div
            className="container constrict display-table"
            style={{ height: "100%" }}
          >
            <h1 id="cart-text" style={{ marginLeft: "10px" }}>
              Does My Order Qualify for Free Shipping?
            </h1>
            <div className="modal-body blc">
              Qualified orders over $99 will ship to you without incurring a
              shipping fee. The following guidelines should be taken into
              account:
              <br />
              <br />
              <li>
                The free shipping option allows TubeDepot to choose the most
                appropriate carrier and service.
              </li>
              <li>
                Domestic US shipments under 1 pound usually ship USPS First
                Class.
              </li>
              <li>
                Domestic US shipments between 1-7.9 pounds usually ship FedEx
                Ground or USPS Priority Mail.
              </li>
              <li>
                Domestic US shipments over 8 pounds will be subject to shipping
                fees. If you place an order for a heavy or bulky item over 8
                pounds, you will see your shipping options at checkout. Since
                TubeDepot is in Memphis, TN, the home and main hub for FedEx and
                USPS, we get very good rates, so rest assured your heavy item
                will be shipped at the most affordable rates.
              </li>
              <li>
                Free shipping is limited to the contiguous 48 states. Free
                shipping is not available to Alaska, Hawaii, U.S. Territories
                and Outlying Areas, APO/FPO/DPOs or International destinations.
              </li>
              <br />
              <Link
                onClick={this.props.closeModal}
                to={{
                  pathname: "/pages/shipping-faq",
                }}
              >
                See Our Shipping FAQ For More Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FreeShipping;
