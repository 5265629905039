import React, { useState, useEffect, useRef } from "react";

let autoCompleteService;
let autoCompleteSessionToken;
let placesDetailsService;
let addressSearchTimer;

const initAutocompleteService = () => {
  autoCompleteService = new google.maps.places.AutocompleteService();
  autoCompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
};

const initPlacesDetailsService = (map) => {
  placesDetailsService = new google.maps.places.PlacesService(map);
};

const SearchLocationInput = (props) => {
  const [queryPredictions, setQueryPredictions] = useState([]);
  const suggestionListRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (autoCompleteService === undefined) {
      initAutocompleteService();
      initPlacesDetailsService(mapRef.current);
    }
  }, []);

  const handleInput = () => {
    if (event.target.value.length < 1) return;
    let input = event.target.value;
    clearTimeout(addressSearchTimer);

    addressSearchTimer = setTimeout(() => {
      autoCompleteService.getPlacePredictions(
        {
          input: input,
          types: ["address"],
          sessionToken: autoCompleteSessionToken,
        },
        handlePredictions,
      );
    }, 200);
  };

  const handlePredictions = (predictions, status) => {
    if (status === "OK") {
      setQueryPredictions(predictions);
    } else {
    }
  };

  const handleSelection = (placeId) => {
    placesDetailsService.getDetails(
      {
        placeId: placeId,
        fields: ["address_components"],
        sessionToken: autoCompleteSessionToken,
      },
      handleResponse,
    );
    autoCompleteSessionToken =
      new google.maps.places.AutocompleteSessionToken();
  };

  const handleResponse = (result, status) => {
    if (status === "OK") {
      props.resultsMethod(result);
    } else {
    }
  };

  return (
    <div id="address-autocomplete-form">
      <input
        type="text"
        placeholder="Search for address"
        style={{ width: "91.5%", marginRight: "10px" }}
        onChange={handleInput}
        onFocus={() => suggestionListRef.current.classList.toggle("hidden")}
        onBlur={() => suggestionListRef.current.classList.toggle("hidden")}
      />
      <div ref={suggestionListRef} id="address-suggestions" className="hidden">
        {queryPredictions.map((prediction, index) => (
          <div
            className="address-suggestion"
            key={index}
            onMouseDown={() => handleSelection(prediction.place_id)}
          >
            <strong>{prediction.structured_formatting.main_text}</strong>
            &nbsp;
            <span>{prediction.structured_formatting.secondary_text}</span>
          </div>
        ))}
      </div>
      <div ref={mapRef} className="hidden" />
    </div>
  );
};

export default SearchLocationInput;
