import React from "react";
import Index from "./Index";
import ReactResizeDetector from "react-resize-detector";

class Data extends React.Component {
  constructor() {
    super();
    this.check = this.check.bind(this);
    this.removeFilters = this.removeFilters.bind(this);
    this.state = {
      data: {
        name: "",
        description: "",
        products: [],
        filters: [],
      },
      checked: {},
      displayProducts: [],
      applicableFilters: {},
      jsonld: "",
    };
  }

  removeFilters() {
    this.setState({
      checked: {},
      displayProducts: this.state.data.products,
      applicableFilters: this.state.data.filters,
    });
  }

  componentWillReceiveProps(nextProps) {
    const that = this;
    if (!(this.props.location.pathname == nextProps.location.pathname)) {
      return fetch("/react/taxonsdata", {
        method: "POST",
        body: nextProps.location.pathname,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          that.setState({
            data: data,
            checked: {},
            displayProducts: data.products,
            applicableFilters: data.filters,
            jsonld: data.jsonld,
          });
          document.title = data.name + " | TubeDepot.com";
          gtag("event", "page_view", {
            page_path: window.location.pathname,
          });
        })
        .catch(function (err) {
          err.json().then((errorMessage) => {
            that.props.notify("danger", errorMessage.error);
          });
        });
    }
  }

  componentDidMount() {
    const that = this;
    return fetch("/react/taxonsdata", {
      method: "POST",
      body: this.props.location.pathname,
    })
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw results;
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        that.setState({
          data: data,
          displayProducts: data.products,
          applicableFilters: data.filters,
          jsonld: data.jsonld,
        });
        document.title = data.name + " | TubeDepot.com";
        gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      })
      .catch(function (err) {
        err.json().then((errorMessage) => {
          that.props.notify("danger", errorMessage.error);
        });
      });
  }

  check(event) {
    var index = event.target.className;
    var arr = this.state.checked;
    if (event.target.checked) {
      arr[index]
        ? arr[index].push(event.target.value)
        : (arr[index] = [event.target.value]);
      this.setState({
        checked: arr,
        displayProducts: this.filteredProducts(),
        applicableFilters: this.validFilters(),
      });
    } else {
      var arr = this.state.checked;
      var splicer = arr[index].indexOf(event.target.value);
      arr[index].splice(splicer, 1);
      arr[index].length == 0 ? delete arr[index] : null;
      this.setState({
        checked: arr,
        displayProducts: this.filteredProducts(),
        applicableFilters: this.validFilters(),
      });
    }
    window.scrollTo(0, 0);
  }

  filteredProducts = () => {
    if (this.state.data.products.length === 0) return [];
    return this.state.data.products.filter((product) => {
      var matchedFilters = [];
      Object.keys(this.state.checked).map((category) => {
        if (this.state.checked[category].includes(product.filters[category])) {
          matchedFilters.push(product.filters[category]);
        }
      });
      return matchedFilters.length === Object.keys(this.state.checked).length;
    });
  };

  validFilters = () => {
    if (this.state.data.products.length === 0) return [];
    var validProducts = this.filteredProducts();
    var filters = {};
    validProducts.map((product) => {
      Object.keys(this.state.data.filters).map((category) => {
        if (
          this.state.checked[category] ||
          product.filters[category] === undefined
        ) {
          return;
        } else if (filters[category] === undefined) {
          filters[category] = [product.filters[category]];
        } else if (!filters[category].includes(product.filters[category])) {
          filters[category].push(product.filters[category]);
        }
      });
    });
    return filters;
  };

  render() {
    return (
      <div>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.props.updateHeight}
        >
          <Index
            safeFilters={this.state.safeFilters}
            applicableFilters={this.state.applicableFilters}
            data={this.state.data}
            checked={this.state.checked}
            check={this.check}
            removeFilters={this.removeFilters}
            displayProducts={this.state.displayProducts}
          />
        </ReactResizeDetector>
        {this.state.jsonld == null ? null : (
          <div
            className="jsonld"
            dangerouslySetInnerHTML={{ __html: this.state.jsonld.toString() }}
          ></div>
        )}
      </div>
    );
  }
}

export default Data;
