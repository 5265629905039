import React from "react";
import { Link } from "react-router-dom";
class UtilityNav extends React.Component {
  render() {
    return (
      <ul id="utility-nav" className="nav pull-right">
        {this.props.subscribed == true ? null : (
          <li>
            <a href="javascript:;" data-toggle="modal" href="#subscribeModal">
              Save 10%
            </a>
          </li>
        )}
        <li
          id="account-dropdown"
          className="dropdown"
          onMouseEnter={() => this.props.open("Utility")}
          onMouseLeave={this.props.close}
        >
          <a href="javascript:;" data-toggle="dropdown">
            {this.props.account.email}
            <b className="caret"></b>
          </a>
          <ul
            className="dropdown-menu"
            style={{
              visibility: this.props.opened == "Utility" ? "visible" : "hidden",
            }}
          >
            {this.props.account.logged_in == true ? (
              <span>
                <li>
                  <Link to="/account">Your Account Page</Link>
                </li>
                {this.props.account.admin == true ? (
                  <li>
                    <a href="/admin">Admin</a>
                  </li>
                ) : null}
                <li>
                  <a href="javascript:;" onClick={this.props.logOut}>
                    Log Out
                  </a>
                </li>
              </span>
            ) : (
              <span>
                <li>
                  <a data-toggle="modal" href="#registermodal">
                    Register
                  </a>
                </li>
                <li>
                  <a data-toggle="modal" href="#loginmodal" id="account-login">
                    Log In
                  </a>
                </li>
              </span>
            )}
            {this.props.account.order.number == "" ? null : (
              <span>
                <li className="divider" style={{ marginBottom: "3px" }}></li>
                <li
                  style={{
                    textAlign: "center",
                    fontSize: "11px",
                    height: "12px",
                  }}
                >
                  Order: {this.props.account.order.number}
                </li>
              </span>
            )}
          </ul>
        </li>
        <li className="dropdown">
          <ul
            className="dropdown-menu"
            style={{
              visibility: this.props.showDropdown ? "visible" : "hidden",
            }}
            data-toggle="modal"
            href="#cartModal"
          >
            <div style={{ margin: "10px" }}>
              <img
                src={this.props.account.order.last_added_image}
                style={{
                  float: "left",
                  margin: "5px",
                  width: "60px",
                  height: "60px",
                }}
              />
              Item has been added to your cart.
            </div>
          </ul>
          <a id="cart-link" data-toggle="modal" href="#cartModal">
            <i className="fa fa-shopping-cart fa-white fa-flip-horizontal"></i>
          </a>
          <span
            id="cart-count"
            data-toggle="modal"
            href="#cartModal"
            className="badge cart-count"
          >
            {this.props.account.order.item_count}
          </span>
        </li>
      </ul>
    );
  }
}

export default UtilityNav;
