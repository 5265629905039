import React from "react";
import { Link } from "react-router-dom";
import { Parallax, Background } from "react-parallax";

const saleText = "Massive NOS Sale";
const saleTextTwo = "Click Here For Details";

class TubesByAmp extends React.Component {
  render() {
    return (
      <div className="hero">
        <div className="constrict">
          <Link to="/t/tubedepot-sale" className="hover:_no-underline">
            <Parallax
              bgImage={this.props.slider_image}
              bgImageAlt={this.props.sale_text_1}
              strength={200}
            >
              <div className="herosaleimg parallax-window">
                <div
                  className="herosaleimg parallax-window _flex _no-underline"
                  data-parallax="scroll"
                >
                  <div className="_m-auto _w-7/12 _pt-4 _pb-3 _bg-opacity-75 _bg-white _flex-col _rounded-3xl">
                    <div className="_flex _flex-col">
                      <h1 className="_flex _mx-auto _text-center _text-black _pr-0">
                        {this.props.sale_text_1}
                      </h1>
                      <h1 className="_flex _mx-auto _text-center _text-red-800 _pr-0">
                        {this.props.sale_text_2}
                      </h1>
                    </div>
                    <button className="mainimgbutton btn btn-tubedepot _flex _mx-auto">
                      Find Out More
                    </button>
                  </div>
                </div>
              </div>
            </Parallax>
          </Link>
        </div>
      </div>
    );
  }
}

export default TubesByAmp;
