import React from "react";
import { Link } from "react-router-dom";
class Rating extends React.Component {
  render() {
    var stars = [];
    for (var i = 0; i < 5; i++) {
      if (this.props.rating != null) {
        if (this.props.rating >= i) {
          stars.push(<i key={i} className="fa fa-star starss"></i>);
        } else {
          stars.push(<i key={i} className="fa fa-star starno"></i>);
        }
      }
    }
    if (this.props.rating == 0) {
      return <span>{stars}</span>;
    } else {
      return <span>{stars}</span>;
    }
  }
}

export default Rating;
