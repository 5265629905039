import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slide-out";
import Navigation from "./Navigation";
import SubMenu from "./SubMenu";
class Index extends React.Component {
  constructor() {
    super();
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalAndMenu = this.closeModalAndMenu.bind(this);
    this.state = {
      open: false,
      name: "",
      links: {
        path: "",
        name: "",
      },
    };
  }

  closeMenu() {
    this.setState({ open: false });
  }

  openMenu(name, links) {
    links == ""
      ? this.setState({ open: true, name: name, links: [] })
      : this.setState({
          open: true,
          name: name,
          links: this.props.links[links],
        });
    $("#navModal").animate({ scrollTop: 0 }, 0);
  }

  closeModal() {
    $("#navModal").modal("hide");
  }

  closeModalAndMenu() {
    $("#navModal").modal("hide");
    var that = this;
    setTimeout(function () {
      that.closeMenu();
    }, 500);
  }

  render() {
    return (
      <div className="mobile-navbar">
        <div className="navbar-inner constrict">
          <div className="container">
            <ul
              className="nav"
              style={
                this.state.open ? { overflow: "hidden", height: "80vh" } : {}
              }
            >
              <Navigation
                openMenu={this.openMenu}
                open={this.state.open}
                closeModal={this.closeModal}
                account={this.props.account}
              />
              <Slider isOpen={this.state.open}>
                <SubMenu
                  subscribed={this.props.subscribed}
                  links={this.state.links}
                  name={this.state.name}
                  closeMenu={this.closeMenu}
                  closeModal={this.closeModal}
                  closeModalAndMenu={this.closeModalAndMenu}
                  devise={this.props.devise}
                  changeDeviseInfo={this.props.changeDeviseInfo}
                  forgotPassword={this.props.forgotPassword}
                  register={this.props.register}
                  logIn={this.props.logIn}
                  notify={this.props.notify}
                  account={this.props.account}
                  logOut={this.props.logOut}
                />
              </Slider>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
