import React from "react";
import { Link } from "react-router-dom";
import Countdown from "react-countdown-now";

// Random component
const Completionist = () => (
  <h5>This sale has expired - come back later for more great deals!</h5>
);

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <div id="clockdiv">
        <div>
          <span className="days">{days}</span>
          <div className="smalltext">Days</div>
        </div>
        <div>
          <span className="hours">{hours}</span>
          <div className="smalltext">Hours</div>
        </div>
        <div>
          <span className="minutes">{minutes}</span>
          <div className="smalltext">Minutes</div>
        </div>
        <div>
          <span className="seconds">{seconds}</span>
          <div className="smalltext">Seconds</div>
        </div>
      </div>
    );
  }
};

class OneSaleyDeals extends React.Component {
  render() {
    return (
      <div className="constrict">
        <div className="salecont">
          <div className="onesaleimage" style={{ marginLeft: "50px" }}>
            <Link to={this.props.slider_link}>
              <img src={this.props.slider} />
            </Link>
          </div>
          <div className="saletext" style={{ margin: "auto" }}>
            <h1>
              {this.props.timer_text_1}
              <br />
              <div className="rtext">{this.props.timer_text_2}</div>
            </h1>
            <Countdown date={this.props.sale_end} renderer={renderer} />
          </div>
        </div>
      </div>
    );
  }
}

export default OneSaleyDeals;
