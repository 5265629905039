import React from "react";
import { Link } from "react-router-dom";
class NewProducts extends React.Component {
  render() {
    return (
      <span>
        <div className="title constrict">
          <h1>New Products</h1>
        </div>
        <div className="new-products constrict">
          <div className="new-product-links">
            {this.props.new_products.map((itemMap, key) => (
              <Link key={key} to={itemMap.path}>
                <div className="new-product-link bani center">
                  <img
                    itemProp="image"
                    className="new-product-image"
                    src={itemMap.image}
                    alt={itemMap.alt}
                  />
                  <br />
                  <h5>{itemMap.name}</h5>
                </div>
              </Link>
            ))}
          </div>

          <div className="new_product_button">
            <Link
              to={{
                pathname: "/new_products",
              }}
            >
              <button className="btn btn-tubedepot btn-large">See More</button>
            </Link>
            <a
              href="https://forms.gle/4f2A424CXG6kCg927"
              style={{ marginLeft: "20px" }}
            >
              <button className="btn btn-tubedepot-alt btn-large">
                Suggest New Products
              </button>
            </a>
          </div>
        </div>
      </span>
    );
  }
}

export default NewProducts;
