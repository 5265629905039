import React from "react";
import { Link } from "react-router-dom";
import Amp from "./Amp";
import Part from "./Part";
import CartForm from "./CartForm";

class Item extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        items: [],
        amp: false,
        name: "",
      },
      display: [],
      selected: {},
    };
    this.searchTba = this.searchTba.bind(this);
    this.btnClick = this.btnClick.bind(this);
  }

  selectedTubeSetItems(display) {
    const tubeSetItems = display.map((itemPart) => ({
      id: itemPart.sku,
      item_name: itemPart.name,
      item_id: itemPart.sku,
      price: itemPart.price,
      quantity: itemPart.quantity,
    }));
    return tubeSetItems;
  }

  // In the CartForm component we do the same operation for adding the price of the tubes
  // in the set, so I've added it here as well in case we run into the same issue with commas
  // mentioned here: https://github.com/TubeDepot/tubedepot-spree2/commit/50e440addb61993795ddd7ab4311fd3a38e5954e
  selectedTubeSetPrice(display) {
    let price = 0;
    display.map((itemPart) => {
      price += parseFloat(
        itemPart.price.replaceAll(",", "").match(/(?=\d)\S*/),
      );
    });
    return price;
  }

  componentWillReceiveProps(nextProps) {
    const that = this;
    if (nextProps.match.params.slug !== this.props.match.params.slug) {
      return fetch("/react/tbaitemdata/" + nextProps.match.params.slug)
        .then((results) => {
          if (results.ok) {
            return results.json();
          }
          throw that.props.notify(
            "danger",
            "Something went wrong. Please refresh and try again.",
          );
        })
        .then((jsonData) => {
          return jsonData;
        })
        .then((data) => {
          data.type
            ? that.setState({ data: data, display: data[data.type] })
            : that.setState({ data: data, display: data.items });
          gtag("event", "page_view", {
            page_path: window.location.pathname,
            product_code: data.g_id,
          });
        })
        .catch(function (error) {
          that.props.notify(
            "danger",
            "Something went wrong. Please refresh and try again.",
          );
        });
    }
  }

  componentDidMount() {
    const that = this;
    return fetch("/react/tbaitemdata/" + this.props.match.params.slug)
      .then((results) => {
        if (results.ok) {
          return results.json();
        }
        throw that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      })
      .then((jsonData) => {
        return jsonData;
      })
      .then((data) => {
        data.type != null
          ? that.setState({ data: data, display: data[data.type] })
          : that.setState({ data: data, display: data.items });
        document.title = "Tubes by Amp | TubeDepot.com";
        gtag("event", "page_view", {
          page_path: window.location.pathname,
          product_code: data.g_id,
        });
        gtag("event", "view_item", {
          currency: "USD",
          value: this.selectedTubeSetPrice(this.state.display),
          items: this.selectedTubeSetItems(this.state.display),
        });
      })
      .catch(function (error) {
        that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  }

  handleSetChange = () => {
    let products = Array.from(
      document.querySelectorAll(
        "ul#products.product-listing div.taxon-product",
      ),
    );
    let selected = {};
    products.forEach((product) => {
      selected[`${product.dataset.variantId}`] = product.dataset.quantity;
    });

    this.setState({
      selected: selected,
    });
  };

  searchTba(e) {
    if (e.target.value == "") {
      this.setState({ display: this.state.data.items });
    } else {
      var search = this.props.search.search;
      var filtered = search.filter((amp) =>
        amp.name.toLowerCase().includes(e.target.value.toLowerCase()),
      );
      this.setState({ display: filtered });
    }
  }

  btnClick(e) {
    var value = e.target.value;
    this.setState(({ data }) => ({
      data: {
        ...data,
        type: value,
      },
      display: data[value],
    }));
  }

  render() {
    if (this.state.data.amp == true) {
      return (
        <div className="constrict">
          <div className="title">
            <h1>{this.state.data.name}</h1>
          </div>
          {this.state.data.multiple == true ? (
            <div className="buttoncont">
              {this.state.data.standard == true ? (
                <button
                  onClick={this.btnClick}
                  className="tsbutton btn"
                  value="Standard Tube Set"
                  id="stsbutton"
                  disabled={
                    this.state.data.type == "Standard Tube Set" ? true : false
                  }
                >
                  Standard Tube Set
                </button>
              ) : null}
              {this.state.data.high == true ? (
                <button
                  onClick={this.btnClick}
                  className="tsbutton btn"
                  value="High Quality Tube Set"
                  id="hqtsbutton"
                  disabled={
                    this.state.data.type == "High Quality Tube Set"
                      ? true
                      : false
                  }
                >
                  High Quality Tube Set
                </button>
              ) : null}
              {this.state.data.best == true ? (
                <button
                  onClick={this.btnClick}
                  className="tsbutton btn"
                  value="Best Quality Tube Set"
                  id="bqtsbutton"
                  disabled={
                    this.state.data.type == "Best Quality Tube Set"
                      ? true
                      : false
                  }
                >
                  Best Quality Tube Set
                </button>
              ) : null}
            </div>
          ) : null}
          <div className="toplev">
            <legend align="center">{this.state.data.type}</legend>
            <div className="ampstyle">
              <ul
                id="products"
                className="inline product-listing non-filter tba-products"
              >
                {this.state.display.map((itemPart, key) => (
                  <li
                    key={key}
                    id={itemPart.id}
                    className="span3"
                    data-hook="products_list_item"
                    itemScope
                    itemType="http://schema.org/Product"
                  >
                    <Part
                      itemPart={itemPart}
                      handleSetChange={() => this.handleSetChange()}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div className="price-cnt border pull-right ampprice">
              <CartForm
                selected={this.state.selected}
                parts={this.state.data[this.state.data.type]}
                addToCart={this.props.addToCart}
                ampId={this.state.data.amp_id}
              />
              <br />
              <br />
              <br />
            </div>
          </div>

          <br />
          <br />
          <br />
          <div className="pull-left">
            <i>
              All registered trademarks and copyrights are the property of their
              respective owners.
            </i>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <div className="constrict">
            <div className="title">
              <h1>{this.state.data.name}</h1>
            </div>
            <p>
              Whether you are replacing the tubes in your guitar/bass amp;
              updating your home audio system: re-tubing your microphone or
              changing out the organ tubes, we have what you need right here.
              Each selection below will lead you to the brands and models you
              love for all of your equipment. We’ll then show you the entire set
              you need to fill your gear and we’ll offer several different
              brands to choose from, from standard to premium.
            </p>
            <input
              type="text"
              placeholder="Search for your amplifier.."
              className="tbasearch"
              onChange={this.searchTba}
            />
            <br />
            <br />
            <ul
              id="products"
              className="inline product-listing non-filter marpr tba-products"
              data-hook
            >
              {this.state.display.map((itemMap, key) => (
                <li
                  id={itemMap.id}
                  key={key}
                  className="span3"
                  data-hook="products_list_item"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                  <Link to={itemMap.path}>
                    <div className="taxon-product">
                      <div className="taxon-product-image">
                        <object>
                          <a>
                            <img itemProp="image" src={itemMap.image} />
                          </a>
                        </object>
                      </div>
                      <div className="taxon-product-info">
                        <object>
                          <a>{itemMap.name}</a>
                        </object>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
            <i>
              All registered trademarks and copyrights are the property of their
              respective owners.
            </i>
          </div>
        </div>
      );
    }
  }
}

export default Item;
