import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class StaticContent extends React.Component {
  constructor() {
    super();
    this.state = {
      page: {},
    };
  }

  componentDidMount() {
    var that = this;
    fetch("/react/pages/" + this.props.match.params.slug)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((page) => {
        this.setState({
          page: page,
        });
        gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      })
      .catch(function (err) {
        err.json().then((errorMessage) => {
          that.props.notify("danger", errorMessage.error);
        });
      });
  }

  setStaticBody = () => {
    return ReactHtmlParser(this.state.page.body);
  };

  setStaticTitle = () => {
    return this.state.page.title;
  };

  render() {
    return (
      <div className="constrict">
        <h2 className="title">{this.setStaticTitle()}</h2>
        {this.setStaticBody()}
      </div>
    );
  }
}

export default StaticContent;
