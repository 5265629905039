import React from "react";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import ReactPaginate from "react-paginate";
class Products extends React.Component {
  render() {
    return (
      <ul id="products" className="inline product-listing non-filter" data-hook>
        {this.props.products.map((itemMap, key) => (
          <li
            key={key}
            id={itemMap.id}
            className="span3 _w-56"
            data-hook="products_list_item"
            itemScope
            itemType="http://schema.org/Product"
          >
            <Link
              to={{
                pathname: itemMap.path,
                state: { product_id: itemMap.id },
              }}
            >
              <div className="taxon-product">
                <div className="taxon-product-image">
                  <object>
                    <a>
                      <img
                        itemProp="image"
                        src={itemMap.image}
                        alt={itemMap.alt}
                      />
                    </a>
                  </object>
                </div>
                <div className="taxon-product-info">
                  <object>
                    <a>{itemMap.name}</a>
                  </object>
                  <br />
                  <Rating rating={itemMap.rating} />
                  <div className="price-container">
                    {itemMap.on_sale == true ? (
                      <div className="price selling" itemProp="price">
                        <strike>{itemMap.price}</strike>
                        &nbsp;
                        <div className="bestsellingdiv">
                          {" "}
                          {itemMap.sale_text} {itemMap.display_price}
                        </div>
                      </div>
                    ) : (
                      <div className="price selling" itemProp="price">
                        {itemMap.display_price}
                      </div>
                    )}
                    <div className="sku" itemProp="sku">
                      {itemMap.sku}
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}

export default Products;
