import React from "react";

class Tracking extends React.Component {
  constructor() {
    super();
    this.state = {
      tracking: [],
    };
  }

  componentDidMount() {
    fetch(`/react/orders/${this.props.orderNumber}/tracking`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          tracking: data,
        });
      });
  }

  render() {
    return (
      <div>
        {this.state.tracking.map((shipment, index) => (
          <div key={index}>
            <hr />
            <h6>Shipment {shipment.number}</h6>
            <div>
              {shipment.tracking == null ? (
                "There is no tracking information for this package yet."
              ) : shipment.url == "" ? (
                <span>Tracking: {shipment.tracking}</span>
              ) : (
                <span>
                  Tracking: <a href={shipment.url}>{shipment.tracking}</a>
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Tracking;
