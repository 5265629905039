import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class Price extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  setPrice = () => {
    let variantPrice;
    if (this.props.productSale.price !== this.props.variantPrice) {
      variantPrice = this.props.variantPrice;
    } else {
      variantPrice = this.props.productSale.price;
    }

    return `$${variantPrice}`;
  };

  displayDollars = () => {
    return this.props.setDisplayPrice.match(/.*(?=\.)/);
  };

  displayCents = () => {
    return this.props.setDisplayPrice.match(/\.(.*)/)[1];
  };

  setPercentDisplay = () => {
    let percentOff;
    if (this.props.productSale.percent_off === "none") {
      percentOff = "SALE";
    } else {
      percentOff = this.props.productSale.percent_off + "% OFF!";
    }
    return percentOff;
  };

  setRemainingTime = () => {
    if (this.props.productSale.remaining_time !== "none") {
      return this.props.productSale.remaining_time + " Remaining";
    }
  };

  render() {
    return (
      <div id="product-price">
        <div className="price clearfix">
          {this.props.productSale.type === "none" ? (
            <div>
              <div className="pull-left price-color">$</div>
              <div
                id="price-dollars"
                className="price-big pull-left price-color"
              >
                {this.displayDollars()}
              </div>
              <div id="price-cents" className="pull-left price-color">
                .{this.displayCents()}
              </div>
            </div>
          ) : (
            <div>
              <strike>
                <div className="price-color">{this.setPrice()}</div>
              </strike>
              <p />
              <div
                id="sale-note"
                className="price-color pull-left"
                style={{ color: "red", fontWeight: "16px" }}
              >
                {this.setPercentDisplay()}
                &nbsp;
              </div>

              <div style={{ display: "inline-block" }}>
                <div
                  className="pull-left price-color"
                  style={{ color: "red", fontWeight: "16px" }}
                >
                  $
                </div>
                <div
                  id="price-dollars"
                  className="price-big pull-left price-color"
                  style={{ color: "red", fontWeight: "16px" }}
                >
                  {this.displayDollars()}
                </div>
                <div
                  id="price-cents"
                  className="pull-left price-color"
                  style={{ color: "red", fontWeight: "16px" }}
                >
                  .{this.displayCents()}
                </div>
              </div>
              <div style={{ color: "red", fontWeight: "bold" }}>
                <br />
                <br />
                {this.setRemainingTime()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Price;
