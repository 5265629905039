import React from "react";
import { Link } from "react-router-dom";

class SubMenu extends React.Component {
  constructor() {
    super();
    this.logOut = this.logOut.bind(this);
  }

  logOut() {
    this.props.logOut();
    this.props.closeModalAndMenu();
  }

  render() {
    return (
      <ul className="nav">
        <li className="dropdown-li slicky" onClick={this.props.closeMenu}>
          <div className="dropdown dd-color">{this.props.name}</div>
          <i className="fa fa-arrow-left dd-arrow-left" />
        </li>
        <div>
          {this.props.links.map((itemMap, key) => (
            <li className="subli" key={key}>
              <Link to={itemMap.path} onClick={this.props.closeModal}>
                {itemMap.name}
              </Link>
            </li>
          ))}
          {this.props.name == "Tube Categories" ? (
            <li className="subli">
              <Link
                to="/t/nixie-tubes-and-clocks"
                onClick={this.props.closeModal}
              >
                Nixie Tubes and Clocks
              </Link>
            </li>
          ) : null}
          {this.props.name == this.props.account.email ? (
            <span>
              {this.props.account.logged_in == true ? (
                <span>
                  <li className="subli">
                    <Link to="/account" onClick={this.props.closeModal}>
                      Your Account Page
                    </Link>
                  </li>
                  <li className="subli">
                    <a href="javascript:;" onClick={this.logOut}>
                      Log Out
                    </a>
                  </li>
                </span>
              ) : (
                <span>
                  <li className="subli">
                    <a
                      data-toggle="modal"
                      href="#registermodal"
                      onClick={this.props.closeModalAndMenu}
                    >
                      Register
                    </a>
                  </li>
                  <li className="subli">
                    <a
                      data-toggle="modal"
                      href="#loginmodal"
                      onClick={this.props.closeModalAndMenu}
                    >
                      Log In
                    </a>
                  </li>
                </span>
              )}
              {this.props.account.order.number == "" ? null : (
                <span>
                  <li className="divider" style={{ marginBottom: "3px" }}></li>
                  <li
                    style={{
                      textAlign: "center",
                      fontSize: "11px",
                      height: "12px",
                    }}
                  >
                    Order: {this.props.account.order.number}
                  </li>
                </span>
              )}
            </span>
          ) : null}
        </div>
      </ul>
    );
  }
}

export default SubMenu;
