import React from "react";
import { Link } from "react-router-dom";
import Address from "./Address";

class Orders extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      orders: [],
    };
  }

  componentDidMount() {
    var that = this;
    fetch("/react/orders")
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        this.setState({
          email: data.email,
          orders: data.orders,
        });
        document.title = "Your Orders | TubeDepot.com";
        gtag("event", "page_view", {
          page_path: window.location.pathname,
        });
      })
      .catch(function (err) {
        err.json().then((errorMessage) => {
          that.props.notify("danger", errorMessage.error);
        });
      });
  }

  componentDidUpdate() {
    if (this.state.orders) {
      $(".tablesorter").tablesorter({ sortList: [[1, 1]] });
    }
  }

  setOrderString = (string) => {
    return string == null
      ? ""
      : string
          .split("_")
          .map((word) => word[0].toUpperCase() + word.slice(1))
          .join(" ");
  };

  render() {
    return (
      <div className="_flex _flex-wrap _mx-auto constrict">
        <legend>My Account</legend>
        <table data-hook="account_summary" className="account-summary _w-full">
          <tbody>
            <tr id="user-info">
              <th>Email</th>
              <th>Default Shipping Address</th>
              <th>Default Billing Address</th>
            </tr>
            <tr>
              <td>
                {this.state.email} <br />
                <Link
                  to={{
                    pathname: `/account/form`,
                  }}
                >
                  <button className="btn btn-tubedepot">Update Email</button>
                </Link>
              </td>
              <td>
                <span>
                  {this.props.defaultAddress !== "" ? (
                    <div>
                      <Address address={this.props.defaultAddress} />
                      <Link
                        to={{
                          pathname: `/account/shipping_address_form`,
                        }}
                      >
                        <button className="btn btn-tubedepot">
                          Update Default Shipping Address
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <span>
                      No default address found.
                      <br />
                    </span>
                  )}
                </span>
              </td>
              <td>
                <span>
                  {this.props.defaultAddress !== "" ? (
                    <div>
                      <Address address={this.props.defaultBillingAddress} />
                      <Link
                        to={{
                          pathname: `/account/billing_address_form`,
                        }}
                      >
                        <button className="btn btn-tubedepot">
                          Update Default Billing Address
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <span>
                      No default address found.
                      <br />
                    </span>
                  )}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          data-hook="account_my_orders"
          className="account-my-orders _w-full"
        >
          <legend>My Orders</legend>
          {this.state.orders.length !== 0 ? (
            <table className="order-summary tablesorter">
              <thead class="_bg-gray-200">
                <tr>
                  <th className="order-number">
                    <a href="#">Order</a>
                  </th>
                  <th className="order-date">
                    <a href="#">Order Date</a>
                  </th>
                  <th className="order-status">
                    <a href="#">Status</a>
                  </th>
                  <th className="order-payment-state">
                    <a href="#">Payment State</a>
                  </th>
                  <th className="order-shipment-state">
                    <a href="#">Shipment State</a>
                  </th>
                  <th className="order-total">
                    <a href="#">Total</a>
                  </th>
                  <th className="order-repeat">Repeat</th>
                </tr>
              </thead>
              <tbody>
                {this.state.orders.map((order, index) => (
                  <tr
                    key={index}
                    className={
                      "hover:_bg-gray-400 " +
                      (index % 2 === 0 ? "_bg-white" : "_bg-gray-200")
                    }
                  >
                    <td className="order-number">
                      <Link
                        to={{
                          pathname: `/account/${order.number}`,
                        }}
                      >
                        {order.number}
                      </Link>
                    </td>
                    <td className="order-date">{order.completed_at}</td>
                    <td className="order-status">
                      {this.setOrderString(order.state)}
                    </td>
                    <td className="order-payment-state">
                      {this.setOrderString(order.payment_state)}
                    </td>
                    <td className="order-shipment-state">
                      {this.setOrderString(order.shipment_state)}
                    </td>
                    <td className="order-total">{order.display_total}</td>
                    <td className="order-repeat">
                      {order.can_repeat ? (
                        <i
                          className="fa fa-history fa-2x center"
                          onClick={() => this.props.repeatOrder(order.number)}
                        />
                      ) : (
                        <i
                          className="fa fa-history fa-2x center"
                          style={{ color: "gray" }}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            "You currently have no orders."
          )}
        </div>
      </div>
    );
  }
}

export default Orders;
