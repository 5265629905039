import React from "react";
import { Link } from "react-router-dom";
import BraintreeComponent from "./BraintreeComponent";
import BraintreeStoredPayment from "./BraintreeStoredPayment";
import PayPalComponent from "./PayPalComponent";
import NoPaymentNecessary from "./NoPaymentNecessary";
class Payment extends React.Component {
  render() {
    if (this.props.total == 0) {
      return (
        <span>
          <div className="title">
            <h3>Payment</h3>
            <br />
          </div>
          <h5 style={{ margin: "5px" }} className="billAddress">
            Billing address: {this.props.bill_address.address1} |{" "}
            <a href="javascript:;" onClick={this.props.getAddress}>
              Change
            </a>
          </h5>
          <NoPaymentNecessary captureNothing={this.props.captureNothing} />
        </span>
      );
    } else {
      return (
        <span>
          <div className="title">
            <h3>Payment</h3>
            <br />
          </div>
          <h5 style={{ margin: "5px" }} className="billAddress">
            Billing address: {this.props.bill_address.address1} |{" "}
            <a href="javascript:;" onClick={this.props.getAddress}>
              Change
            </a>
          </h5>
          {this.props.paymentInfo.storedCards.length > 0 ? (
            <BraintreeStoredPayment
              removeStoredCard={this.props.removeStoredCard}
              captureBraintree={this.props.captureBraintree}
              loggedIn={this.props.loggedIn}
              captureStoredBraintree={this.props.captureStoredBraintree}
              paymentInfo={this.props.paymentInfo}
              bill_address={this.props.bill_address}
              notify={this.props.notify}
            />
          ) : (
            <BraintreeComponent
              loggedIn={this.props.loggedIn}
              captureBraintree={this.props.captureBraintree}
              paymentInfo={this.props.paymentInfo}
              bill_address={this.props.bill_address}
              notify={this.props.notify}
              reCaptchaSiteKey={this.props.reCaptchaSiteKey}
              testEnv={this.props.testEnv}
            />
          )}
          <PayPalComponent
            total={this.props.total}
            paymentInfo={this.props.paymentInfo}
            capturePayPal={this.props.capturePayPal}
            notify={this.props.notify}
            bill_address={this.props.bill_address}
          />
        </span>
      );
    }
  }
}

export default Payment;
