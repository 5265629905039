import React from "react";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  TumblrShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  TumblrIcon,
  EmailIcon,
} from "react-share";

class CartForm extends React.Component {
  setPrice = () => {
    let price = 0;
    let selectedVariants = Object.keys(this.props.selected);
    let swap;

    this.props.parts.map((part) => {
      swap = part.collection.find((sub) =>
        selectedVariants.includes(`${sub.id}`),
      );
      if (selectedVariants.includes(`${part.variant_id}`)) {
        price += parseFloat(part.price.replaceAll(",", "").match(/(?=\d)\S*/));
      } else if (swap !== undefined) {
        price += parseFloat(swap.price.replaceAll(",", "").match(/(?=\d)\S*/));
      }
    });

    return `${price.toFixed(2)}`;
  };

  handleSubmit = () => {
    this.props.addToCart({
      variants: this.props.selected,
      source_type: "Spree::TbaAmp",
      source_id: this.props.ampId,
    });
  };

  prohibitedItems = () => {
    return (
      this.props.parts.find(
        (part) => !part.in_stock,
      ) !== undefined
    );
  };

  render() {
    if (this.prohibitedItems()) {
      return (
        <div>
          <div id="inside-product-cart-form">
            <div className="columns five if alpha else omega">
              <div id="product-price">
                <div className="price clearfix" itemProp="price">
                  <div className="pull-left price-color">$</div>
                  <div
                    className="price-big pull-left price-color"
                    id="price-dollars"
                  >
                    {this.setPrice()}
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <fieldset>
                <div className="control-group">
                  <h5 className="_w-40">
                    Sorry, but this product is currently not for sale.
                  </h5>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div id="inside-product-cart-form">
            <div className="columns five if alpha else omega">
              <div id="product-price">
                <div className="price clearfix" itemProp="price">
                  <div className="pull-left price-color">$</div>
                  <div
                    className="price-big pull-left price-color"
                    id="price-dollars"
                  >
                    {this.setPrice()}
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <fieldset>
                <div className="control-group">
                  <button
                    className="btn btn-large btn-tubedepot"
                    id="add-to-cart-button"
                    onClick={() => this.handleSubmit()}
                  >
                    Add To Cart
                  </button>
                  <div className="cart-sm-buttons">
                    <FacebookShareButton url={window.location.href}>
                      <FacebookIcon size={20} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton url={window.location.href}>
                      <TwitterIcon size={20} round={true} />
                    </TwitterShareButton>
                    <TumblrShareButton url={window.location.href}>
                      <TumblrIcon size={20} round={true} />
                    </TumblrShareButton>
                    <EmailShareButton url={window.location.href}>
                      <EmailIcon size={20} round={true} />
                    </EmailShareButton>
                  </div>
                  <a data-toggle="modal" href="#freeShippingModal">
                    <p id="product-free-shipping-cta">
                      <i className="fa fa-truck" />
                      Free Shipping Over $99*
                    </p>
                  </a>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CartForm;
