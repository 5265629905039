import React from "react";
class Issues extends React.Component {
  constructor(props) {
    super(props);
    this.getIssues = this.getIssues.bind(this);
    this.postIssue = this.postIssue.bind(this);
    this.changeInfo = this.changeInfo.bind(this);
    this.addIssue = this.addIssue.bind(this);
    this.state = {
      issues: [
        {
          title: "switch",
          body: "debug",
          state: "fix",
        },
      ],
      new_issue_title: "",
      new_issue_body: "",
    };
  }

  componentDidMount() {
    this.getIssues();
  }

  postIssue(e) {
    e.preventDefault();
    var token = this.props.token;
    fetch("https://api.github.com/repos/TubeDepot/tubedepot-spree2/issues", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "token " + token,
      },
      body: JSON.stringify({
        title: this.state.new_issue_title,
        body: this.state.new_issue_body,
        labels: ["submission"],
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        fetch("https://api.github.com/projects/columns/7335840/cards", {
          method: "POST",
          headers: {
            Accept: "application/vnd.github.inertia-preview+json",
            "Content-Type": "application/json",
            Authorization: "token " + token,
          },
          body: JSON.stringify({
            content_id: data.id,
            content_type: "Issue",
          }),
        });
      });
    // POST /projects/columns/:column_id/cards
    this.addIssue();
  }

  getIssues() {
    fetch(
      "https://api.github.com/repos/TubeDepot/tubedepot-spree2/issues?labels=submission",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "token " + this.props.token,
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        this.setState({ issues: data });
      });
  }

  changeInfo(e) {
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  }

  addIssue() {
    var newIssue = {
      title: this.state.new_issue_title,
      body: this.state.new_issue_body,
      state: "open",
    };
    this.setState({ issues: [newIssue, ...this.state.issues] });
  }

  render() {
    return (
      <div className="row no-margin">
        <div
          className="submission"
          style={{ width: "100%", textAlign: "center" }}
        >
          Submit a new issue:
          <br />
          <form onSubmit={this.postIssue}>
            <input
              style={{ width: "50%" }}
              type="text"
              onChange={this.changeInfo}
              name="new_issue_title"
              placeholder="Title"
              value={this.state.new_issue_title}
              required
            />
            <br />
            <textarea
              style={{ width: "50%", marginTop: "5px" }}
              type="text"
              onChange={this.changeInfo}
              name="new_issue_body"
              placeholder="Body"
              value={this.state.new_issue_body}
              required
            />
            <br />
            <input
              style={{ padding: "10px" }}
              type="submit"
              value="Submit"
              className="btn btn-tubedepot btn-success"
            />
            <br />
          </form>
          <hr />
        </div>
        Website Issue Submissions:
        <table>
          <tbody>
            <tr>
              <td>Title</td>
              <td>State</td>
              <td>Body</td>
            </tr>
            {this.state.issues.map((issue, index) => (
              <tr className="issue" key={index}>
                <td>{issue.title}</td>
                <td>{issue.state}</td>
                <td>{issue.body}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Issues;
