import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class LineItems extends React.Component {
  setAmount = (amount) => {
    amount = parseFloat(amount);
    return amount >= 0
      ? `$${amount.toFixed(2)}`
      : `-$${(amount * -1).toFixed(2)}`;
  };

  setItemAdjustments = (adjustments) => {
    if (!adjustments) return;
    let itemAdjustmentsHTML = "";

    adjustments.map((adjustment) => {
      itemAdjustmentsHTML += `
        <li>
          <strong>
            ${adjustment.label}
          </strong>
          <div class="pull-right">
            ${this.setAmount(adjustment.amount)}
          </div>
          <hr style="margin:0px;" />
        </li>
      `;
    });
    return ReactHtmlParser(itemAdjustmentsHTML);
  };

  setOrderAdjustments = () => {
    if (!this.props.totals.promotion_adjustments) return;
    let orderAdjustmentsHTML = "";

    this.props.totals.promotion_adjustments.map((adjustment) => {
      orderAdjustmentsHTML = `
        <tr class="total">
          <td colSpan="4">
            <strong>
              ${adjustment.label}
            </strong>
          </td>
          <td class="total">
            ${this.setAmount(adjustment.amount)}
          </td>
        </tr>
      `;
    });

    return ReactHtmlParser(orderAdjustmentsHTML);
  };

  render() {
    return (
      <table
        id="line-items"
        className="index columns alpha omega sixteen"
        data-hook="order_summary"
      >
        <thead data-hook>
          <tr data-hook="order_details_line_items_headers">
            <th colSpan="2" width="85%">
              Item
            </th>
            <th className="price" width="5%">
              Price
            </th>
            <th className="qty" width="5%">
              QTY
            </th>
            <th className="total" width="5%">
              <span>Total</span>
            </th>
          </tr>
        </thead>
        <tbody data-hook>
          {this.props.lineItems.map((lineItem, index) => (
            <tr data-hook="order_details_line_item_row" key={index}>
              <td data-hook="order_item_image" width="15%">
                <Link
                  to={{
                    pathname: lineItem.product_path,
                  }}
                >
                  <img src={lineItem.image} />
                </Link>
              </td>
              <td data-hook="order_item_description" width="70%">
                <Link
                  to={{
                    pathname: lineItem.product_path,
                  }}
                >
                  <h4 style={{ paddingTop: "5px" }}>{lineItem.product_name}</h4>
                </Link>
                <ul className="cart-line-item-adjustments">
                  {this.setItemAdjustments(lineItem.adjustments)}
                </ul>
              </td>
              <td data-hook="order_item_price" className="price" width="5%">
                <span>
                  {this.setAmount(
                    parseFloat(lineItem.amount) / lineItem.quantity,
                  )}
                </span>
              </td>
              <td data-hook="order_item_qty" width="5%">
                {lineItem.quantity}
              </td>
              <td data-hook="order_item_total" className="total" width="5%">
                <span>{this.setAmount(lineItem.amount)}</span>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot id="order-total" data-hook="order_details_total">
          <tr className="total">
            <td colSpan="4">
              <strong>Order Total:</strong>
            </td>
            <td className="total">
              <span id="order_total">
                {this.setAmount(this.props.totals.total)}
              </span>
            </td>
          </tr>
        </tfoot>
        <tfoot id="subtotal" data-hook="order_details_subtotal">
          <tr className="total" id="subtotal-row">
            <td colSpan="4">
              <strong>Subtotal:</strong>
            </td>
            <td className="total">
              <span>{this.setAmount(this.props.totals.subtotal)}</span>
            </td>
          </tr>
          {this.setOrderAdjustments()}
        </tfoot>
        <tfoot data-hook="order_details_tax_adjustments">
          {this.props.totals.tax_label !== null ? (
            <tr className="total">
              <td colSpan="4">
                <strong>{this.props.totals.tax_label}</strong>
              </td>
              <td className="total">
                <span>{this.setAmount(this.props.totals.tax_total)}</span>
              </td>
            </tr>
          ) : null}
          <tr className="total" data-hook="shipping_total">
            <td colSpan="4">
              <strong>Shipping Total:</strong>
            </td>
            <td className="total">
              {this.setAmount(this.props.totals.ship_total)}
            </td>
          </tr>
        </tfoot>
      </table>
    );
  }
}

export default LineItems;
