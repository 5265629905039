import React from "react";
import { Link } from "react-router-dom";

class Part extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      part: props.itemPart,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.itemPart.variant_id !== nextProps.itemPart.variant_id) {
      this.setState({
        part: nextProps.itemPart,
      });
      Array.from(document.querySelectorAll("select.tselect")).forEach(
        (select) => {
          select.value = "";
        },
      );
    }
  }

  componentDidMount() {
    this.props.handleSetChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.part !== prevState.part && this.props === prevProps) {
      this.props.handleSetChange();
    } else if (this.props.itemPart.sku !== prevProps.itemPart.sku) {
      this.props.handleSetChange();
    }
  }

  handlePartChange = (event) => {
    let selection = this.props.itemPart.collection.find(
      (item) => `${item.id}` === event.target.value,
    );
    this.setState({
      part: selection,
    });
  };

  render() {
    return (
      <div>
        <div
          className="taxon-product"
          data-variant-id={
            isNaN(parseInt(this.state.part.id))
              ? this.state.part.variant_id
              : this.state.part.id
          }
          data-quantity={this.props.itemPart.quantity}
        >
          <div className="taxon-product-image">
            <object>
              <Link
                className="info"
                to={{
                  pathname: this.state.part.path,
                }}
              >
                <img itemProp="image" src={this.state.part.image} />
              </Link>
            </object>
            <br />
            <b>
              <div className="quantityamp">
                <span className="quan">X{this.props.itemPart.quantity}</span>
                {this.props.itemPart.collection.length > 0 ? (
                  <select
                    name="variant_id"
                    id="variant_id"
                    className="tselect"
                    style={{ width: "150px" }}
                    defaultValue=""
                    onChange={(event) => this.handlePartChange(event)}
                  >
                    <option value="" disabled>
                      Similar Tubes
                    </option>
                    {this.props.itemPart.collection.map((variant, index) => (
                      <option key={index} value={variant.id}>
                        {variant.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  ""
                )}
              </div>
            </b>
          </div>
          <div className="taxon-product-info">
            <object>
              <Link
                className="info"
                to={{
                  pathname: this.state.part.path,
                }}
              >
                {this.state.part.name}
              </Link>
            </object>
            <br />
            <b>
              <div className="stock-notifier">
                {this.state.part.in_stock == false ? "Out of Stock" : null}
              </div>
            </b>
            {this.state.part.note == "" ? null : (
              <div className="partnote">{this.state.part.note}</div>
            )}
            <div className="price-container">
              <div className="price selling" itemProp="price">
                {this.state.part.price}
              </div>
              <div className="sku" itemProp="sku">
                {this.state.part.sku}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Part;
