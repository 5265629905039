import React from "react";
class Video extends React.Component {
  render() {
    return (
      <span>
        <div className="title constrict">
          <h1>TubeDepot TV</h1>
        </div>
        <div className="constrict" align="center">
          <iframe
            className="videoframe"
            width="940"
            height="480"
            src={"https://www.youtube.com/embed/" + this.props.video_url}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </div>
      </span>
    );
  }
}

export default Video;
